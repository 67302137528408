import Fuse from 'fuse.js';
import { map, sample, toArray, xor } from 'lodash';
import { useState, useLayoutEffect, useCallback, useRef } from 'react';
import { useEventListener } from 'poke-hook';
import { Filter } from './Filter';
import { Results } from './Results';
import {
	PokemonForm,
	PokemonForms,
	Regions,
	TypeId,
} from 'poke-data';

function searchFilter(fuse: Fuse<PokemonForm>) {
	const list = map(PokemonForms, (p, i) => ({
		item: p,
		score: 1,
		refIndex: Number(i),
	}));

	return (query: string): Fuse.FuseResult<PokemonForm>[] => {
		const numericQuery = (Number(query) || Math.E).toString();
		const searchExpression = {
			$or: [
				{ name: query },
				{
					$and: [
						{ id: numericQuery },
					],
				},
			],
		};
		const results = fuse.search(searchExpression);
		return results.length ? results : !query.length ? list : [];
	};
}

const PokemonFormArray = toArray(PokemonForms);
const search = searchFilter(new Fuse(PokemonFormArray, {
	keys: [ 'id', 'name' ],
	includeScore: true,
	sortFn: (a, b) => {
		const formA = PokemonFormArray[a.idx];
		const formB = PokemonFormArray[b.idx];
		if (formA.species_id === formB.species_id) {
			const formAFromGalar = formA.form !== 'gmax' && formA.regions.includes(Regions.galar);
			const formBFromGalar = formB.form !== 'gmax' && formB.regions.includes(Regions.galar);
			if (formAFromGalar && !formBFromGalar) {
				return -1;
			} else if (formBFromGalar && !formAFromGalar) {
				return 1;
			}
		}
		return a.score - b.score;
	},
}));

export function Search({
	pokemon,
	searchBar,
	typeFilter,
	setPokemon,
}: {
	pokemon: PokemonForm | undefined,
	searchBar: boolean,
	typeFilter: boolean,
	setPokemon: (x: PokemonForm | undefined) => void,
}): JSX.Element {
	const inputEl = useRef<HTMLInputElement>(null);
	const [ inputValue, setInputValue ] = useState('');
	const [ activeTypes, setActiveTypes ] = useState<TypeId[]>([]);
	const [ onlyMonoTypes, setOnlyMonoTypes ] = useState<boolean>(false);
	const [ searchResults, setSearchResults ] = useState<Fuse.FuseResult<PokemonForm>[]>([]);

	const handleKeyPress = useCallback((keyPress: KeyboardEvent) => {
		if (![ 'Enter', 'I' ].includes(keyPress.key)) {
			return;
		}
		if (keyPress.key === 'Enter') {
			setInputValue('');
			setOnlyMonoTypes(false);
			setActiveTypes([]);
			if (inputEl.current !== null) {
				inputEl.current.focus();
			}
		}
		if (keyPress.key === 'I') {
			if (
				inputEl.current !== null &&
				inputEl.current !== document.activeElement
			) {
				inputEl.current.focus();
				keyPress.preventDefault();
			}
		}
	}, []);

	useEventListener('keypress', handleKeyPress);

	useLayoutEffect(
		function searchAndFilter() {
			const results = search(searchBar ? inputValue : '')
				.filter(({ item }) => {
					if (!typeFilter) {
						return true;
					}
					if (activeTypes.length === 0) {
						return true;
					}
					if (activeTypes.length === 1 && !onlyMonoTypes) {
						return item.type_ids.includes(activeTypes[0]);
					}
					return xor(activeTypes, item.type_ids).length === 0;
				});
			setSearchResults(results);
			setPokemon(results.find(Boolean)?.item);
		}, [
			activeTypes,
			inputValue,
			onlyMonoTypes,
			searchBar,
			typeFilter,
		],
	);

	function handleChange(val: string) {
		setInputValue(val);
	}

	return <>
		<div
			className="fixed top-2 right-7 w-7 h-7 select-none"
			role="button"
			onClick={ () => setPokemon(sample(PokemonForms)) }
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.21 58.98" className="w-4 h-7 mx-auto text-bw fill-current"><path d="M0 29.58a29.1 29.1 0 0 1 51.2-19.4l1.1-5.9a2.24 2.24 0 0 1 4.4.8l-2.1 11.2v.2a2.25 2.25 0 0 1-2.2 1.7H52l-11.3-2.1a2.24 2.24 0 0 1 .8-4.4l6 1.1a24.62 24.62 0 0 0-43 14.5h15.7a9 9 0 0 1 8.7-6.8 2.2 2.2 0 0 1 0 4.4 4.5 4.5 0 1 0 4.5 4.5 2.22 2.22 0 0 1 2.2-2.2H56a2.22 2.22 0 0 1 2.2 2.2 28.92 28.92 0 0 1-14.8 25.8A29.07 29.07 0 0 1 7 48.88l-.8 5.8a2.17 2.17 0 0 1-2.2 1.9h-.3a2.23 2.23 0 0 1-1.9-2.5l1.5-11.4a2.23 2.23 0 0 1 2.5-1.9l11.4 1.5a2.22 2.22 0 1 1-.6 4.4l-6.3-.8a24.59 24.59 0 0 0 30.8 5.4 24.68 24.68 0 0 0 12.4-19.6H37.31a8.92 8.92 0 0 1-8.7 6.7 9 9 0 0 1-8.7-6.7H1.81c-.81.1-1.81-.9-1.81-2.1Z"/></svg>
		</div>
		{ searchBar && <input
			className="h-7 w-96 text-black px-2 rounded"
			type="text"
			autoFocus
			placeholder="name or national #"
			value={ inputValue }
			onChange={ (e) => handleChange(e.target.value) }
			ref={ inputEl }
		/> }
		{ typeFilter && <Filter
			{ ...{
				activeTypes,
				onlyMonoTypes,
				setActiveTypes,
				setOnlyMonoTypes,
			} }
		/> }
		<Results
			{ ...{
				pokemon,
				searchResults,
				setPokemon,
			} }
		/>
	</>;
}

