import { Descriptions } from 'poke-data';
import { SectionProps } from './SectionWrapper';

export function Description({
	pokemon,
}: SectionProps): JSX.Element {
	return <div
		className="w-full py-2 bg-content"
	>
		<p
			className="w-80 mx-auto text-sm"
		>
			{ Descriptions[pokemon.species_id].description }
		</p>
	</div>;
}
