import { ElementTypeColors, ElementTypes, TypeId } from 'poke-data';

export function MonoTypeToggle({
	typeId,
	selected,
	setOnlyMonoTypes,
}: {
	typeId: TypeId,
	selected: boolean,
	setOnlyMonoTypes: (x: boolean | ((x: boolean) => boolean)) => void,
}): JSX.Element {
	const type = ElementTypes[typeId];
	const color = ElementTypeColors[type];
	return <div
		className="w-7 h-7 pl-0.5 leading-7 rounded-full text-white text-center select-none"
		style={{
			backgroundColor: selected ? color : undefined,
			border: '1px solid ' + color,
		}}
		onClick={ () => setOnlyMonoTypes(prev => !prev) }
		role="button"
	>M</div>;
}
