import { useRef, useEffect } from 'react';

export function useEventListener<K extends keyof WindowEventMap>(
	name: K,
	handler: (event: WindowEventMap[K]) => void,
): void {
	const savedHandler = useRef<typeof handler>(() => ({}));

	useEffect(() => {
		savedHandler.current = handler;
	}, [ handler ]);

	useEffect(() => {
		const eventListener: typeof handler = (event) => savedHandler.current(event);
		window.addEventListener(name, eventListener);
		return () => {
			window.removeEventListener(name, eventListener);
		};
	}, [ name ]);
}
