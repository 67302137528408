export const Regions = <const>{
	'kanto': 'kanto',
	'johto': 'johto',
	'hoenn': 'hoenn',
	'sinnoh': 'sinnoh',
	'unova': 'unova',
	'kalos': 'kalos',
	'alola': 'alola',
	'galar': 'galar',
};

export type Region = keyof typeof Regions;
