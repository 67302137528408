import { ElementTypes, TypeId } from 'poke-data';

export function ElementImage({
	typeId,
	isActive = false,
	onClick,
	className = 'w-7',
}: {
	typeId: TypeId,
	isActive?: boolean,
	onClick?: (x: TypeId) => void,
	className?: string,
}): JSX.Element {
	const type = ElementTypes[typeId];
	const url = isActive
		? require(`pokesprite-images/misc/types/gen8/${type}.png`)
		: require(`pokesprite-images/misc/type-logos/gen8/${type}.png`);
	return <img
		className={ className }
		src={ url.default }
		alt={ type }
		role={ onClick ? 'button' : undefined }
		onClick={ onClick ? () => onClick(typeId) : undefined }
	/>;
}
