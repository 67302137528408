export const Settings = <const>{
	'alternateForms': 'alternateForms',
	'description': 'description',
	'evolutions': 'evolutions',
	'searchBar': 'searchBar',
	'typeEffectiveness': 'typeEffectiveness',
	'typeFilter': 'typeFilter',
};

export type Setting = keyof typeof Settings;

export const DefaultSettings: Record<Setting, boolean> = <const>{
	'alternateForms': false,
	'description': false,
	'evolutions': true,
	'searchBar': true,
	'typeEffectiveness': true,
	'typeFilter': true,
};

export const DefaultSections: readonly Section[] = <const>[
	'searchBar',
	'typeFilter',
	'typeEffectiveness',
	'evolutions',
];

export interface SectionAction {
	section: Section,
	enabled: boolean,
}

export const SectionNames: {[k in Section]: string} = <const>{
	'alternateForms': 'Alternate Forms',
	'description': 'Description',
	'evolutions': 'Evolutions',
	'searchBar': 'Search Bar',
	'typeEffectiveness': 'Type Effectiveness',
	'typeFilter': 'Type Filter',
};

export const SectionIds: {[k in Section]: k} = <const>{
	'alternateForms': 'alternateForms',
	'description': 'description',
	'evolutions': 'evolutions',
	'searchBar': 'searchBar',
	'typeEffectiveness': 'typeEffectiveness',
	'typeFilter': 'typeFilter',
};

export const SortableSections = <const>[
	'alternateForms',
	'description',
	'evolutions',
	'typeEffectiveness',
];

export type Section = typeof AllSections[number];

export const AllSections = <const>[
	'alternateForms',
	'description',
	'evolutions',
	'searchBar',
	'typeEffectiveness',
	'typeFilter',
];
