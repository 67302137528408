import type { PokemonFormId, EvolutionGroupId } from './';

export const AlternateForms: Record<EvolutionGroupId, ReadonlyArray<PokemonFormId>> = <const>{
	1: [
		10133,
		10413,
	],
	2: [
		10134,
		10135,
		10414,
	],
	3: [
		10136,
		10415,
	],
	4: [
		10416,
	],
	5: [
		10192,
	],
	6: [
		10175,
	],
	7: [
		10195,
	],
	8: [],
	9: [],
	10: [
		10182,
		10183,
		10184,
		10185,
		10186,
		10187,
		10196,
		10197,
		10198,
		10199,
		10200,
		10201,
		10267,
		10417,
	],
	11: [],
	12: [],
	13: [],
	14: [],
	15: [],
	16: [],
	17: [],
	18: [],
	19: [],
	20: [],
	21: [],
	22: [
		10418,
	],
	23: [],
	24: [],
	25: [],
	26: [],
	27: [
		10137,
	],
	28: [
		10419,
	],
	29: [],
	30: [],
	31: [],
	32: [],
	33: [
		10173,
	],
	34: [],
	35: [],
	36: [],
	37: [],
	38: [],
	39: [],
	40: [
		10138,
		10420,
	],
	41: [
		10174,
	],
	42: [],
	43: [
		10421,
	],
	44: [],
	45: [],
	46: [],
	47: [],
	48: [],
	49: [],
	50: [],
	51: [],
	52: [],
	53: [
		10139,
	],
	54: [],
	55: [],
	56: [],
	57: [],
	58: [
		10146,
	],
	59: [],
	60: [],
	61: [],
	62: [
		10140,
	],
	63: [],
	64: [
		10141,
	],
	65: [
		10422,
	],
	66: [],
	67: [
		10423,
	],
	68: [],
	69: [],
	70: [],
	71: [
		10142,
	],
	72: [
		10424,
	],
	73: [],
	74: [],
	75: [],
	76: [],
	77: [
		10143,
		10144,
	],
	78: [],
	79: [],
	80: [],
	81: [],
	82: [],
	83: [],
	84: [],
	85: [],
	86: [],
	87: [],
	88: [],
	89: [
		10145,
	],
	90: [],
	91: [],
	92: [],
	93: [],
	94: [],
	95: [],
	96: [],
	97: [],
	98: [],
	99: [
		10001,
		10002,
		10003,
		10004,
		10005,
		10006,
		10007,
		10008,
		10009,
		10010,
		10011,
		10012,
		10013,
		10014,
		10015,
		10016,
		10017,
		10018,
		10019,
		10020,
		10021,
		10022,
		10023,
		10024,
		10025,
		10026,
		10027,
	],
	100: [],
	101: [],
	102: [],
	103: [],
	104: [],
	105: [],
	106: [],
	107: [],
	108: [
		10147,
	],
	109: [],
	110: [],
	111: [],
	112: [],
	113: [],
	114: [],
	115: [],
	116: [],
	117: [],
	118: [
		10148,
	],
	119: [],
	120: [],
	121: [],
	122: [],
	123: [],
	124: [],
	125: [],
	126: [
		10149,
	],
	127: [],
	128: [],
	129: [],
	130: [
		10167,
	],
	131: [
		10150,
	],
	132: [
		10166,
	],
	133: [],
	134: [],
	135: [],
	136: [],
	137: [],
	138: [],
	139: [],
	140: [
		10151,
		10170,
	],
	141: [],
	142: [],
	143: [],
	144: [],
	145: [],
	146: [],
	147: [],
	148: [],
	149: [
		10168,
	],
	150: [
		10152,
	],
	151: [
		10153,
	],
	152: [
		10154,
	],
	153: [
		10155,
	],
	154: [],
	155: [],
	156: [],
	157: [],
	158: [],
	159: [],
	160: [
		10172,
	],
	161: [],
	162: [
		10189,
	],
	163: [],
	164: [],
	165: [],
	166: [],
	167: [],
	168: [
		10169,
	],
	169: [],
	170: [],
	171: [],
	172: [],
	173: [],
	174: [],
	175: [],
	176: [],
	177: [],
	178: [],
	179: [],
	180: [],
	181: [
		10156,
	],
	182: [],
	183: [],
	184: [],
	185: [
		10157,
	],
	186: [
		10176,
	],
	187: [],
	188: [],
	189: [],
	190: [],
	191: [
		10191,
	],
	192: [
		10178,
	],
	193: [],
	194: [],
	195: [],
	196: [
		10164,
	],
	197: [
		10165,
	],
	198: [
		10179,
	],
	199: [],
	200: [
		10181,
	],
	201: [],
	202: [
		10031,
		10032,
		10033,
	],
	203: [],
	204: [],
	205: [],
	206: [],
	207: [],
	208: [],
	209: [],
	211: [],
	212: [],
	213: [
		10034,
		10035,
	],
	214: [],
	215: [],
	216: [],
	217: [
		10038,
	],
	218: [
		10039,
		10040,
	],
	219: [],
	220: [
		10190,
	],
	221: [],
	223: [],
	224: [],
	228: [],
	229: [],
	230: [
		10158,
	],
	232: [
		10159,
	],
	233: [],
	234: [],
	235: [],
	236: [],
	237: [],
	239: [
		10160,
	],
	240: [],
	241: [],
	242: [],
	243: [],
	244: [],
	245: [],
	246: [],
	247: [],
	248: [
		10063,
	],
	249: [],
	250: [],
	252: [],
	253: [],
	254: [
		10041,
		10042,
		10043,
		10044,
		10045,
		10046,
		10047,
		10048,
		10049,
		10050,
		10051,
		10052,
		10053,
		10054,
		10055,
		10056,
		10057,
		10085,
	],
	255: [],
	256: [],
	257: [],
	258: [],
	259: [],
	260: [],
	261: [],
	262: [],
	263: [],
	264: [],
	265: [],
	266: [],
	267: [],
	268: [],
	269: [],
	270: [],
	271: [
		10171,
	],
	272: [],
	273: [],
	274: [],
	275: [],
	276: [],
	277: [],
	278: [],
	279: [],
	280: [
		10066,
	],
	281: [],
	282: [],
	283: [],
	284: [],
	285: [],
	286: [],
	287: [],
	288: [],
	289: [],
	290: [
		10425,
	],
	291: [],
	292: [],
	293: [],
	294: [],
	295: [],
	296: [],
	297: [
		10068,
		10069,
		10070,
		10071,
		10072,
		10073,
	],
	298: [],
	299: [],
	300: [],
	301: [],
	302: [],
	303: [],
	304: [],
	305: [],
	306: [],
	307: [],
	308: [],
	309: [],
	310: [],
	311: [],
	312: [],
	313: [],
	314: [],
	315: [],
	316: [],
	317: [],
	318: [],
	319: [],
	320: [],
	321: [],
	322: [],
	323: [],
	324: [],
	325: [],
	326: [],
	327: [],
	328: [
		10079,
	],
	329: [
		10080,
	],
	330: [],
	331: [],
	332: [
		10081,
	],
	333: [
		10082,
		10083,
	],
	334: [
		10084,
	],
	335: [],
	336: [
		10075,
		10076,
		10077,
		10078,
	],
	337: [],
	338: [],
	339: [
		10218,
		10219,
	],
	340: [],
	341: [],
	342: [
		10086,
		10087,
		10088,
		10089,
		10090,
		10091,
		10092,
		10093,
		10094,
		10095,
		10096,
		10097,
		10098,
		10099,
		10100,
		10101,
		10102,
		10161,
		10162,
	],
	343: [],
	344: [
		10103,
		10104,
		10105,
		10106,
		10107,
		10108,
		10109,
		10110,
		10111,
		10112,
		10113,
		10114,
		10163,
	],
	345: [],
	346: [],
	347: [
		10115,
		10116,
		10117,
		10118,
		10119,
		10120,
		10121,
		10122,
		10123,
	],
	348: [
		10124,
	],
	349: [
		10125,
	],
	350: [],
	351: [],
	352: [],
	353: [],
	354: [],
	355: [],
	356: [],
	357: [],
	358: [],
	359: [],
	360: [],
	361: [],
	362: [],
	363: [],
	364: [],
	365: [
		10126,
		10127,
		10128,
		10129,
		10130,
		10131,
	],
	366: [],
	367: [],
	368: [
		10132,
	],
	369: [],
	370: [
		10220,
		10221,
		10222,
	],
	371: [
		10177,
	],
	372: [],
	373: [],
	374: [],
	375: [],
	376: [],
	377: [],
	378: [
		10223,
	],
	379: [
		10224,
	],
	380: [],
	381: [],
	382: [
		10309,
	],
	383: [
		10228,
		10310,
		10311,
	],
	384: [
		10229,
	],
	385: [],
	386: [],
	387: [],
	388: [],
	389: [],
	390: [],
	391: [],
	392: [],
	393: [],
	394: [],
	395: [],
	396: [],
	397: [],
	398: [],
	399: [],
	400: [
		10249,
		10250,
		10251,
		10252,
		10253,
		10254,
		10255,
		10256,
		10257,
		10258,
		10259,
		10260,
		10261,
	],
	401: [],
	402: [],
	403: [],
	404: [
		10262,
		10263,
		10264,
	],
	405: [],
	406: [],
	407: [],
	408: [
		10265,
	],
	409: [],
	410: [],
	411: [],
	412: [],
	413: [],
	414: [],
	415: [],
	416: [],
	417: [],
	418: [],
	419: [],
	420: [],
	421: [],
	422: [
		10266,
	],
	423: [],
	424: [],
	425: [],
	426: [],
	427: [],
	428: [
		10426,
	],
	429: [
		10427,
	],
	430: [
		10428,
	],
	431: [
		10429,
	],
	432: [],
	433: [
		10430,
	],
	434: [
		10431,
	],
	435: [],
	436: [],
	437: [],
	438: [
		10432,
	],
	439: [],
	440: [
		10433,
	],
	441: [
		10434,
		10435,
	],
	442: [
		10436,
	],
	443: [],
	444: [],
	445: [
		10339,
		10437,
		10447,
	],
	446: [
		10438,
	],
	447: [],
	448: [],
	449: [
		10439,
	],
	450: [
		10440,
	],
	451: [
		10441,
	],
	452: [],
	453: [],
	454: [],
	455: [],
	456: [
		10404,
	],
	457: [
		10405,
	],
	458: [],
	459: [
		10442,
	],
	460: [],
	461: [],
	462: [],
	463: [],
	464: [
		10443,
	],
	465: [],
	466: [],
	467: [],
	468: [
		10444,
	],
	469: [
		10445,
		10446,
	],
	470: [],
	471: [],
	472: [],
	473: [],
	474: [],
	475: [],
};
