import { map } from 'lodash';
import { memo, useCallback } from 'react';
import { ElementImage } from '../Pokedex/ElementImage';
import { MonoTypeToggle } from '../Pokedex/MonoTypeToggle';
import {
	TypeId,
	TypeIds,
} from 'poke-data';

export const Filter = memo(function Filter({
	activeTypes,
	onlyMonoTypes,
	setActiveTypes,
	setOnlyMonoTypes,
}: {
	activeTypes: TypeId[],
	onlyMonoTypes: boolean,
	setActiveTypes: (x: TypeId[] | ((x: TypeId[]) => TypeId[])) => void,
	setOnlyMonoTypes: (x: boolean | ((x: boolean) => boolean)) => void,
}): JSX.Element {

	const handleTypeClick = useCallback((type: TypeId) => {
		setOnlyMonoTypes(false);
		if (activeTypes.includes(type)) {
			setActiveTypes(prev => {
				const types = [ ...prev ];
				const i = types.indexOf(type);
				if (~i) {
					types.splice(i, 1);
				}
				return types;
			});
		} else {
			setActiveTypes(prev => {
				const types = [ ...prev ];
				const count = types.unshift(type);
				if (count > 2) {
					types.pop();
				}
				return types;
			});
		}
	}, [ activeTypes ]);

	return <div
		className="grid grid-rows-2 w-96"
	>
		{ map(TypeIds, typeId => {
			return <ElementImage
				key={ typeId }
				typeId={ typeId }
				isActive={ activeTypes.includes(typeId) }
				onClick={ handleTypeClick }
			/>;
		})}
		<div
			className="w-7 col-start-10 row-span-full self-center"
		>
			{ activeTypes.length === 1 &&
				<MonoTypeToggle
					selected={ onlyMonoTypes }
					setOnlyMonoTypes={ setOnlyMonoTypes }
					typeId={ activeTypes[0] }
				/>}
		</div>
	</div>;
});
