import { useState } from 'react';
import { PokemonForm } from 'poke-data';
import { Description } from './Description';
import { Evolutions } from './Evolutions';
import { Forms } from './Forms';
import { Nameplate } from './Nameplate';
import { Search } from '../Search';
import { TypeEffectiveness } from './TypeEffectiveness';
import { Section, SectionIds, SortableSections } from 'src/data/settings';
import { intersection, map } from 'lodash';
import { SectionProps, SectionWrapper } from './SectionWrapper';

type SortableSection = Exclude<Section, 'searchBar' | 'typeFilter'>;

function useSectionFactory(
	pokemon: PokemonForm | undefined,
	setPokemon: (p: PokemonForm | undefined) => void,
) {
	const SectionComponents: Record<SortableSection, (p: SectionProps) => JSX.Element> = {
		'alternateForms': Forms,
		'description': Description,
		'evolutions': Evolutions,
		'typeEffectiveness': TypeEffectiveness,
	};

	function buildSection(
		section: SortableSection,
	) {
		if (!pokemon) {
			return <></>;
		}
		return <SectionWrapper
			key={ section }
			pokemon={ pokemon }
			setPokemon={ setPokemon }
			Wrapped={ SectionComponents[section] }
		/>;
	}

	return buildSection;
}

export function Pokedex({
	sections,
}: {
	sections: Section[],
}): JSX.Element {
	const [ pokemon, setPokemon ] = useState<PokemonForm | undefined>();
	const buildSection = useSectionFactory(pokemon, setPokemon);

	return <>
		<Search
			pokemon={ pokemon }
			searchBar={ sections.includes(SectionIds.searchBar) }
			typeFilter={ sections.includes(SectionIds.typeFilter) }
			setPokemon={ setPokemon }
		/>
		{pokemon && <>
			<Nameplate
				pokemon={ pokemon }
			/>
			{ map(intersection(sections, SortableSections), buildSection) }
		</>}
	</>;
}

