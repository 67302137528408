import { ElementImage } from './ElementImage';
import { PokemonImage } from './PokemonImage';
import {
	StatusColors,
	PokemonForm,
} from 'poke-data';

export function Nameplate({
	pokemon,
}: {
	pokemon: PokemonForm,
}): JSX.Element {
	return <div
		className="bg-header w-full h-20 pb-4"
	>
		<div
			className="grid grid-flow-col gap-1 justify-center items-end mx-auto w-96 h-20 pb-4"
		>
			<PokemonImage
				className="h-12 mr-1"
				pokemon={ pokemon }
			/>
			<span
				className="mb-1 overflow-x-hidden whitespace-nowrap overflow-ellipsis"
			>
				<span
					className="block text-sm text-bw"
				>
					#{ pokemon.species_id.toString().padStart(3, '0') }
				</span>
				<span
					className={ 'border-b ' + StatusColors[pokemon.status] }
				>
					{ pokemon.name }
				</span>
			</span>
			{ pokemon.type_ids.map(
				typeId => <ElementImage key={ typeId } typeId={ typeId } />,
			) }
		</div>
	</div>;
}
