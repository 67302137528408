import { useStorageReducer } from 'src/hook/useStorageReducer';
import { Pokedex } from './Pokedex';
import { Settings } from './Settings';

export function App(): JSX.Element {
	const [ sections, setSection ] = useStorageReducer();

	return <div
		className="grid grid-flow-row w-full justify-items-center font-body text-base text-white bg-black pt-2"
	>
		<Settings
			{ ...{
				sections,
				setSection,
			} }
		/>
		<Pokedex
			sections={ sections }
		/>
	</div>;
}
