import { Setting } from 'poke-data';
import { SectionAction, SectionNames } from 'src/data/settings';

export function SectionSetting({
	id,
	enabled,
	setSection,
}: {
	id: Setting,
	enabled: boolean,
	setSection: React.Dispatch<SectionAction>
}): JSX.Element {
	return <label
		className="h-7 leading-7 block text-sm"
	>
		<input
			className="mr-2 h-7 align-middle"
			type="checkbox"
			checked={ enabled }
			onChange={ (e) => {
				setSection({
					section: id,
					enabled: e.target.checked,
				});
			} }
		/>
		<span className="align-middle uppercase">
			{ SectionNames[id] }
		</span>
	</label>;
}
