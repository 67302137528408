import type { PokemonFormId, EvolutionGroupId } from './';

export const EvolutionChains: Record<EvolutionGroupId, ReadonlyArray<ReadonlyArray<ReadonlyArray<PokemonFormId>>>> = <const>{
	1: [
		[
			[
				1,
			],
			[
				2,
			],
			[
				3,
			],
		],
	],
	2: [
		[
			[
				4,
			],
			[
				5,
			],
			[
				6,
			],
		],
	],
	3: [
		[
			[
				7,
			],
			[
				8,
			],
			[
				9,
			],
		],
	],
	4: [
		[
			[
				10,
			],
			[
				11,
			],
			[
				12,
			],
		],
	],
	5: [
		[
			[
				13,
			],
			[
				14,
			],
			[
				15,
			],
		],
	],
	6: [
		[
			[
				16,
			],
			[
				17,
			],
			[
				18,
			],
		],
	],
	7: [
		[
			[
				19,
			],
			[
				20,
			],
		],
		[
			[
				10193,
			],
			[
				10194,
			],
		],
	],
	8: [
		[
			[
				21,
			],
			[
				22,
			],
		],
	],
	9: [
		[
			[
				23,
			],
			[
				24,
			],
		],
	],
	10: [
		[
			[
				172,
			],
			[
				25,
			],
			[
				26,
				10202,
			],
		],
	],
	11: [
		[
			[
				27,
			],
			[
				28,
			],
		],
		[
			[
				10203,
			],
			[
				10204,
			],
		],
	],
	12: [
		[
			[
				29,
			],
			[
				30,
			],
			[
				31,
			],
		],
	],
	13: [
		[
			[
				32,
			],
			[
				33,
			],
			[
				34,
			],
		],
	],
	14: [
		[
			[
				173,
			],
			[
				35,
			],
			[
				36,
			],
		],
	],
	15: [
		[
			[
				37,
			],
			[
				38,
			],
		],
		[
			[
				10205,
			],
			[
				10206,
			],
		],
	],
	16: [
		[
			[
				174,
			],
			[
				39,
			],
			[
				40,
			],
		],
	],
	17: [
		[
			[
				41,
			],
			[
				42,
			],
			[
				169,
			],
		],
	],
	18: [
		[
			[
				43,
			],
			[
				44,
			],
			[
				45,
				182,
			],
		],
	],
	19: [
		[
			[
				46,
			],
			[
				47,
			],
		],
	],
	20: [
		[
			[
				48,
			],
			[
				49,
			],
		],
	],
	21: [
		[
			[
				50,
			],
			[
				51,
			],
		],
		[
			[
				10207,
			],
			[
				10208,
			],
		],
	],
	22: [
		[
			[
				52,
			],
			[
				53,
			],
		],
		[
			[
				10209,
			],
			[
				10210,
			],
		],
		[
			[
				10317,
			],
			[
				863,
			],
		],
	],
	23: [
		[
			[
				54,
			],
			[
				55,
			],
		],
	],
	24: [
		[
			[
				56,
			],
			[
				57,
			],
		],
	],
	25: [
		[
			[
				58,
			],
			[
				59,
			],
		],
	],
	26: [
		[
			[
				60,
			],
			[
				61,
			],
			[
				62,
				186,
			],
		],
	],
	27: [
		[
			[
				63,
			],
			[
				64,
			],
			[
				65,
			],
		],
	],
	28: [
		[
			[
				66,
			],
			[
				67,
			],
			[
				68,
			],
		],
	],
	29: [
		[
			[
				69,
			],
			[
				70,
			],
			[
				71,
			],
		],
	],
	30: [
		[
			[
				72,
			],
			[
				73,
			],
		],
	],
	31: [
		[
			[
				74,
			],
			[
				75,
			],
			[
				76,
			],
		],
		[
			[
				10211,
			],
			[
				10212,
			],
			[
				10213,
			],
		],
	],
	32: [
		[
			[
				77,
			],
			[
				78,
			],
		],
		[
			[
				10318,
			],
			[
				10319,
			],
		],
	],
	33: [
		[
			[
				79,
			],
			[
				80,
				199,
			],
		],
		[
			[
				10320,
			],
			[
				10321,
				10328,
			],
		],
	],
	34: [
		[
			[
				81,
			],
			[
				82,
			],
			[
				462,
			],
		],
	],
	35: [
		[
			[
				83,
			],
		],
		[
			[
				10322,
			],
			[
				865,
			],
		],
	],
	36: [
		[
			[
				84,
			],
			[
				85,
			],
		],
	],
	37: [
		[
			[
				86,
			],
			[
				87,
			],
		],
	],
	38: [
		[
			[
				88,
			],
			[
				89,
			],
		],
		[
			[
				10214,
			],
			[
				10215,
			],
		],
	],
	39: [
		[
			[
				90,
			],
			[
				91,
			],
		],
	],
	40: [
		[
			[
				92,
			],
			[
				93,
			],
			[
				94,
			],
		],
	],
	41: [
		[
			[
				95,
			],
			[
				208,
			],
		],
	],
	42: [
		[
			[
				96,
			],
			[
				97,
			],
		],
	],
	43: [
		[
			[
				98,
			],
			[
				99,
			],
		],
	],
	44: [
		[
			[
				100,
			],
			[
				101,
			],
		],
	],
	45: [
		[
			[
				102,
			],
			[
				103,
				10216,
			],
		],
	],
	46: [
		[
			[
				104,
			],
			[
				105,
				10217,
			],
		],
	],
	47: [
		[
			[
				236,
			],
			[
				106,
				107,
				237,
			],
		],
	],
	48: [
		[
			[
				108,
			],
			[
				463,
			],
		],
	],
	49: [
		[
			[
				109,
			],
			[
				110,
				10323,
			],
		],
	],
	50: [
		[
			[
				111,
			],
			[
				112,
			],
			[
				464,
			],
		],
	],
	51: [
		[
			[
				440,
			],
			[
				113,
			],
			[
				242,
			],
		],
	],
	52: [
		[
			[
				114,
			],
			[
				465,
			],
		],
	],
	53: [
		[
			[
				115,
			],
		],
	],
	54: [
		[
			[
				116,
			],
			[
				117,
			],
			[
				230,
			],
		],
	],
	55: [
		[
			[
				118,
			],
			[
				119,
			],
		],
	],
	56: [
		[
			[
				120,
			],
			[
				121,
			],
		],
	],
	57: [
		[
			[
				439,
			],
			[
				122,
				10324,
			],
			[
				866,
			],
		],
	],
	58: [
		[
			[
				123,
			],
			[
				212,
			],
		],
	],
	59: [
		[
			[
				238,
			],
			[
				124,
			],
		],
	],
	60: [
		[
			[
				239,
			],
			[
				125,
			],
			[
				466,
			],
		],
	],
	61: [
		[
			[
				240,
			],
			[
				126,
			],
			[
				467,
			],
		],
	],
	62: [
		[
			[
				127,
			],
		],
	],
	63: [
		[
			[
				128,
			],
		],
	],
	64: [
		[
			[
				129,
			],
			[
				130,
			],
		],
	],
	65: [
		[
			[
				131,
			],
		],
	],
	66: [
		[
			[
				132,
			],
		],
	],
	67: [
		[
			[
				133,
			],
			[
				134,
				135,
				136,
				196,
				197,
				470,
				471,
				700,
			],
		],
	],
	68: [
		[
			[
				137,
			],
			[
				233,
			],
			[
				474,
			],
		],
	],
	69: [
		[
			[
				138,
			],
			[
				139,
			],
		],
	],
	70: [
		[
			[
				140,
			],
			[
				141,
			],
		],
	],
	71: [
		[
			[
				142,
			],
		],
	],
	72: [
		[
			[
				446,
			],
			[
				143,
			],
		],
	],
	73: [
		[
			[
				144,
			],
		],
		[
			[
				10325,
			],
		],
	],
	74: [
		[
			[
				145,
			],
		],
		[
			[
				10326,
			],
		],
	],
	75: [
		[
			[
				146,
			],
		],
		[
			[
				10327,
			],
		],
	],
	76: [
		[
			[
				147,
			],
			[
				148,
			],
			[
				149,
			],
		],
	],
	77: [
		[
			[
				150,
			],
		],
	],
	78: [
		[
			[
				151,
			],
		],
	],
	79: [
		[
			[
				152,
			],
			[
				153,
			],
			[
				154,
			],
		],
	],
	80: [
		[
			[
				155,
			],
			[
				156,
			],
			[
				157,
			],
		],
	],
	81: [
		[
			[
				158,
			],
			[
				159,
			],
			[
				160,
			],
		],
	],
	82: [
		[
			[
				161,
			],
			[
				162,
			],
		],
	],
	83: [
		[
			[
				163,
			],
			[
				164,
			],
		],
	],
	84: [
		[
			[
				165,
			],
			[
				166,
			],
		],
	],
	85: [
		[
			[
				167,
			],
			[
				168,
			],
		],
	],
	86: [
		[
			[
				170,
			],
			[
				171,
			],
		],
	],
	87: [
		[
			[
				175,
			],
			[
				176,
			],
			[
				468,
			],
		],
	],
	88: [
		[
			[
				177,
			],
			[
				178,
			],
		],
	],
	89: [
		[
			[
				179,
			],
			[
				180,
			],
			[
				181,
			],
		],
	],
	90: [
		[
			[
				298,
			],
			[
				183,
			],
			[
				184,
			],
		],
	],
	91: [
		[
			[
				438,
			],
			[
				185,
			],
		],
	],
	92: [
		[
			[
				187,
			],
			[
				188,
			],
			[
				189,
			],
		],
	],
	93: [
		[
			[
				190,
			],
			[
				424,
			],
		],
	],
	94: [
		[
			[
				191,
			],
			[
				192,
			],
		],
	],
	95: [
		[
			[
				193,
			],
			[
				469,
			],
		],
	],
	96: [
		[
			[
				194,
			],
			[
				195,
			],
		],
	],
	97: [
		[
			[
				198,
			],
			[
				430,
			],
		],
	],
	98: [
		[
			[
				200,
			],
			[
				429,
			],
		],
	],
	99: [
		[
			[
				201,
			],
		],
	],
	100: [
		[
			[
				360,
			],
			[
				202,
			],
		],
	],
	101: [
		[
			[
				203,
			],
		],
	],
	102: [
		[
			[
				204,
			],
			[
				205,
			],
		],
	],
	103: [
		[
			[
				206,
			],
		],
	],
	104: [
		[
			[
				207,
			],
			[
				472,
			],
		],
	],
	105: [
		[
			[
				209,
			],
			[
				210,
			],
		],
	],
	106: [
		[
			[
				211,
			],
		],
	],
	107: [
		[
			[
				213,
			],
		],
	],
	108: [
		[
			[
				214,
			],
		],
	],
	109: [
		[
			[
				215,
			],
			[
				461,
			],
		],
	],
	110: [
		[
			[
				216,
			],
			[
				217,
			],
		],
	],
	111: [
		[
			[
				218,
			],
			[
				219,
			],
		],
	],
	112: [
		[
			[
				220,
			],
			[
				221,
			],
			[
				473,
			],
		],
	],
	113: [
		[
			[
				222,
			],
		],
		[
			[
				10329,
			],
			[
				864,
			],
		],
	],
	114: [
		[
			[
				223,
			],
			[
				224,
			],
		],
	],
	115: [
		[
			[
				225,
			],
		],
	],
	116: [
		[
			[
				458,
			],
			[
				226,
			],
		],
	],
	117: [
		[
			[
				227,
			],
		],
	],
	118: [
		[
			[
				228,
			],
			[
				229,
			],
		],
	],
	119: [
		[
			[
				231,
			],
			[
				232,
			],
		],
	],
	120: [
		[
			[
				234,
			],
		],
	],
	121: [
		[
			[
				235,
			],
		],
	],
	122: [
		[
			[
				241,
			],
		],
	],
	123: [
		[
			[
				243,
			],
		],
	],
	124: [
		[
			[
				244,
			],
		],
	],
	125: [
		[
			[
				245,
			],
		],
	],
	126: [
		[
			[
				246,
			],
			[
				247,
			],
			[
				248,
			],
		],
	],
	127: [
		[
			[
				249,
			],
		],
	],
	128: [
		[
			[
				250,
			],
		],
	],
	129: [
		[
			[
				251,
			],
		],
	],
	130: [
		[
			[
				252,
			],
			[
				253,
			],
			[
				254,
			],
		],
	],
	131: [
		[
			[
				255,
			],
			[
				256,
			],
			[
				257,
			],
		],
	],
	132: [
		[
			[
				258,
			],
			[
				259,
			],
			[
				260,
			],
		],
	],
	133: [
		[
			[
				261,
			],
			[
				262,
			],
		],
	],
	134: [
		[
			[
				263,
			],
			[
				264,
			],
		],
		[
			[
				10330,
			],
			[
				10331,
			],
			[
				862,
			],
		],
	],
	135: [
		[
			[
				265,
			],
			[
				266,
				268,
			],
			[
				267,
				269,
			],
		],
	],
	136: [
		[
			[
				270,
			],
			[
				271,
			],
			[
				272,
			],
		],
	],
	137: [
		[
			[
				273,
			],
			[
				274,
			],
			[
				275,
			],
		],
	],
	138: [
		[
			[
				276,
			],
			[
				277,
			],
		],
	],
	139: [
		[
			[
				278,
			],
			[
				279,
			],
		],
	],
	140: [
		[
			[
				280,
			],
			[
				281,
			],
			[
				282,
				475,
			],
		],
	],
	141: [
		[
			[
				283,
			],
			[
				284,
			],
		],
	],
	142: [
		[
			[
				285,
			],
			[
				286,
			],
		],
	],
	143: [
		[
			[
				287,
			],
			[
				288,
			],
			[
				289,
			],
		],
	],
	144: [
		[
			[
				290,
			],
			[
				291,
				292,
			],
		],
	],
	145: [
		[
			[
				293,
			],
			[
				294,
			],
			[
				295,
			],
		],
	],
	146: [
		[
			[
				296,
			],
			[
				297,
			],
		],
	],
	147: [
		[
			[
				299,
			],
			[
				476,
			],
		],
	],
	148: [
		[
			[
				300,
			],
			[
				301,
			],
		],
	],
	149: [
		[
			[
				302,
			],
		],
	],
	150: [
		[
			[
				303,
			],
		],
	],
	151: [
		[
			[
				304,
			],
			[
				305,
			],
			[
				306,
			],
		],
	],
	152: [
		[
			[
				307,
			],
			[
				308,
			],
		],
	],
	153: [
		[
			[
				309,
			],
			[
				310,
			],
		],
	],
	154: [
		[
			[
				311,
			],
		],
	],
	155: [
		[
			[
				312,
			],
		],
	],
	156: [
		[
			[
				313,
			],
		],
	],
	157: [
		[
			[
				314,
			],
		],
	],
	158: [
		[
			[
				406,
			],
			[
				315,
			],
			[
				407,
			],
		],
	],
	159: [
		[
			[
				316,
			],
			[
				317,
			],
		],
	],
	160: [
		[
			[
				318,
			],
			[
				319,
			],
		],
	],
	161: [
		[
			[
				320,
			],
			[
				321,
			],
		],
	],
	162: [
		[
			[
				322,
			],
			[
				323,
			],
		],
	],
	163: [
		[
			[
				324,
			],
		],
	],
	164: [
		[
			[
				325,
			],
			[
				326,
			],
		],
	],
	165: [
		[
			[
				327,
			],
		],
	],
	166: [
		[
			[
				328,
			],
			[
				329,
			],
			[
				330,
			],
		],
	],
	167: [
		[
			[
				331,
			],
			[
				332,
			],
		],
	],
	168: [
		[
			[
				333,
			],
			[
				334,
			],
		],
	],
	169: [
		[
			[
				335,
			],
		],
	],
	170: [
		[
			[
				336,
			],
		],
	],
	171: [
		[
			[
				337,
			],
		],
	],
	172: [
		[
			[
				338,
			],
		],
	],
	173: [
		[
			[
				339,
			],
			[
				340,
			],
		],
	],
	174: [
		[
			[
				341,
			],
			[
				342,
			],
		],
	],
	175: [
		[
			[
				343,
			],
			[
				344,
			],
		],
	],
	176: [
		[
			[
				345,
			],
			[
				346,
			],
		],
	],
	177: [
		[
			[
				347,
			],
			[
				348,
			],
		],
	],
	178: [
		[
			[
				349,
			],
			[
				350,
			],
		],
	],
	179: [
		[
			[
				351,
			],
		],
		[
			[
				10028,
			],
		],
		[
			[
				10029,
			],
		],
		[
			[
				10030,
			],
		],
	],
	180: [
		[
			[
				352,
			],
		],
	],
	181: [
		[
			[
				353,
			],
			[
				354,
			],
		],
	],
	182: [
		[
			[
				355,
			],
			[
				356,
			],
			[
				477,
			],
		],
	],
	183: [
		[
			[
				357,
			],
		],
	],
	184: [
		[
			[
				433,
			],
			[
				358,
			],
		],
	],
	185: [
		[
			[
				359,
			],
		],
	],
	186: [
		[
			[
				361,
			],
			[
				362,
				478,
			],
		],
	],
	187: [
		[
			[
				363,
			],
			[
				364,
			],
			[
				365,
			],
		],
	],
	188: [
		[
			[
				366,
			],
			[
				367,
				368,
			],
		],
	],
	189: [
		[
			[
				369,
			],
		],
	],
	190: [
		[
			[
				370,
			],
		],
	],
	191: [
		[
			[
				371,
			],
			[
				372,
			],
			[
				373,
			],
		],
	],
	192: [
		[
			[
				374,
			],
			[
				375,
			],
			[
				376,
			],
		],
	],
	193: [
		[
			[
				377,
			],
		],
	],
	194: [
		[
			[
				378,
			],
		],
	],
	195: [
		[
			[
				379,
			],
		],
	],
	196: [
		[
			[
				380,
			],
		],
	],
	197: [
		[
			[
				381,
			],
		],
	],
	198: [
		[
			[
				382,
			],
		],
	],
	199: [
		[
			[
				383,
			],
		],
		[
			[
				10180,
			],
		],
	],
	200: [
		[
			[
				384,
			],
		],
	],
	201: [
		[
			[
				385,
			],
		],
	],
	202: [
		[
			[
				386,
			],
		],
	],
	203: [
		[
			[
				387,
			],
			[
				388,
			],
			[
				389,
			],
		],
	],
	204: [
		[
			[
				390,
			],
			[
				391,
			],
			[
				392,
			],
		],
	],
	205: [
		[
			[
				393,
			],
			[
				394,
			],
			[
				395,
			],
		],
	],
	206: [
		[
			[
				396,
			],
			[
				397,
			],
			[
				398,
			],
		],
	],
	207: [
		[
			[
				399,
			],
			[
				400,
			],
		],
	],
	208: [
		[
			[
				401,
			],
			[
				402,
			],
		],
	],
	209: [
		[
			[
				403,
			],
			[
				404,
			],
			[
				405,
			],
		],
	],
	211: [
		[
			[
				408,
			],
			[
				409,
			],
		],
	],
	212: [
		[
			[
				410,
			],
			[
				411,
			],
		],
	],
	213: [
		[
			[
				412,
			],
			[
				413,
				414,
				10036,
				10037,
			],
		],
	],
	214: [
		[
			[
				415,
			],
			[
				416,
			],
		],
	],
	215: [
		[
			[
				417,
			],
		],
	],
	216: [
		[
			[
				418,
			],
			[
				419,
			],
		],
	],
	217: [
		[
			[
				420,
			],
			[
				421,
			],
		],
	],
	218: [
		[
			[
				422,
			],
			[
				423,
			],
		],
	],
	219: [
		[
			[
				425,
			],
			[
				426,
			],
		],
	],
	220: [
		[
			[
				427,
			],
			[
				428,
			],
		],
	],
	221: [
		[
			[
				431,
			],
			[
				432,
			],
		],
	],
	223: [
		[
			[
				434,
			],
			[
				435,
			],
		],
	],
	224: [
		[
			[
				436,
			],
			[
				437,
			],
		],
	],
	228: [
		[
			[
				441,
			],
		],
	],
	229: [
		[
			[
				442,
			],
		],
	],
	230: [
		[
			[
				443,
			],
			[
				444,
			],
			[
				445,
			],
		],
	],
	232: [
		[
			[
				447,
			],
			[
				448,
			],
		],
	],
	233: [
		[
			[
				449,
			],
			[
				450,
			],
		],
	],
	234: [
		[
			[
				451,
			],
			[
				452,
			],
		],
	],
	235: [
		[
			[
				453,
			],
			[
				454,
			],
		],
	],
	236: [
		[
			[
				455,
			],
		],
	],
	237: [
		[
			[
				456,
			],
			[
				457,
			],
		],
	],
	239: [
		[
			[
				459,
			],
			[
				460,
			],
		],
	],
	240: [
		[
			[
				479,
			],
		],
		[
			[
				10058,
			],
		],
		[
			[
				10059,
			],
		],
		[
			[
				10060,
			],
		],
		[
			[
				10061,
			],
		],
		[
			[
				10062,
			],
		],
	],
	241: [
		[
			[
				480,
			],
		],
	],
	242: [
		[
			[
				481,
			],
		],
	],
	243: [
		[
			[
				482,
			],
		],
	],
	244: [
		[
			[
				483,
			],
		],
	],
	245: [
		[
			[
				484,
			],
		],
	],
	246: [
		[
			[
				485,
			],
		],
	],
	247: [
		[
			[
				486,
			],
		],
	],
	248: [
		[
			[
				487,
			],
		],
	],
	249: [
		[
			[
				488,
			],
		],
	],
	250: [
		[
			[
				489,
			],
		],
		[
			[
				490,
			],
		],
	],
	252: [
		[
			[
				491,
			],
		],
	],
	253: [
		[
			[
				492,
			],
		],
		[
			[
				10064,
			],
		],
	],
	254: [
		[
			[
				493,
			],
		],
	],
	255: [
		[
			[
				494,
			],
		],
	],
	256: [
		[
			[
				495,
			],
			[
				496,
			],
			[
				497,
			],
		],
	],
	257: [
		[
			[
				498,
			],
			[
				499,
			],
			[
				500,
			],
		],
	],
	258: [
		[
			[
				501,
			],
			[
				502,
			],
			[
				503,
			],
		],
	],
	259: [
		[
			[
				504,
			],
			[
				505,
			],
		],
	],
	260: [
		[
			[
				506,
			],
			[
				507,
			],
			[
				508,
			],
		],
	],
	261: [
		[
			[
				509,
			],
			[
				510,
			],
		],
	],
	262: [
		[
			[
				511,
			],
			[
				512,
			],
		],
	],
	263: [
		[
			[
				513,
			],
			[
				514,
			],
		],
	],
	264: [
		[
			[
				515,
			],
			[
				516,
			],
		],
	],
	265: [
		[
			[
				517,
			],
			[
				518,
			],
		],
	],
	266: [
		[
			[
				519,
			],
			[
				520,
			],
			[
				521,
			],
		],
	],
	267: [
		[
			[
				522,
			],
			[
				523,
			],
		],
	],
	268: [
		[
			[
				524,
			],
			[
				525,
			],
			[
				526,
			],
		],
	],
	269: [
		[
			[
				527,
			],
			[
				528,
			],
		],
	],
	270: [
		[
			[
				529,
			],
			[
				530,
			],
		],
	],
	271: [
		[
			[
				531,
			],
		],
	],
	272: [
		[
			[
				532,
			],
			[
				533,
			],
			[
				534,
			],
		],
	],
	273: [
		[
			[
				535,
			],
			[
				536,
			],
			[
				537,
			],
		],
	],
	274: [
		[
			[
				538,
			],
		],
	],
	275: [
		[
			[
				539,
			],
		],
	],
	276: [
		[
			[
				540,
			],
			[
				541,
			],
			[
				542,
			],
		],
	],
	277: [
		[
			[
				543,
			],
			[
				544,
			],
			[
				545,
			],
		],
	],
	278: [
		[
			[
				546,
			],
			[
				547,
			],
		],
	],
	279: [
		[
			[
				548,
			],
			[
				549,
			],
		],
	],
	280: [
		[
			[
				550,
			],
		],
	],
	281: [
		[
			[
				551,
			],
			[
				552,
			],
			[
				553,
			],
		],
	],
	282: [
		[
			[
				554,
			],
			[
				555,
				10067,
			],
		],
		[
			[
				10332,
			],
			[
				10333,
				10334,
			],
		],
	],
	283: [
		[
			[
				556,
			],
		],
	],
	284: [
		[
			[
				557,
			],
			[
				558,
			],
		],
	],
	285: [
		[
			[
				559,
			],
			[
				560,
			],
		],
	],
	286: [
		[
			[
				561,
			],
		],
	],
	287: [
		[
			[
				562,
			],
			[
				563,
			],
		],
		[
			[
				10335,
			],
			[
				867,
			],
		],
	],
	288: [
		[
			[
				564,
			],
			[
				565,
			],
		],
	],
	289: [
		[
			[
				566,
			],
			[
				567,
			],
		],
	],
	290: [
		[
			[
				568,
			],
			[
				569,
			],
		],
	],
	291: [
		[
			[
				570,
			],
			[
				571,
			],
		],
	],
	292: [
		[
			[
				572,
			],
			[
				573,
			],
		],
	],
	293: [
		[
			[
				574,
			],
			[
				575,
			],
			[
				576,
			],
		],
	],
	294: [
		[
			[
				577,
			],
			[
				578,
			],
			[
				579,
			],
		],
	],
	295: [
		[
			[
				580,
			],
			[
				581,
			],
		],
	],
	296: [
		[
			[
				582,
			],
			[
				583,
			],
			[
				584,
			],
		],
	],
	297: [
		[
			[
				585,
			],
			[
				586,
			],
		],
	],
	298: [
		[
			[
				587,
			],
		],
	],
	299: [
		[
			[
				588,
			],
			[
				589,
			],
		],
	],
	300: [
		[
			[
				590,
			],
			[
				591,
			],
		],
	],
	301: [
		[
			[
				592,
			],
			[
				593,
			],
		],
	],
	302: [
		[
			[
				594,
			],
		],
	],
	303: [
		[
			[
				595,
			],
			[
				596,
			],
		],
	],
	304: [
		[
			[
				597,
			],
			[
				598,
			],
		],
	],
	305: [
		[
			[
				599,
			],
			[
				600,
			],
			[
				601,
			],
		],
	],
	306: [
		[
			[
				602,
			],
			[
				603,
			],
			[
				604,
			],
		],
	],
	307: [
		[
			[
				605,
			],
			[
				606,
			],
		],
	],
	308: [
		[
			[
				607,
			],
			[
				608,
			],
			[
				609,
			],
		],
	],
	309: [
		[
			[
				610,
			],
			[
				611,
			],
			[
				612,
			],
		],
	],
	310: [
		[
			[
				613,
			],
			[
				614,
			],
		],
	],
	311: [
		[
			[
				615,
			],
		],
	],
	312: [
		[
			[
				616,
			],
			[
				617,
			],
		],
	],
	313: [
		[
			[
				618,
			],
		],
		[
			[
				10336,
			],
		],
	],
	314: [
		[
			[
				619,
			],
			[
				620,
			],
		],
	],
	315: [
		[
			[
				621,
			],
		],
	],
	316: [
		[
			[
				622,
			],
			[
				623,
			],
		],
	],
	317: [
		[
			[
				624,
			],
			[
				625,
			],
		],
	],
	318: [
		[
			[
				626,
			],
		],
	],
	319: [
		[
			[
				627,
			],
			[
				628,
			],
		],
	],
	320: [
		[
			[
				629,
			],
			[
				630,
			],
		],
	],
	321: [
		[
			[
				631,
			],
		],
	],
	322: [
		[
			[
				632,
			],
		],
	],
	323: [
		[
			[
				633,
			],
			[
				634,
			],
			[
				635,
			],
		],
	],
	324: [
		[
			[
				636,
			],
			[
				637,
			],
		],
	],
	325: [
		[
			[
				638,
			],
		],
	],
	326: [
		[
			[
				639,
			],
		],
	],
	327: [
		[
			[
				640,
			],
		],
	],
	328: [
		[
			[
				641,
			],
		],
	],
	329: [
		[
			[
				642,
			],
		],
	],
	330: [
		[
			[
				643,
			],
		],
	],
	331: [
		[
			[
				644,
			],
		],
	],
	332: [
		[
			[
				645,
			],
		],
	],
	333: [
		[
			[
				646,
			],
		],
	],
	334: [
		[
			[
				647,
			],
		],
	],
	335: [
		[
			[
				648,
			],
		],
		[
			[
				10074,
			],
		],
	],
	336: [
		[
			[
				649,
			],
		],
	],
	337: [
		[
			[
				650,
			],
			[
				651,
			],
			[
				652,
			],
		],
	],
	338: [
		[
			[
				653,
			],
			[
				654,
			],
			[
				655,
			],
		],
	],
	339: [
		[
			[
				656,
			],
			[
				657,
			],
			[
				658,
			],
		],
	],
	340: [
		[
			[
				659,
			],
			[
				660,
			],
		],
	],
	341: [
		[
			[
				661,
			],
			[
				662,
			],
			[
				663,
			],
		],
	],
	342: [
		[
			[
				664,
			],
			[
				665,
			],
			[
				666,
			],
		],
	],
	343: [
		[
			[
				667,
			],
			[
				668,
			],
		],
	],
	344: [
		[
			[
				669,
			],
			[
				670,
			],
			[
				671,
			],
		],
	],
	345: [
		[
			[
				672,
			],
			[
				673,
			],
		],
	],
	346: [
		[
			[
				674,
			],
			[
				675,
			],
		],
	],
	347: [
		[
			[
				676,
			],
		],
	],
	348: [
		[
			[
				677,
			],
			[
				678,
			],
		],
	],
	349: [
		[
			[
				679,
			],
			[
				680,
			],
			[
				681,
			],
		],
	],
	350: [
		[
			[
				682,
			],
			[
				683,
			],
		],
	],
	351: [
		[
			[
				684,
			],
			[
				685,
			],
		],
	],
	352: [
		[
			[
				686,
			],
			[
				687,
			],
		],
	],
	353: [
		[
			[
				688,
			],
			[
				689,
			],
		],
	],
	354: [
		[
			[
				690,
			],
			[
				691,
			],
		],
	],
	355: [
		[
			[
				692,
			],
			[
				693,
			],
		],
	],
	356: [
		[
			[
				694,
			],
			[
				695,
			],
		],
	],
	357: [
		[
			[
				696,
			],
			[
				697,
			],
		],
	],
	358: [
		[
			[
				698,
			],
			[
				699,
			],
		],
	],
	359: [
		[
			[
				701,
			],
		],
	],
	360: [
		[
			[
				702,
			],
		],
	],
	361: [
		[
			[
				703,
			],
		],
	],
	362: [
		[
			[
				704,
			],
			[
				705,
			],
			[
				706,
			],
		],
	],
	363: [
		[
			[
				707,
			],
		],
	],
	364: [
		[
			[
				708,
			],
			[
				709,
			],
		],
	],
	365: [
		[
			[
				710,
			],
			[
				711,
			],
		],
	],
	366: [
		[
			[
				712,
			],
			[
				713,
			],
		],
	],
	367: [
		[
			[
				714,
			],
			[
				715,
			],
		],
	],
	368: [
		[
			[
				716,
			],
		],
	],
	369: [
		[
			[
				717,
			],
		],
	],
	370: [
		[
			[
				718,
			],
		],
	],
	371: [
		[
			[
				719,
			],
		],
	],
	372: [
		[
			[
				720,
			],
		],
		[
			[
				10188,
			],
		],
	],
	373: [
		[
			[
				721,
			],
		],
	],
	374: [
		[
			[
				722,
			],
			[
				723,
			],
			[
				724,
			],
		],
	],
	375: [
		[
			[
				725,
			],
			[
				726,
			],
			[
				727,
			],
		],
	],
	376: [
		[
			[
				728,
			],
			[
				729,
			],
			[
				730,
			],
		],
	],
	377: [
		[
			[
				731,
			],
			[
				732,
			],
			[
				733,
			],
		],
	],
	378: [
		[
			[
				734,
			],
			[
				735,
			],
		],
	],
	379: [
		[
			[
				736,
			],
			[
				737,
			],
			[
				738,
			],
		],
	],
	380: [
		[
			[
				739,
			],
			[
				740,
			],
		],
	],
	381: [
		[
			[
				741,
			],
		],
		[
			[
				10225,
			],
		],
		[
			[
				10226,
			],
		],
		[
			[
				10227,
			],
		],
	],
	382: [
		[
			[
				742,
			],
			[
				743,
			],
		],
	],
	383: [
		[
			[
				744,
			],
			[
				745,
			],
		],
	],
	384: [
		[
			[
				746,
			],
		],
	],
	385: [
		[
			[
				747,
			],
			[
				748,
			],
		],
	],
	386: [
		[
			[
				749,
			],
			[
				750,
			],
		],
	],
	387: [
		[
			[
				751,
			],
			[
				752,
			],
		],
	],
	388: [
		[
			[
				753,
			],
			[
				754,
			],
		],
	],
	389: [
		[
			[
				755,
			],
			[
				756,
			],
		],
	],
	390: [
		[
			[
				757,
			],
			[
				758,
			],
		],
	],
	391: [
		[
			[
				759,
			],
			[
				760,
			],
		],
	],
	392: [
		[
			[
				761,
			],
			[
				762,
			],
			[
				763,
			],
		],
	],
	393: [
		[
			[
				764,
			],
		],
	],
	394: [
		[
			[
				765,
			],
		],
	],
	395: [
		[
			[
				766,
			],
		],
	],
	396: [
		[
			[
				767,
			],
			[
				768,
			],
		],
	],
	397: [
		[
			[
				769,
			],
			[
				770,
			],
		],
	],
	398: [
		[
			[
				771,
			],
		],
	],
	399: [
		[
			[
				772,
			],
			[
				773,
			],
		],
	],
	400: [
		[
			[
				774,
			],
		],
	],
	401: [
		[
			[
				775,
			],
		],
	],
	402: [
		[
			[
				776,
			],
		],
	],
	403: [
		[
			[
				777,
			],
		],
	],
	404: [
		[
			[
				778,
			],
		],
	],
	405: [
		[
			[
				779,
			],
		],
	],
	406: [
		[
			[
				780,
			],
		],
	],
	407: [
		[
			[
				781,
			],
		],
	],
	408: [
		[
			[
				782,
			],
			[
				783,
			],
			[
				784,
			],
		],
	],
	409: [
		[
			[
				785,
			],
		],
	],
	410: [
		[
			[
				786,
			],
		],
	],
	411: [
		[
			[
				787,
			],
		],
	],
	412: [
		[
			[
				788,
			],
		],
	],
	413: [
		[
			[
				789,
			],
			[
				790,
			],
			[
				791,
				792,
			],
		],
	],
	414: [
		[
			[
				793,
			],
		],
	],
	415: [
		[
			[
				794,
			],
		],
	],
	416: [
		[
			[
				795,
			],
		],
	],
	417: [
		[
			[
				796,
			],
		],
	],
	418: [
		[
			[
				797,
			],
		],
	],
	419: [
		[
			[
				798,
			],
		],
	],
	420: [
		[
			[
				799,
			],
		],
	],
	421: [
		[
			[
				800,
			],
		],
		[
			[
				10314,
			],
		],
		[
			[
				10315,
			],
		],
		[
			[
				10316,
			],
		],
	],
	422: [
		[
			[
				801,
			],
		],
	],
	423: [
		[
			[
				802,
			],
		],
	],
	424: [
		[
			[
				803,
			],
			[
				804,
			],
		],
	],
	425: [
		[
			[
				805,
			],
		],
	],
	426: [
		[
			[
				806,
			],
		],
	],
	427: [
		[
			[
				807,
			],
		],
	],
	428: [
		[
			[
				808,
			],
			[
				809,
			],
		],
	],
	429: [
		[
			[
				810,
			],
			[
				811,
			],
			[
				812,
			],
		],
	],
	430: [
		[
			[
				813,
			],
			[
				814,
			],
			[
				815,
			],
		],
	],
	431: [
		[
			[
				816,
			],
			[
				817,
			],
			[
				818,
			],
		],
	],
	432: [
		[
			[
				819,
			],
			[
				820,
			],
		],
	],
	433: [
		[
			[
				821,
			],
			[
				822,
			],
			[
				823,
			],
		],
	],
	434: [
		[
			[
				824,
			],
			[
				825,
			],
			[
				826,
			],
		],
	],
	435: [
		[
			[
				827,
			],
			[
				828,
			],
		],
	],
	436: [
		[
			[
				829,
			],
			[
				830,
			],
		],
	],
	437: [
		[
			[
				831,
			],
			[
				832,
			],
		],
	],
	438: [
		[
			[
				833,
			],
			[
				834,
			],
		],
	],
	439: [
		[
			[
				835,
			],
			[
				836,
			],
		],
	],
	440: [
		[
			[
				837,
			],
			[
				838,
			],
			[
				839,
			],
		],
	],
	441: [
		[
			[
				840,
			],
			[
				841,
				842,
			],
		],
	],
	442: [
		[
			[
				843,
			],
			[
				844,
			],
		],
	],
	443: [
		[
			[
				845,
			],
		],
	],
	444: [
		[
			[
				846,
			],
			[
				847,
			],
		],
	],
	445: [
		[
			[
				848,
			],
			[
				849,
			],
		],
	],
	446: [
		[
			[
				850,
			],
			[
				851,
			],
		],
	],
	447: [
		[
			[
				852,
			],
			[
				853,
			],
		],
	],
	448: [
		[
			[
				854,
			],
			[
				855,
			],
		],
	],
	449: [
		[
			[
				856,
			],
			[
				857,
			],
			[
				858,
			],
		],
	],
	450: [
		[
			[
				859,
			],
			[
				860,
			],
			[
				861,
			],
		],
	],
	451: [
		[
			[
				868,
			],
			[
				869,
			],
		],
	],
	452: [
		[
			[
				870,
			],
		],
	],
	453: [
		[
			[
				871,
			],
		],
	],
	454: [
		[
			[
				872,
			],
			[
				873,
			],
		],
	],
	455: [
		[
			[
				874,
			],
		],
	],
	456: [
		[
			[
				875,
			],
		],
	],
	457: [
		[
			[
				876,
			],
		],
	],
	458: [
		[
			[
				877,
			],
		],
	],
	459: [
		[
			[
				878,
			],
			[
				879,
			],
		],
	],
	460: [
		[
			[
				880,
			],
		],
	],
	461: [
		[
			[
				881,
			],
		],
	],
	462: [
		[
			[
				882,
			],
		],
	],
	463: [
		[
			[
				883,
			],
		],
	],
	464: [
		[
			[
				884,
			],
		],
	],
	465: [
		[
			[
				885,
			],
			[
				886,
			],
			[
				887,
			],
		],
	],
	466: [
		[
			[
				888,
			],
		],
		[
			[
				10407,
			],
		],
	],
	467: [
		[
			[
				889,
			],
		],
		[
			[
				10408,
			],
		],
	],
	468: [
		[
			[
				890,
			],
		],
	],
	469: [
		[
			[
				891,
			],
			[
				892,
				10409,
			],
		],
	],
	470: [
		[
			[
				893,
			],
		],
	],
	471: [
		[
			[
				894,
			],
		],
	],
	472: [
		[
			[
				895,
			],
		],
	],
	473: [
		[
			[
				896,
			],
		],
	],
	474: [
		[
			[
				897,
			],
		],
	],
	475: [
		[
			[
				898,
			],
		],
		[
			[
				10411,
			],
		],
		[
			[
				10412,
			],
		],
	],
};
