import { PokemonForm } from 'poke-data';

export function PokemonImage({
	className,
	onClick,
	pokemon,
}: {
	className?: string,
	onClick?: (x: PokemonForm) => void,
	pokemon: PokemonForm,
}): JSX.Element {
	return <img
		className={ className ? className : undefined }
		alt={ pokemon.name }
		src={ require(`pokemon-sprites/sprites/pokemon/${pokemon.slug}.png`).default }
		style={{
			imageRendering: 'pixelated',
		}}
		role={ onClick ? 'button' : undefined }
		onClick={ onClick ? () => onClick(pokemon) : undefined }
	/>;
}
