import { useMemo } from 'react';
import {
	invertBy,
	map,
	orderBy,
	toPairs,
} from 'lodash';
import {
	TypeId,
	TypeIds,
	typeEfficacy,
} from 'poke-data';
import { ElementImage } from './ElementImage';
import { SectionProps } from './SectionWrapper';

export function TypeEffectiveness({
	pokemon,
}: SectionProps): JSX.Element {
	function formatEfficiency(effAsString: string) {
		const [ integer, fractional ] = effAsString.split('.').map(Number);
		return <span
			className={ 'w-9 mt-1 mr-1 text-right ' + getEfficiencyColor(Number(effAsString)) }
		>
			{ formatEfficiencyText(integer, fractional) }
		</span>;
	}

	function formatEfficiencyText(i: number, f: number): string {
		if (i === 0 && !f) {
			return '0';
		}
		if (i > 0) {
			return 'x' + i + toVulgarForm(f);
		}
		return toVulgarForm(f);
	}

	function toVulgarForm(fractional: number): string {
		if (fractional === 25) {
			return '¼';
		}
		if (fractional === 5) {
			return '½';
		}
		return '';
	}

	function getEfficiencyColor(eff: number): string {
		if (eff > 1) {
			return 'text-efficiency-weak';
		}
		if (eff === 0) {
			return 'text-efficiency-immune';
		}
		return 'text-efficiency-resistant';
	}

	const typeRows = useMemo(() => map(orderBy(toPairs(invertBy(map(
		TypeIds,
		(typeId) => typeEfficacy(typeId, ...pokemon.type_ids)),
	)), 0, 'desc'), ([ eff, typeIds ]) => {
		if (eff === '1') {
			return null;
		}
		const typeImages = typeIds.map(typeId => <ElementImage key={ typeId } typeId={ Number(typeId) as TypeId } />);
		const efficiency = formatEfficiency(eff);
		return (
			<div
				className="grid grid-flow-col gap-1 items-center justify-start"
				key={ eff }
			>
				{ efficiency }
				{ typeImages }
			</div>
		);
	}), [ orderBy(pokemon.type_ids).join() ]);

	return <div
		className="grid grid-flow-row justify-center w-full py-2 bg-content text-sm"
	>
		{ typeRows }
	</div>;
}
