import { map } from 'lodash';
import { AlternateForms, PokemonForms, StatusColors } from 'poke-data';
import { ElementImage } from './ElementImage';
import { PokemonImage } from './PokemonImage';
import { SectionProps } from './SectionWrapper';

export function Forms({
	pokemon,
	setPokemon,
}: SectionProps): JSX.Element {
	return <div
		className="w-full py-2 bg-content text-sm"
	>
		<div
			className="flex flex-row flex-wrap w-96 gap-4 mx-auto justify-center justify-items-center items-end"
		>
			{ map(AlternateForms[pokemon.evolution_id], formId => {
				const form = PokemonForms[formId];
				return <div
					key={ form.id }
					className={ 'w-[184px] text-center ' + (pokemon.id === form.id ? 'opacity-100' : 'opacity-50' ) }
					onClick={ () => setPokemon(form) }
					role="button"
				>
					<div
						className="flex m-auto w-32 h-32"
					>
						<PokemonImage
							className="m-auto h-auto"
							pokemon={ form }
						/>
					</div>
					<div
						className="flex justify-center items-center content-center"
					>
						<div
							className={ 'overflow-x-hidden whitespace-nowrap overflow-ellipsis border-b ' + StatusColors[form.status] }
						>
							{ form.name }
						</div>
						{ form.type_ids.map(typeId =>
							<ElementImage
								className="w-4 h-4"
								typeId={ typeId }
								key={ typeId }
							/>,
						) }
					</div>
				</div>;
			}) }
		</div>
	</div>;
}
