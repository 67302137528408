const Statuses = <const>[
	'Normal',
	'Sub Legendary',
	'Legendary',
	'Mythical',
];

export type Status = typeof Statuses[number];

export const StatusColors: Record<Status, string> = <const>{
	'Normal': 'border-transparent',
	'Sub Legendary': 'border-legendary-sub',
	'Legendary': 'border-legendary',
	'Mythical': 'border-legendary-mythical',
};
