import { useState } from 'react';
import { AllSections, Section, SectionAction, SortableSections } from 'src/data/settings';
import { SectionSetting } from './SectionSetting';
import { difference, intersection, map } from 'lodash';

export function Settings({
	sections,
	setSection,
}: {
	sections: Section[],
	setSection: React.Dispatch<SectionAction>
}): JSX.Element {
	const [ open, setOpen ] = useState<boolean>(false);
	return <>
		<div
			className="fixed top-2 right-0 w-7 h-7 text-bw text-center leading-7 select-none"
			onClick={ () => setOpen(prev => !prev) }
			role="button"
		>
			{ open ? 'x' : '*' }
		</div>
		{ open && <>
			<div
				className="w-96"
			>
				<h1
					className="h-7 leading-7"
				>
					Settings
				</h1>
				{ map(difference(AllSections, SortableSections), sectionId => <SectionSetting
					key={ sectionId }
					id={ sectionId }
					enabled={ sections.includes(sectionId) }
					setSection={ setSection }
				/>) }
				<hr
					className="my-2"
				/>
				{ map(intersection(sections, SortableSections), sectionId => <SectionSetting
					key={ sectionId }
					id={ sectionId }
					enabled={ true }
					setSection={ setSection }
				/>) }
				{ map(difference(SortableSections, sections), sectionId => <SectionSetting
					key={ sectionId }
					id={ sectionId }
					enabled={ false }
					setSection={ setSection }
				/>) }
				<hr
					className="my-2"
				/>
			</div>
		</> }
	</>;
}
