import { PokemonForm } from 'poke-data';

export interface SectionProps {
	pokemon: PokemonForm,
	setPokemon: (p: PokemonForm) => void,
}

export function SectionWrapper({
	pokemon,
	setPokemon,
	Wrapped,
}: {
	Wrapped: (p: SectionProps) => JSX.Element,
} & SectionProps): JSX.Element {
	return <div
		className="w-full py-2 bg-content text-sm"
	>
		<Wrapped
			pokemon={ pokemon }
			setPokemon={ setPokemon }
		/>
	</div>;
}

