import { map } from 'lodash';
import { Fragment } from 'react';
import { EvolutionChains, PokemonForms } from 'poke-data';
import { PokemonImage } from './PokemonImage';
import { SectionProps } from './SectionWrapper';

export function Evolutions({
	pokemon,
	setPokemon,
}: SectionProps): JSX.Element {
	return <div
		className="grid grid-flow-row gap-2 justify-center w-full py-2 bg-content text-sm"
	>
		{ map(EvolutionChains[pokemon.evolution_id], ( root, i ) => {
			return <div
				className="grid grid-flow-col gap-2 items-center justify-start"
				key={ pokemon.evolution_id + '_' + i }
			>
				{ map(root, ( level, j ) => <Fragment
					key={ pokemon.evolution_id + '_' + j }
				>
					{ j !== 0 &&
						<div
							className="text-lg"
						>
							{'\u21E8'}
						</div>}
					<div
						className="grid grid-flow-row gap-2 items-center justify-items-center"
					>
						{ map(level, formId => {
							const form = PokemonForms[formId];
							return <PokemonImage
								className={ pokemon.id === form.id ? 'opacity-100' : 'opacity-50' }
								key={ form.id }
								onClick={ setPokemon }
								pokemon={ form }
							/>;
						}) }
					</div>
				</Fragment>,
				) }
			</div>;
		}) }
	</div>;
}
