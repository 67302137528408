import { SpeciesId } from '.';

type DescriptionsBySpeciesId = {
	readonly [Property in SpeciesId]: {
		readonly 'species_id': Property,
		readonly 'description': string,
	}
}

export const Descriptions: DescriptionsBySpeciesId = <const>{
	1: {
		'description': 'While it is young, it uses the nutrients that are stored in the seed on its back in order to grow.',
		'species_id': 1,
	},
	2: {
		'description': 'Exposure to sunlight adds to its strength. Sunlight also makes the bud on its back grow larger.',
		'species_id': 2,
	},
	3: {
		'description': 'A bewitching aroma wafts from its flower. The fragrance becalms those engaged in a battle.',
		'species_id': 3,
	},
	4: {
		'description': 'From the time it is born, a flame burns at the tip of its tail. Its life would end if the flame were to go out.',
		'species_id': 4,
	},
	5: {
		'description': 'If it becomes agitated during battle, it spouts intense flames, incinerating its surroundings.',
		'species_id': 5,
	},
	6: {
		'description': 'Its wings can carry this Pokémon close to an altitude of 4,600 feet. It blows out fire at very high temperatures.',
		'species_id': 6,
	},
	7: {
		'description': 'When it feels threatened, it draws its limbs inside its shell and sprays water from its mouth.',
		'species_id': 7,
	},
	8: {
		'description': 'It cleverly controls its furry ears and tail to maintain its balance while swimming.',
		'species_id': 8,
	},
	9: {
		'description': 'The rocket cannons on its shell fire jets of water capable of punching holes through thick steel.',
		'species_id': 9,
	},
	10: {
		'description': 'Its short feet are tipped with suction pads that enable it to tirelessly climb slopes and walls.',
		'species_id': 10,
	},
	11: {
		'description': 'Even though it is encased in a sturdy shell, the body inside is tender. It can’t withstand a harsh attack.',
		'species_id': 11,
	},
	12: {
		'description': 'It collects honey every day. It rubs honey onto the hairs on its legs to carry it back to its nest.',
		'species_id': 12,
	},
	13: {
		'description': 'Beware of the sharp stinger on its head. It hides in grass and bushes where it eats leaves.',
		'species_id': 13,
	},
	14: {
		'description': 'Able to move only slightly. When endangered, it may stick out its stinger and poison its enemy.',
		'species_id': 14,
	},
	15: {
		'description': 'It has three poisonous stingers on its forelegs and its tail. They are used to jab its enemy repeatedly.',
		'species_id': 15,
	},
	16: {
		'description': 'Very docile. If attacked, it will often kick up sand to protect itself rather than fight back.',
		'species_id': 16,
	},
	17: {
		'description': 'This Pokémon is full of vitality. It constantly flies around its large territory in search of prey.',
		'species_id': 17,
	},
	18: {
		'description': 'This Pokémon flies at Mach 2 speed, seeking prey. Its large talons are feared as wicked weapons.',
		'species_id': 18,
	},
	19: {
		'description': 'Its whiskers provide it with a keen sense of smell, enabling it to pick up the scent of hidden food and locate it instantly.',
		'species_id': 19,
	},
	20: {
		'description': 'It makes its Rattata underlings gather food for it, dining solely on the most nutritious and delicious fare.',
		'species_id': 20,
	},
	21: {
		'description': 'Inept at flying high. However, it can fly around very fast to protect its territory.',
		'species_id': 21,
	},
	22: {
		'description': 'A Pokémon that dates back many years. If it senses danger, it flies high and away, instantly.',
		'species_id': 22,
	},
	23: {
		'description': 'The older it gets, the longer it grows. At night, it wraps its long body around tree branches to rest.',
		'species_id': 23,
	},
	24: {
		'description': 'The frightening patterns on its belly have been studied. Six variations have been confirmed.',
		'species_id': 24,
	},
	25: {
		'description': 'This Pikachu is wearing its Trainer’s cap. The cap is a precious symbol of a fateful encounter.',
		'species_id': 25,
	},
	26: {
		'description': 'If its electric pouches run empty, it raises its tail to gather electricity from the atmosphere.',
		'species_id': 26,
	},
	27: {
		'description': 'It burrows into the ground to create its nest. If hard stones impede its tunneling, it uses its sharp claws to shatter them and then carries on digging.',
		'species_id': 27,
	},
	28: {
		'description': 'It climbs trees by hooking on with its sharp claws. Sandslash shares the berries it gathers, dropping them down to Sandshrew waiting below the tree.',
		'species_id': 28,
	},
	29: {
		'description': 'A mild-mannered Pokémon that does not like to fight. Beware—its small horn secretes venom.',
		'species_id': 29,
	},
	30: {
		'description': 'When resting deep in its burrow, its barbs always retract. This is proof that it is relaxed.',
		'species_id': 30,
	},
	31: {
		'description': 'Tough scales cover the sturdy body of this Pokémon. It appears that the scales grow in cycles.',
		'species_id': 31,
	},
	32: {
		'description': 'Its large ears are always kept upright. If it senses danger, it will attack with a poisonous sting.',
		'species_id': 32,
	},
	33: {
		'description': 'Its horn contains venom. If it stabs an enemy with the horn, the impact makes the poison leak out.',
		'species_id': 33,
	},
	34: {
		'description': 'Its steel-like hide adds to its powerful tackle. Its horns are so hard, they can pierce a diamond.',
		'species_id': 34,
	},
	35: {
		'description': 'Its adorable behavior and cry make it highly popular. However, this cute Pokémon is rarely found.',
		'species_id': 35,
	},
	36: {
		'description': 'Their ears are sensitive enough to hear a pin drop from over a mile away, so they’re usually found in quiet places.',
		'species_id': 36,
	},
	37: {
		'description': 'As each tail grows, its fur becomes more lustrous. When held, it feels slightly warm.',
		'species_id': 37,
	},
	38: {
		'description': 'Very smart and very vengeful. Grabbing one of its many tails could result in a 1,000-year curse.',
		'species_id': 38,
	},
	39: {
		'description': 'By freely changing the wavelength of its voice, Jigglypuff sings a mysterious melody sure to make any listener sleepy.',
		'species_id': 39,
	},
	40: {
		'description': 'It’s proud of its fur, which is fine and delicate. In particular, the curl on its forehead has a texture that’s perfectly heavenly.',
		'species_id': 40,
	},
	41: {
		'description': 'Emits ultrasonic cries while it flies. They act as a sonar used to check for objects in its way.',
		'species_id': 41,
	},
	42: {
		'description': 'It attacks in a stealthy manner, without warning. Its sharp fangs are used to bite and to suck blood.',
		'species_id': 42,
	},
	43: {
		'description': 'During the day, it stays in the cold underground to avoid the sun. It grows by bathing in moonlight.',
		'species_id': 43,
	},
	44: {
		'description': 'What appears to be drool is actually sweet honey. It is very sticky and clings stubbornly if touched.',
		'species_id': 44,
	},
	45: {
		'description': 'The larger its petals, the more toxic pollen it contains. Its big head is heavy and hard to hold up.',
		'species_id': 45,
	},
	46: {
		'description': 'Burrows under the ground to gnaw on tree roots. The mushrooms on its back absorb most of the nutrition.',
		'species_id': 46,
	},
	47: {
		'description': 'The bug host is drained of energy by the mushroom on its back. The mushroom appears to do all the thinking.',
		'species_id': 47,
	},
	48: {
		'description': 'Its large eyes act as radar. In a bright place, you can see that they are clusters of many tiny eyes.',
		'species_id': 48,
	},
	49: {
		'description': 'The powdery scales on its wings are hard to remove from skin. They also contain poison that leaks out on contact.',
		'species_id': 49,
	},
	50: {
		'description': 'It burrows through the ground at a shallow depth. It leaves raised earth in its wake, making it easy to spot.',
		'species_id': 50,
	},
	51: {
		'description': 'The three of them get along very well. Through their formidable teamwork, they defeat powerful opponents.',
		'species_id': 51,
	},
	52: {
		'description': 'Deeply proud and keenly smart, this Pokémon moves with cunning during battle and relentlessly attacks enemies’ weak points.',
		'species_id': 52,
	},
	53: {
		'description': 'Its elegant and refined behavior clashes with that of the barbaric Perrserker. The relationship between the two is one of mutual disdain.',
		'species_id': 53,
	},
	54: {
		'description': 'As Psyduck gets stressed out, its headache gets progressively worse. It uses intense psychic energy to overwhelm those around it.',
		'species_id': 54,
	},
	55: {
		'description': 'Old tales tell of Golduck punishing those that defiled its river. The guilty were dragged into the water and taken away.',
		'species_id': 55,
	},
	56: {
		'description': 'An agile Pokémon that lives in trees. It angers easily and will not hesitate to attack anything.',
		'species_id': 56,
	},
	57: {
		'description': 'It stops being angry only when nobody else is around. To view this moment is very difficult.',
		'species_id': 57,
	},
	58: {
		'description': 'Extremely loyal, it will fearlessly bark at any opponent to protect its own Trainer from harm.',
		'species_id': 58,
	},
	59: {
		'description': 'A Pokémon that has long been admired for its beauty. It runs agilely as if on wings.',
		'species_id': 59,
	},
	60: {
		'description': 'In rivers with fast-flowing water, this Pokémon will cling to a rock by using its thick lips, which act like a suction cup.',
		'species_id': 60,
	},
	61: {
		'description': 'This Pokémon’s sweat is a slimy mucus. When captured, Poliwhirl can slither from its enemies’ grasp and escape.',
		'species_id': 61,
	},
	62: {
		'description': 'Poliwrath is skilled at both swimming and martial arts. It uses its well-trained arms to dish out powerful punches.',
		'species_id': 62,
	},
	63: {
		'description': 'Abra can teleport in its sleep. Apparently the more deeply Abra sleeps, the farther its teleportations go.',
		'species_id': 63,
	},
	64: {
		'description': 'This Pokémon’s telekinesis is immensely powerful. To prepare for evolution, Kadabra stores up psychic energy in the star on its forehead.',
		'species_id': 64,
	},
	65: {
		'description': 'Alakazam wields potent psychic powers. It’s said that this Pokémon used these powers to create the spoons it holds.',
		'species_id': 65,
	},
	66: {
		'description': 'Always brimming with power, it passes time by lifting boulders. Doing so makes it even stronger.',
		'species_id': 66,
	},
	67: {
		'description': 'Its formidable body never gets tired. It helps people by doing work such as the moving of heavy goods.',
		'species_id': 67,
	},
	68: {
		'description': 'One of these Pokémon once used its immeasurable strength to lift a large ship that was in trouble. It then carried the ship to port.',
		'species_id': 68,
	},
	69: {
		'description': 'Prefers hot and humid places. It ensnares tiny bugs with its vines and devours them.',
		'species_id': 69,
	},
	70: {
		'description': 'When hungry, it swallows anything that moves. Its hapless prey is dissolved by strong acids.',
		'species_id': 70,
	},
	71: {
		'description': 'Lures prey with the sweet aroma of honey. Swallowed whole, the prey is dissolved in a day, bones and all.',
		'species_id': 71,
	},
	72: {
		'description': 'This Pokémon is mostly made of water. A Tentacool out in the ocean is very hard to spot, because its body blends in with the sea.',
		'species_id': 72,
	},
	73: {
		'description': 'Its 80 tentacles can stretch and shrink freely. Tentacruel ensnares prey in a net of spread-out tentacles, delivering venomous stings to its catch.',
		'species_id': 73,
	},
	74: {
		'description': 'Commonly found near mountain trails and the like. If you step on one by accident, it gets angry.',
		'species_id': 74,
	},
	75: {
		'description': 'Often seen rolling down mountain trails. Obstacles are just things to roll straight over, not avoid.',
		'species_id': 75,
	},
	76: {
		'description': 'It uses magnetism to accelerate and fire off rocks tinged with electricity. Even if it doesn’t score a direct hit, the jolt of electricity will do the job.',
		'species_id': 76,
	},
	77: {
		'description': 'If you’ve been accepted by Ponyta, its burning mane is mysteriously no longer hot to the touch.',
		'species_id': 77,
	},
	78: {
		'description': 'Brave and prideful, this Pokémon dashes airily through the forest, its steps aided by the psychic power stored in the fur on its fetlocks.',
		'species_id': 78,
	},
	79: {
		'description': 'Because Galarian Slowpoke eat the seeds of a plant that grows only in Galar, their tails have developed a spicy flavor.',
		'species_id': 79,
	},
	80: {
		'description': 'Being bitten by a Shellder shocked this Pokémon into standing on two legs. If the Shellder lets go, it seems Slowbro will turn back into a Slowpoke.',
		'species_id': 80,
	},
	81: {
		'description': 'It subsists on electricity. As Magnemite flies, it emits electromagnetic waves from the units on each side of its body.',
		'species_id': 81,
	},
	82: {
		'description': 'This Pokémon is constantly putting out a powerful magnetic force. Most computers go haywire when a Magneton approaches.',
		'species_id': 82,
	},
	83: {
		'description': 'The stalks of leeks are thicker and longer in the Galar region. Farfetch’d that adapted to these stalks took on a unique form.',
		'species_id': 83,
	},
	84: {
		'description': 'Its short wings make flying difficult. Instead, this Pokémon runs at high speed on developed legs.',
		'species_id': 84,
	},
	85: {
		'description': 'One of Doduo’s two heads splits to form a unique species. It runs close to 40 mph in prairies.',
		'species_id': 85,
	},
	86: {
		'description': 'Loves freezing-cold conditions. Relishes swimming in a frigid climate of around 14 degrees Fahrenheit.',
		'species_id': 86,
	},
	87: {
		'description': 'Its entire body is a snowy white. Unharmed by even intense cold, it swims powerfully in icy waters.',
		'species_id': 87,
	},
	88: {
		'description': 'It has a passion for trash above all else, speedily digesting it and creating brilliant crystals of sparkling poison.',
		'species_id': 88,
	},
	89: {
		'description': 'Muk’s coloration becomes increasingly vivid the more it feasts on its favorite dish—trash.',
		'species_id': 89,
	},
	90: {
		'description': 'Its hard shell repels any kind of attack. It is vulnerable only when its shell is open.',
		'species_id': 90,
	},
	91: {
		'description': 'Once it slams its shell shut, it is impossible to open, even by those with superior strength.',
		'species_id': 91,
	},
	92: {
		'description': 'With its gas-like body, it can sneak into any place it desires. However, it can be blown away by wind.',
		'species_id': 92,
	},
	93: {
		'description': 'If you get the feeling of being watched in darkness when nobody is around, Haunter is there.',
		'species_id': 93,
	},
	94: {
		'description': 'It is said to emerge from darkness to steal the lives of those who become lost in mountains.',
		'species_id': 94,
	},
	95: {
		'description': 'It rapidly bores through the ground at 50 mph by squirming and twisting its massive, rugged body.',
		'species_id': 95,
	},
	96: {
		'description': 'If you sleep by it all the time, it will sometimes show you dreams it had eaten in the past.',
		'species_id': 96,
	},
	97: {
		'description': 'Avoid eye contact if you come across one. It will try to put you to sleep by using its pendulum.',
		'species_id': 97,
	},
	98: {
		'description': 'If it senses danger approaching, it cloaks itself with bubbles from its mouth so it will look bigger.',
		'species_id': 98,
	},
	99: {
		'description': 'Its oversized claw is very powerful, but when it’s not in battle, the claw just gets in the way.',
		'species_id': 99,
	},
	100: {
		'description': 'It is said to camouflage itself as a Poké Ball. It will self-destruct with very little stimulus.',
		'species_id': 100,
	},
	101: {
		'description': 'Stores electrical energy inside its body. Even the slightest shock could trigger a huge explosion.',
		'species_id': 101,
	},
	102: {
		'description': 'These Pokémon get nervous when they’re not in a group of six. The minute even one member of the group goes missing, Exeggcute become cowardly.',
		'species_id': 102,
	},
	103: {
		'description': 'This Pokémon’s psychic powers aren’t as strong as they once were. The head on this Exeggutor’s tail scans surrounding areas with weak telepathy.',
		'species_id': 103,
	},
	104: {
		'description': 'This Pokémon wears the skull of its deceased mother. Sometimes Cubone’s dreams make it cry, but each tear Cubone sheds makes it stronger.',
		'species_id': 104,
	},
	105: {
		'description': 'The cursed flames that light up the bone carried by this Pokémon are said to cause both mental and physical pain that will never fade.',
		'species_id': 105,
	},
	106: {
		'description': 'The legs freely contract and stretch. The stretchy legs allow it to hit a distant foe with a rising kick.',
		'species_id': 106,
	},
	107: {
		'description': 'Its punches slice the air. However, it seems to need a short break after fighting for three minutes.',
		'species_id': 107,
	},
	108: {
		'description': 'Bug Pokémon are Lickitung’s main food source. This Pokémon paralyzes its prey with a lick from its long tongue, then swallows the prey whole.',
		'species_id': 108,
	},
	109: {
		'description': 'It adores polluted air. Some claim that Koffing used to be more plentiful in the Galar region than they are now.',
		'species_id': 109,
	},
	110: {
		'description': 'It can’t suck in air quite as well as a Galarian Weezing, but the toxins it creates are more potent than those of its counterpart.',
		'species_id': 110,
	},
	111: {
		'description': 'It can remember only one thing at a time. Once it starts rushing, it forgets why it started.',
		'species_id': 111,
	},
	112: {
		'description': 'Protected by an armor-like hide, it is capable of living in molten lava of 3,600 degrees Fahrenheit.',
		'species_id': 112,
	},
	113: {
		'description': 'This species was once very slow. To protect their eggs from other creatures, these Pokémon became able to flee quickly.',
		'species_id': 113,
	},
	114: {
		'description': 'The vines of a Tangela have a distinct scent. In some parts of Galar, Tangela vines are used as herbs.',
		'species_id': 114,
	},
	115: {
		'description': 'There are records of a lost human child being raised by a childless Kangaskhan.',
		'species_id': 115,
	},
	116: {
		'description': 'They swim with dance-like motions and cause whirlpools to form. Horsea compete to see which of them can generate the biggest whirlpool.',
		'species_id': 116,
	},
	117: {
		'description': 'Seadra’s mouth is slender, but its suction power is strong. In an instant, Seadra can suck in food that’s larger than the opening of its mouth.',
		'species_id': 117,
	},
	118: {
		'description': 'Its dorsal and pectoral fins are strongly developed like muscles. It can swim at a speed of five knots.',
		'species_id': 118,
	},
	119: {
		'description': 'Using its horn, it bores holes in riverbed boulders, making nests to prevent its eggs from washing away.',
		'species_id': 119,
	},
	120: {
		'description': 'Fish Pokémon nibble at it, but Staryu isn’t bothered. Its body regenerates quickly, even if part of it is completely torn off.',
		'species_id': 120,
	},
	121: {
		'description': 'Starmie swims by spinning its body at high speed. As this Pokémon cruises through the ocean, it absorbs tiny plankton.',
		'species_id': 121,
	},
	122: {
		'description': 'It can radiate chilliness from the bottoms of its feet. It’ll spend the whole day tap-dancing on a frozen floor.',
		'species_id': 122,
	},
	123: {
		'description': 'If you come across an area in a forest where a lot of the trees have been cut down, what you’ve found is a Scyther’s territory.',
		'species_id': 123,
	},
	124: {
		'description': 'Appears to move to a rhythm of its own, as if it were dancing. It wiggles its hips as it walks.',
		'species_id': 124,
	},
	125: {
		'description': 'If a major power outage occurs, it is certain that this Pokémon has eaten electricity at a power plant.',
		'species_id': 125,
	},
	126: {
		'description': 'Born in an active volcano. Its body is always cloaked in flames, so it looks like a big ball of fire.',
		'species_id': 126,
	},
	127: {
		'description': 'This Pokémon clamps its pincers down on its prey and then either splits the prey in half or flings it away.',
		'species_id': 127,
	},
	128: {
		'description': 'The Tauros of the Galar region are volatile in nature, and they won’t allow people to ride on their backs.',
		'species_id': 128,
	},
	129: {
		'description': 'This weak and pathetic Pokémon gets easily pushed along rivers when there are strong currents.',
		'species_id': 129,
	},
	130: {
		'description': 'Once it begins to rampage, a Gyarados will burn everything down, even in a harsh storm.',
		'species_id': 130,
	},
	131: {
		'description': 'Crossing icy seas is no issue for this cold-resistant Pokémon. Its smooth skin is a little cool to the touch.',
		'species_id': 131,
	},
	132: {
		'description': 'When it encounters another Ditto, it will move faster than normal to duplicate that opponent exactly.',
		'species_id': 132,
	},
	133: {
		'description': 'Having gotten even friendlier and more innocent, Eevee tries to play with anyone around, only to end up crushing them with its immense body.',
		'species_id': 133,
	},
	134: {
		'description': 'Its body’s cellular structure is similar to the molecular composition of water. It can melt invisibly in water.',
		'species_id': 134,
	},
	135: {
		'description': 'It accumulates negative ions in the atmosphere to blast out 10,000-volt lightning bolts.',
		'species_id': 135,
	},
	136: {
		'description': 'It stores some of the air it inhales in its internal flame pouch, which heats it to over 3,000 degrees Fahrenheit.',
		'species_id': 136,
	},
	137: {
		'description': 'In recent years, this species has been very helpful in cyberspace. These Pokémon will go around checking to make sure no suspicious data exists.',
		'species_id': 137,
	},
	138: {
		'description': 'An ancient Pokémon that was recovered from a fossil. It swam by cleverly twisting its 10 tentacles about.',
		'species_id': 138,
	},
	139: {
		'description': 'Its sharp beak rings its mouth. Its shell was too big for it to move freely, so it became extinct.',
		'species_id': 139,
	},
	140: {
		'description': 'A Pokémon that was recovered from a fossil. It used the eyes on its back while hiding on the seafloor.',
		'species_id': 140,
	},
	141: {
		'description': 'A slim and fast swimmer. It sliced its prey with its sharp sickles and drank the body fluids.',
		'species_id': 141,
	},
	142: {
		'description': 'A savage Pokémon that died out in ancient times. It was resurrected using DNA taken from amber.',
		'species_id': 142,
	},
	143: {
		'description': 'Terrifyingly strong, this Pokémon is the size of a mountain—and moves about as much as one as well.',
		'species_id': 143,
	},
	144: {
		'description': 'A legendary bird Pokémon. It freezes water that is contained in winter air and makes it snow.',
		'species_id': 144,
	},
	145: {
		'description': 'This legendary bird Pokémon is said to appear when the sky turns dark and lightning showers down.',
		'species_id': 145,
	},
	146: {
		'description': 'A legendary bird Pokémon. As it flaps its flaming wings, even the night sky will turn red.',
		'species_id': 146,
	},
	147: {
		'description': 'Long thought to be a myth, this Pokémon’s existence was only recently confirmed by a fisherman who caught one.',
		'species_id': 147,
	},
	148: {
		'description': 'According to a witness, its body was surrounded by a strange aura that gave it a mystical look.',
		'species_id': 148,
	},
	149: {
		'description': 'It is said that this Pokémon lives somewhere in the sea and that it flies. However, these are only rumors.',
		'species_id': 149,
	},
	150: {
		'description': 'Despite its diminished size, its mental power has grown phenomenally. With a mere thought, it can smash a skyscraper to smithereens.',
		'species_id': 150,
	},
	151: {
		'description': 'When viewed through a microscope, this Pokémon’s short, fine, delicate hair can be seen.',
		'species_id': 151,
	},
	152: {
		'description': 'In battle, Chikorita waves its leaf around to keep the foe at bay. However, a sweet fragrance also wafts from the leaf, becalming the battling Pokémon and creating a cozy, friendly atmosphere all around.',
		'species_id': 152,
	},
	153: {
		'description': 'Bayleef’s neck is ringed by curled-up leaves. Inside each tubular leaf is a small shoot of a tree. The fragrance of this shoot makes people peppy.',
		'species_id': 153,
	},
	154: {
		'description': 'The fragrance of Meganium’s flower soothes and calms emotions. In battle, this Pokémon gives off more of its becalming scent to blunt the foe’s fighting spirit.',
		'species_id': 154,
	},
	155: {
		'description': 'Cyndaquil protects itself by flaring up the flames on its back. The flames are vigorous if the Pokémon is angry. However, if it is tired, the flames splutter fitfully with incomplete combustion.',
		'species_id': 155,
	},
	156: {
		'description': 'Quilava keeps its foes at bay with the intensity of its flames and gusts of superheated air. This Pokémon applies its outstanding nimbleness to dodge attacks even while scorching the foe with flames.',
		'species_id': 156,
	},
	157: {
		'description': 'Typhlosion obscures itself behind a shimmering heat haze that it creates using its intensely hot flames. This Pokémon creates blazing explosive blasts that burn everything to cinders.',
		'species_id': 157,
	},
	158: {
		'description': 'Despite the smallness of its body, Totodile’s jaws are very powerful. While the Pokémon may think it is just playfully nipping, its bite has enough power to cause serious injury.',
		'species_id': 158,
	},
	159: {
		'description': 'Once Croconaw has clamped its jaws on its foe, it will absolutely not let go. Because the tips of its fangs are forked back like barbed fishhooks, they become impossible to remove when they have sunk in.',
		'species_id': 159,
	},
	160: {
		'description': 'Feraligatr intimidates its foes by opening its huge mouth. In battle, it will kick the ground hard with its thick and powerful hind legs to charge at the foe at an incredible speed.',
		'species_id': 160,
	},
	161: {
		'description': 'When Sentret sleeps, it does so while another stands guard. The sentry wakes the others at the first sign of danger. When this Pokémon becomes separated from its pack, it becomes incapable of sleep due to fear.',
		'species_id': 161,
	},
	162: {
		'description': 'Furret has a very slim build. When under attack, it can slickly squirm through narrow spaces and get away. In spite of its short limbs, this Pokémon is very nimble and fleet.',
		'species_id': 162,
	},
	163: {
		'description': 'It begins to hoot at the same time every day. Some Trainers use them in place of clocks.',
		'species_id': 163,
	},
	164: {
		'description': 'When it needs to think, it rotates its head 180 degrees to sharpen its intellectual power.',
		'species_id': 164,
	},
	165: {
		'description': 'These very cowardly Pokémon join together and use Reflect to protect their nest.',
		'species_id': 165,
	},
	166: {
		'description': 'It flies through the night sky, sprinkling sparkly dust. According to some, if that dust sticks to you, good things will happen to you.',
		'species_id': 166,
	},
	167: {
		'description': 'Although the poison from its fangs isn’t that strong, it’s potent enough to weaken prey that gets caught in its web.',
		'species_id': 167,
	},
	168: {
		'description': 'It spews threads from its mouth to catch its prey. When night falls, it leaves its web to go hunt aggressively.',
		'species_id': 168,
	},
	169: {
		'description': 'It feeds on the blood of living people and Pokémon. If it can’t drink any blood for even a short while, it becomes weak and unable to fly.',
		'species_id': 169,
	},
	170: {
		'description': 'On the dark ocean floor, its only means of communication is its constantly flashing lights.',
		'species_id': 170,
	},
	171: {
		'description': 'This Pokémon flashes a bright light that blinds its prey. This creates an opening for it to deliver an electrical attack.',
		'species_id': 171,
	},
	172: {
		'description': 'The electric sacs in its cheeks are small. If even a little electricity leaks, it becomes shocked.',
		'species_id': 172,
	},
	173: {
		'description': 'Because of its unusual, starlike silhouette, people believe that it came here on a meteor.',
		'species_id': 173,
	},
	174: {
		'description': 'Taking advantage of the softness of its body, Igglybuff moves as if bouncing. Its body turns a deep pink when its temperature rises.',
		'species_id': 174,
	},
	175: {
		'description': 'It is considered to be a symbol of good luck. Its shell is said to be filled with happiness.',
		'species_id': 175,
	},
	176: {
		'description': 'It grows dispirited if it is not with kind people. It can float in midair without moving its wings.',
		'species_id': 176,
	},
	177: {
		'description': 'Because its wings aren’t yet fully grown, it has to hop to get around. It is always staring at something.',
		'species_id': 177,
	},
	178: {
		'description': 'This odd Pokémon can see both the past and the future. It eyes the sun’s movement all day.',
		'species_id': 178,
	},
	179: {
		'description': 'Rubbing its fleece generates electricity. You’ll want to pet it because it’s cute, but if you use your bare hand, you’ll get a painful shock.',
		'species_id': 179,
	},
	180: {
		'description': 'It stores electricity in its fluffy fleece. If it stores up too much, it will start to go bald in those patches.',
		'species_id': 180,
	},
	181: {
		'description': 'Its tail shines bright and strong. It has been prized since long ago as a beacon for sailors.',
		'species_id': 181,
	},
	182: {
		'description': 'Bellossom gather at times and appear to dance. They say that the dance is a ritual to summon the sun.',
		'species_id': 182,
	},
	183: {
		'description': 'Even after Marill swims in a cold sea, its water- repellent fur dries almost as soon as Marill leaves the water. That’s why this Pokémon is never cold.',
		'species_id': 183,
	},
	184: {
		'description': 'These Pokémon create air-filled bubbles. When Azurill play in rivers, Azumarill will cover them with these bubbles.',
		'species_id': 184,
	},
	185: {
		'description': 'It disguises itself as a tree to avoid attack. It hates water, so it will disappear if it starts raining.',
		'species_id': 185,
	},
	186: {
		'description': 'The cry of a male is louder than that of a female. Male Politoed with deep, menacing voices find more popularity with the opposite gender.',
		'species_id': 186,
	},
	187: {
		'description': 'This Pokémon drifts and floats with the wind. If it senses the approach of strong winds, Hoppip links its leaves with other Hoppip to prepare against being blown away.',
		'species_id': 187,
	},
	188: {
		'description': 'Skiploom’s flower blossoms when the temperature rises above 64 degrees Fahrenheit. How much the flower opens depends on the temperature. For that reason, this Pokémon is sometimes used as a thermometer.',
		'species_id': 188,
	},
	189: {
		'description': 'Jumpluff rides warm southern winds to cross the sea and fly to foreign lands. The Pokémon descends to the ground when it encounters cold air while it is floating.',
		'species_id': 189,
	},
	190: {
		'description': 'It searches for prey from the tops of trees. When it spots its favorite food, Bounsweet, Aipom gets excited and pounces.',
		'species_id': 190,
	},
	191: {
		'description': 'Sunkern tries to move as little as it possibly can. It does so because it tries to conserve all the nutrients it has stored in its body for its evolution. It will not eat a thing, subsisting only on morning dew.',
		'species_id': 191,
	},
	192: {
		'description': 'Sunflora converts solar energy into nutrition. It moves around actively in the daytime when it is warm. It stops moving as soon as the sun goes down for the night.',
		'species_id': 192,
	},
	193: {
		'description': 'Yanma is capable of seeing 360 degrees without having to move its eyes. It is a great flier that is adept at making sudden stops and turning midair. This Pokémon uses its flying ability to quickly chase down targeted prey.',
		'species_id': 193,
	},
	194: {
		'description': 'When walking on land, it covers its body with a poisonous film that keeps its skin from dehydrating.',
		'species_id': 194,
	},
	195: {
		'description': 'Its body is always slimy. It often bangs its head on the river bottom as it swims but seems not to care.',
		'species_id': 195,
	},
	196: {
		'description': 'It unleashes psychic power from the orb on its forehead. When its power is exhausted, the orb grows dull and dark.',
		'species_id': 196,
	},
	197: {
		'description': 'On the night of a full moon, or when it gets excited, the ring patterns on its body glow yellow.',
		'species_id': 197,
	},
	198: {
		'description': 'It searches for shiny things for its boss. Murkrow’s presence is said to be unlucky, so many people detest it.',
		'species_id': 198,
	},
	199: {
		'description': 'Slowking can solve any problem presented to it, but no one can understand a thing Slowking says.',
		'species_id': 199,
	},
	200: {
		'description': 'What makes it happy is imitating the voices of weeping people and scaring everyone. It doesn’t deal well with folks who aren’t easily frightened.',
		'species_id': 200,
	},
	201: {
		'description': 'This Pokémon is shaped like ancient writing. It is a mystery as to which came first, the ancient writings or the various Unown. Research into this topic is ongoing but nothing is known.',
		'species_id': 201,
	},
	202: {
		'description': 'To keep its pitch-black tail hidden, it lives quietly in the darkness. It is never first to attack.',
		'species_id': 202,
	},
	203: {
		'description': 'Girafarig’s rear head contains a tiny brain that is too small for thinking. However, the rear head doesn’t need to sleep, so it can keep watch over its surroundings 24 hours a day.',
		'species_id': 203,
	},
	204: {
		'description': 'It sticks tree bark to itself with its saliva, making itself thicker and larger. Elderly Pineco are ridiculously huge.',
		'species_id': 204,
	},
	205: {
		'description': 'In the moment that it gulps down its prey, the inside of its shell is exposed, but to this day, no one has ever seen that sight.',
		'species_id': 205,
	},
	206: {
		'description': 'The nests Dunsparce live in are mazes of tunnels. They never get lost in their own nests—they can tell where they are by the scent of the dirt.',
		'species_id': 206,
	},
	207: {
		'description': 'Gligar glides through the air without a sound as if it were sliding. This Pokémon hangs on to the face of its foe using its clawed hind legs and the large pincers on its forelegs, then injects the prey with its poison barb.',
		'species_id': 207,
	},
	208: {
		'description': 'It is thought its body transformed as a result of iron accumulating internally from swallowing soil.',
		'species_id': 208,
	},
	209: {
		'description': 'In contrast to its appearance, it’s quite timid. When playing with other puppy Pokémon, it sometimes gets bullied.',
		'species_id': 209,
	},
	210: {
		'description': 'Although it’s popular with young people, Granbull is timid and sensitive, so it’s totally incompetent as a watchdog.',
		'species_id': 210,
	},
	211: {
		'description': 'The small spikes covering its body developed from scales. They inject a toxin that causes fainting.',
		'species_id': 211,
	},
	212: {
		'description': 'Though its body is slim, Scizor has tremendous attacking power. Even Scizor’s muscles are made of metal.',
		'species_id': 212,
	},
	213: {
		'description': 'The berries stored in its vaselike shell eventually become a thick, pulpy juice.',
		'species_id': 213,
	},
	214: {
		'description': 'This Pokémon takes pride in its strength, which allows it to lift things 100 times heavier than itself with no trouble at all.',
		'species_id': 214,
	},
	215: {
		'description': 'It has a cunning yet savage disposition. It waits for parents to leave their nests, and then it sneaks in to steal their eggs.',
		'species_id': 215,
	},
	216: {
		'description': 'This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill.',
		'species_id': 216,
	},
	217: {
		'description': 'In the forests inhabited by Ursaring, it is said that there are many streams and towering trees where they gather food. This Pokémon walks through its forest gathering food every day.',
		'species_id': 217,
	},
	218: {
		'description': 'Slugma does not have any blood in its body. Instead, intensely hot magma circulates throughout this Pokémon’s body, carrying essential nutrients and oxygen to its organs.',
		'species_id': 218,
	},
	219: {
		'description': 'Magcargo’s body temperature is approximately 18,000 degrees Fahrenheit. Water is vaporized on contact. If this Pokémon is caught in the rain, the raindrops instantly turn into steam, cloaking the area in a thick fog.',
		'species_id': 219,
	},
	220: {
		'description': 'If it smells something enticing, it dashes off headlong to find the source of the aroma.',
		'species_id': 220,
	},
	221: {
		'description': 'Although its legs are short, its rugged hooves prevent it from slipping, even on icy ground.',
		'species_id': 221,
	},
	222: {
		'description': 'Sudden climate change wiped out this ancient kind of Corsola. This Pokémon absorbs others’ life-force through its branches.',
		'species_id': 222,
	},
	223: {
		'description': 'Using its dorsal fin as a suction pad, it clings to a Mantine’s underside to scavenge for leftovers.',
		'species_id': 223,
	},
	224: {
		'description': 'It traps enemies with its suction-cupped tentacles, then smashes them with its rock-hard head.',
		'species_id': 224,
	},
	225: {
		'description': 'It has a generous habit of sharing its food with people and Pokémon, so it’s always scrounging around for more food.',
		'species_id': 225,
	},
	226: {
		'description': 'As it majestically swims, it doesn’t care if Remoraid attach to it to scavenge for its leftovers.',
		'species_id': 226,
	},
	227: {
		'description': 'People fashion swords from Skarmory’s shed feathers, so this Pokémon is a popular element in heraldic designs.',
		'species_id': 227,
	},
	228: {
		'description': 'They make repeated eerie howls before dawn to call attention to their pack.',
		'species_id': 228,
	},
	229: {
		'description': 'Identifiable by its eerie howls, people a long time ago thought it was the grim reaper and feared it.',
		'species_id': 229,
	},
	230: {
		'description': 'Scales shed by this Pokémon have such a splendorous gleam to them that they’ve been given to royalty as gifts.',
		'species_id': 230,
	},
	231: {
		'description': 'Phanpy uses its long nose to shower itself. When others gather around, they thoroughly douse each other with water. These Pokémon can be seen drying their soaking-wet bodies at the edge of water.',
		'species_id': 231,
	},
	232: {
		'description': 'If Donphan were to tackle with its hard body, even a house could be destroyed. Using its massive strength, the Pokémon helps clear rock and mud slides that block mountain trails.',
		'species_id': 232,
	},
	233: {
		'description': 'After artificial intelligence was implemented in Porygon2, the Pokémon began using a strange language that only other Porygon2 understand.',
		'species_id': 233,
	},
	234: {
		'description': 'Stantler’s magnificent antlers were traded at high prices as works of art. As a result, this Pokémon was hunted close to extinction by those who were after the priceless antlers.',
		'species_id': 234,
	},
	235: {
		'description': 'It draws symbols with the fluid that oozes from the tip of its tail. Depending on the symbol, Smeargle fanatics will pay big money for them.',
		'species_id': 235,
	},
	236: {
		'description': 'Even though it is small, it can’t be ignored because it will slug any handy target without warning.',
		'species_id': 236,
	},
	237: {
		'description': 'After doing a handstand to throw off the opponent’s timing, it presents its fancy kick moves.',
		'species_id': 237,
	},
	238: {
		'description': 'Highly perceptive sensors, Smoochum’s lips are kept moist by its daily application of tree sap.',
		'species_id': 238,
	},
	239: {
		'description': 'When its horns shine a bluish white, that’s the sign it’s fully charged. You’ll get a shocking jolt if you touch it!',
		'species_id': 239,
	},
	240: {
		'description': 'Its body temperature is always around 1,100 degrees Fahrenheit. If Magby falls into a small- enough pond, the whole thing will dry up.',
		'species_id': 240,
	},
	241: {
		'description': 'This Pokémon needs to be milked every day, or else it will fall ill. The flavor of Miltank milk changes with the seasons.',
		'species_id': 241,
	},
	242: {
		'description': 'Blissey lays mysterious eggs that are filled with happiness. It’s said that anyone who eats a Blissey egg will start acting kindly to all others.',
		'species_id': 242,
	},
	243: {
		'description': 'Raikou embodies the speed of lightning. The roars of this Pokémon send shock waves shuddering through the air and shake the ground as if lightning bolts had come crashing down.',
		'species_id': 243,
	},
	244: {
		'description': 'Entei embodies the passion of magma. This Pokémon is thought to have been born in the eruption of a volcano. It sends up massive bursts of fire that utterly consume all that they touch.',
		'species_id': 244,
	},
	245: {
		'description': 'Suicune embodies the compassion of a pure spring of water. It runs across the land with gracefulness. This Pokémon has the power to purify dirty water.',
		'species_id': 245,
	},
	246: {
		'description': 'It feeds on soil. After it has eaten a large mountain, it will fall asleep so it can grow.',
		'species_id': 246,
	},
	247: {
		'description': 'It will not stay still, even while it’s a pupa. It already has arms and legs under its solid shell.',
		'species_id': 247,
	},
	248: {
		'description': 'The quakes caused when it walks make even great mountains crumble and change the surrounding terrain.',
		'species_id': 248,
	},
	249: {
		'description': 'Lugia’s wings pack devastating power—a light fluttering of its wings can blow apart regular houses. As a result, this Pokémon chooses to live out of sight deep under the sea.',
		'species_id': 249,
	},
	250: {
		'description': 'Ho-Oh’s feathers glow in seven colors depending on the angle at which they are struck by light. These feathers are said to bring happiness to the bearers. This Pokémon is said to live at the foot of a rainbow.',
		'species_id': 250,
	},
	251: {
		'description': 'This Pokémon traveled through time to come from the future. It bolsters grass and trees with its own strength, and it can heal wounds, too.',
		'species_id': 251,
	},
	252: {
		'description': 'Treecko is cool, calm, and collected—it never panics under any situation. If a bigger foe were to glare at this Pokémon, it would glare right back without conceding an inch of ground.',
		'species_id': 252,
	},
	253: {
		'description': 'This Pokémon adeptly flies from branch to branch in trees. In a forest, no Pokémon can ever hope to catch a fleeing Grovyle however fast they may be.',
		'species_id': 253,
	},
	254: {
		'description': 'Sceptile has seeds growing on its back. They are said to be bursting with nutrients that revitalize trees. This Pokémon raises the trees in a forest with loving care.',
		'species_id': 254,
	},
	255: {
		'description': 'Torchic has a place inside its body where it keeps its flame. Give it a hug—it will be glowing with warmth. This Pokémon is covered all over by a fluffy coat of down.',
		'species_id': 255,
	},
	256: {
		'description': 'Combusken battles with the intensely hot flames it spews from its beak and with outstandingly destructive kicks. This Pokémon’s cry is very loud and distracting.',
		'species_id': 256,
	},
	257: {
		'description': 'Blaziken has incredibly strong legs—it can easily clear a 30-story building in one leap. This Pokémon’s blazing punches leave its foes scorched and blackened.',
		'species_id': 257,
	},
	258: {
		'description': 'In water, Mudkip breathes using the gills on its cheeks. If it is faced with a tight situation in battle, this Pokémon will unleash its amazing power—it can crush rocks bigger than itself.',
		'species_id': 258,
	},
	259: {
		'description': 'Marshtomp is much faster at traveling through mud than it is at swimming. This Pokémon’s hindquarters exhibit obvious development, giving it the ability to walk on just its hind legs.',
		'species_id': 259,
	},
	260: {
		'description': 'Swampert predicts storms by sensing subtle differences in the sounds of waves and tidal winds with its fins. If a storm is approaching, it piles up boulders to protect itself.',
		'species_id': 260,
	},
	261: {
		'description': 'Poochyena is an omnivore—it will eat anything. A distinguishing feature is how large its fangs are compared to its body. This Pokémon tries to intimidate its foes by making the hair on its tail bristle out.',
		'species_id': 261,
	},
	262: {
		'description': 'Mightyena travel and act as a pack in the wild. The memory of its life in the wild compels the Pokémon to obey only those Trainers that it recognizes to possess superior skill.',
		'species_id': 262,
	},
	263: {
		'description': 'Thought to be the oldest form of Zigzagoon, it moves in zigzags and wreaks havoc upon its surroundings.',
		'species_id': 263,
	},
	264: {
		'description': 'It uses its explosive speed and razor-sharp claws to bring down prey. Running along winding paths is not its strong suit.',
		'species_id': 264,
	},
	265: {
		'description': 'Wurmple is targeted by Swellow as prey. This Pokémon will try to resist by pointing the spikes on its rear at the attacking predator. It will weaken the foe by leaking poison from the spikes.',
		'species_id': 265,
	},
	266: {
		'description': 'Silcoon was thought to endure hunger and not consume anything before its evolution. However, it is now thought that this Pokémon slakes its thirst by drinking rainwater that collects on its silk.',
		'species_id': 266,
	},
	267: {
		'description': 'Beautifly has a long mouth like a coiled needle, which is very convenient for collecting pollen from flowers. This Pokémon rides the spring winds as it flits around gathering pollen.',
		'species_id': 267,
	},
	268: {
		'description': 'If it is attacked, Cascoon remains motionless however badly it may be hurt. It does so because if it were to move, its body would be weak upon evolution. This Pokémon will also not forget the pain it endured.',
		'species_id': 268,
	},
	269: {
		'description': 'When Dustox flaps its wings, a fine dust is scattered all over. This dust is actually a powerful poison that will even make a pro wrestler sick. This Pokémon searches for food using its antennae like radar.',
		'species_id': 269,
	},
	270: {
		'description': 'Its leaf grew too large for it to live on land. That is how it began to live floating in the water.',
		'species_id': 270,
	},
	271: {
		'description': 'It lives at the water’s edge where it is sunny. It sleeps on a bed of water grass by day and becomes active at night.',
		'species_id': 271,
	},
	272: {
		'description': 'If it hears festive music, it begins moving in rhythm in order to amplify its power.',
		'species_id': 272,
	},
	273: {
		'description': 'It attaches itself to a tree branch using the top of its head. Strong winds can sometimes make it fall.',
		'species_id': 273,
	},
	274: {
		'description': 'They live in holes bored in large trees. The sound of Nuzleaf’s grass flute fills listeners with dread.',
		'species_id': 274,
	},
	275: {
		'description': 'It lives quietly in the deep forest. It is said to create chilly winter winds with the fans it holds.',
		'species_id': 275,
	},
	276: {
		'description': 'Taillow is young—it has only just left its nest. As a result, it sometimes becomes lonesome and cries at night. This Pokémon feeds on Wurmple that live in forests.',
		'species_id': 276,
	},
	277: {
		'description': 'Swellow is very conscientious about the upkeep of its glossy wings. Once two Swellow are gathered, they diligently take care of cleaning each other’s wings.',
		'species_id': 277,
	},
	278: {
		'description': 'It soars on updrafts without flapping its wings. It makes a nest on sheer cliffs at the sea’s edge.',
		'species_id': 278,
	},
	279: {
		'description': 'Skimming the water’s surface, it dips its large bill in the sea, scoops up food and water, and carries it.',
		'species_id': 279,
	},
	280: {
		'description': 'If its horns capture the warm feelings of people or Pokémon, its body warms up slightly.',
		'species_id': 280,
	},
	281: {
		'description': 'It has a psychic power that enables it to distort the space around it and see into the future.',
		'species_id': 281,
	},
	282: {
		'description': 'To protect its Trainer, it will expend all its psychic power to create a small black hole.',
		'species_id': 282,
	},
	283: {
		'description': 'It lives in ponds and marshes that feature lots of plant life. It often fights with Dewpider, whose habitat and diet are similar.',
		'species_id': 283,
	},
	284: {
		'description': 'Its thin, winglike antennae are highly absorbent. It waits out rainy days in tree hollows.',
		'species_id': 284,
	},
	285: {
		'description': 'If Shroomish senses danger, it shakes its body and scatters spores from the top of its head. This Pokémon’s spores are so toxic, they make trees and weeds wilt.',
		'species_id': 285,
	},
	286: {
		'description': 'The seeds ringing Breloom’s tail are made of hardened toxic spores. It is horrible to eat the seeds. Just taking a bite of this Pokémon’s seed will cause your stomach to rumble.',
		'species_id': 286,
	},
	287: {
		'description': 'Slakoth’s heart beats just once a minute. Whatever happens, it is content to loaf around motionless. It is rare to see this Pokémon in motion.',
		'species_id': 287,
	},
	288: {
		'description': 'Vigoroth is simply incapable of remaining still. Even when it tries to sleep, the blood in its veins grows agitated, compelling this Pokémon to run wild throughout the jungle before it can settle down.',
		'species_id': 288,
	},
	289: {
		'description': 'Wherever Slaking live, rings of over a yard in diameter appear in grassy fields. They are made by the Pokémon as it eats all the grass within reach while lying prone on the ground.',
		'species_id': 289,
	},
	290: {
		'description': 'It can sometimes live underground for more than 10 years. It absorbs nutrients from the roots of trees.',
		'species_id': 290,
	},
	291: {
		'description': 'This Pokémon is so quick, it is said to be able to avoid any attack. It loves to feed on tree sap.',
		'species_id': 291,
	},
	292: {
		'description': 'A strange Pokémon—it flies without moving its wings, has a hollow shell for a body, and does not breathe.',
		'species_id': 292,
	},
	293: {
		'description': 'When Whismur cries, the sound of its own voice startles it, making the Pokémon cry even louder. It cries until it’s exhausted, then it falls asleep.',
		'species_id': 293,
	},
	294: {
		'description': 'The force of this Pokémon’s loud voice isn’t just the sound—it’s also the wave of air pressure that blows opponents away and damages them.',
		'species_id': 294,
	},
	295: {
		'description': 'This Pokémon can do more than just shout. To communicate with others of its kind, it’ll emit all sorts of sounds from the holes in its body.',
		'species_id': 295,
	},
	296: {
		'description': 'There’s a rumor of a traditional recipe for stew that Trainers can use to raise strong Makuhita.',
		'species_id': 296,
	},
	297: {
		'description': 'Hariyama that are big and fat aren’t necessarily strong. There are some small ones that move nimbly and use moves skillfully.',
		'species_id': 297,
	},
	298: {
		'description': 'Although Azurill are normally docile, an angry one will swing around the big ball on its tail and try to smash its opponents.',
		'species_id': 298,
	},
	299: {
		'description': 'It hunts without twitching a muscle by pulling in its prey with powerful magnetism. But sometimes it pulls natural enemies in close.',
		'species_id': 299,
	},
	300: {
		'description': 'Skitty is known to chase around playfully after its own tail. In the wild, this Pokémon lives in holes in the trees of forests. It is very popular as a pet because of its adorable looks.',
		'species_id': 300,
	},
	301: {
		'description': 'Delcatty sleeps anywhere it wants without keeping a permanent nest. If other Pokémon approach it as it sleeps, this Pokémon will never fight—it will just move away somewhere else.',
		'species_id': 301,
	},
	302: {
		'description': 'It feeds on gemstone crystals. In darkness, its eyes sparkle with the glitter of jewels.',
		'species_id': 302,
	},
	303: {
		'description': 'It chomps with its gaping mouth. Its huge jaws are actually steel horns that have been transformed.',
		'species_id': 303,
	},
	304: {
		'description': 'Aron has a body of steel. With one all-out charge, this Pokémon can demolish even a heavy dump truck. The destroyed dump truck then becomes a handy meal for the Pokémon.',
		'species_id': 304,
	},
	305: {
		'description': 'Lairon feeds on iron contained in rocks and water. It makes its nest on mountains where iron ore is buried. As a result, the Pokémon often clashes with humans mining the iron ore.',
		'species_id': 305,
	},
	306: {
		'description': 'Aggron is protective of its environment. If its mountain is ravaged by a landslide or a fire, this Pokémon will haul topsoil to the area, plant trees, and beautifully restore its own territory.',
		'species_id': 306,
	},
	307: {
		'description': 'Meditite heightens its inner energy through meditation. It survives on just one berry a day. Minimal eating is another aspect of this Pokémon’s training.',
		'species_id': 307,
	},
	308: {
		'description': 'Through the power of meditation, Medicham developed its sixth sense. It gained the ability to use psychokinetic powers. This Pokémon is known to meditate for a whole month without eating.',
		'species_id': 308,
	},
	309: {
		'description': 'It stores electricity in its fur. It gives off sparks from all over its body in seasons when the air is dry.',
		'species_id': 309,
	},
	310: {
		'description': 'It rarely appears before people. It is said to nest where lightning has fallen.',
		'species_id': 310,
	},
	311: {
		'description': 'When Plusle is cheering on its partner, it flashes with electric sparks from all over its body. If its partner loses, this Pokémon cries loudly.',
		'species_id': 311,
	},
	312: {
		'description': 'Minun loves to cheer on its partner in battle. It gives off sparks from its body while it is doing so. If its partner is in trouble, this Pokémon gives off increasing amounts of sparks.',
		'species_id': 312,
	},
	313: {
		'description': 'Volbeat’s tail glows like a lightbulb. With other Volbeat, it uses its tail to draw geometric shapes in the night sky. This Pokémon loves the sweet aroma given off by Illumise.',
		'species_id': 313,
	},
	314: {
		'description': 'Illumise leads a flight of illuminated Volbeat to draw signs in the night sky. This Pokémon is said to earn greater respect from its peers by composing more complex designs in the sky.',
		'species_id': 314,
	},
	315: {
		'description': 'It uses the different poisons in each hand separately when it attacks. The stronger its aroma, the healthier it is.',
		'species_id': 315,
	},
	316: {
		'description': 'Most of Gulpin’s body is made up of its stomach—its heart and brain are very small in comparison. This Pokémon’s stomach contains special enzymes that dissolve anything.',
		'species_id': 316,
	},
	317: {
		'description': 'Swalot has no teeth, so what it eats, it swallows whole, no matter what. Its cavernous mouth yawns widely. An automobile tire could easily fit inside this Pokémon’s mouth.',
		'species_id': 317,
	},
	318: {
		'description': 'These Pokémon have sharp fangs and powerful jaws. Sailors avoid Carvanha dens at all costs.',
		'species_id': 318,
	},
	319: {
		'description': 'This Pokémon is known as the Bully of the Sea. Any ship entering the waters Sharpedo calls home will be attacked—no exceptions.',
		'species_id': 319,
	},
	320: {
		'description': 'When it sucks in a large volume of seawater, it becomes like a big, bouncy ball. It eats a ton of food daily.',
		'species_id': 320,
	},
	321: {
		'description': 'Its immense size is the reason for its popularity. Wailord watching is a favorite sightseeing activity in various parts of the world.',
		'species_id': 321,
	},
	322: {
		'description': 'Numel stores magma of almost 2,200 degrees Fahrenheit within its body. If it gets wet, the magma cools and hardens. In that event, the Pokémon’s body grows heavy and its movements become sluggish.',
		'species_id': 322,
	},
	323: {
		'description': 'The humps on Camerupt’s back are formed by a transformation of its bones. They sometimes blast out molten magma. This Pokémon apparently erupts often when it is enraged.',
		'species_id': 323,
	},
	324: {
		'description': 'You find abandoned coal mines full of them. They dig tirelessly in search of coal.',
		'species_id': 324,
	},
	325: {
		'description': 'Spoink keeps a pearl on top of its head. The pearl functions to amplify this Pokémon’s psychokinetic powers. It is therefore on a constant search for a bigger pearl.',
		'species_id': 325,
	},
	326: {
		'description': 'Grumpig uses the black pearls on its body to wield its fantastic powers. When it is doing so, it dances bizarrely. This Pokémon’s black pearls are valuable as works of art.',
		'species_id': 326,
	},
	327: {
		'description': 'Each Spinda’s spot pattern is different. With its stumbling movements, it evades opponents’ attacks brilliantly!',
		'species_id': 327,
	},
	328: {
		'description': 'It makes an inescapable conical pit and lies in wait at the bottom for prey to come tumbling down.',
		'species_id': 328,
	},
	329: {
		'description': 'To help make its wings grow, it dissolves quantities of prey in its digestive juices and guzzles them down every day.',
		'species_id': 329,
	},
	330: {
		'description': 'It is nicknamed the Desert Spirit because the flapping of its wings sounds like a woman singing.',
		'species_id': 330,
	},
	331: {
		'description': 'The more arid and harsh the environment, the more pretty and fragrant a flower Cacnea grows. This Pokémon battles by wildly swinging its thorny arms.',
		'species_id': 331,
	},
	332: {
		'description': 'If a traveler is going through a desert in the thick of night, Cacturne will follow in a ragtag group. The Pokémon are biding their time, waiting for the traveler to tire and become incapable of moving.',
		'species_id': 332,
	},
	333: {
		'description': 'Swablu loves to make things clean. If it spots something dirty, it will wipe and polish it with its cottony wings. If its wings become dirty, this Pokémon finds a stream and showers itself.',
		'species_id': 333,
	},
	334: {
		'description': 'Altaria sings in a gorgeous soprano. Its wings are like cotton clouds. This Pokémon catches updrafts with its buoyant wings and soars way up into the wild blue yonder.',
		'species_id': 334,
	},
	335: {
		'description': 'Zangoose usually stays on all fours, but when angered, it gets up on its hind legs and extends its claws. This Pokémon shares a bitter rivalry with Seviper that dates back over generations.',
		'species_id': 335,
	},
	336: {
		'description': 'Seviper’s swordlike tail serves two purposes—it slashes foes and douses them with secreted poison. This Pokémon will not give up its long-running blood feud with Zangoose.',
		'species_id': 336,
	},
	337: {
		'description': 'It was discovered at the site of a meteor strike 40 years ago. Its stare can lull its foes to sleep.',
		'species_id': 337,
	},
	338: {
		'description': 'Solar energy is the source of its power, so it is strong during the daytime. When it spins, its body shines.',
		'species_id': 338,
	},
	339: {
		'description': 'It probes muddy riverbeds with its two long whiskers. A slimy film protects its body.',
		'species_id': 339,
	},
	340: {
		'description': 'It claims a large swamp to itself. If a foe comes near it, it sets off tremors by thrashing around.',
		'species_id': 340,
	},
	341: {
		'description': 'It was originally a Pokémon from afar that escaped to the wild. It can adapt to the dirtiest river.',
		'species_id': 341,
	},
	342: {
		'description': 'A brutish Pokémon that loves to battle. It will crash itself into any foe that approaches its nest.',
		'species_id': 342,
	},
	343: {
		'description': 'It was discovered in ancient ruins. While moving, it constantly spins. It stands on one foot even when asleep.',
		'species_id': 343,
	},
	344: {
		'description': 'It appears to have been born from clay dolls made by ancient people. It uses telekinesis to float and move.',
		'species_id': 344,
	},
	345: {
		'description': 'It sticks to rocks with its powerful suckers and can’t be washed away no matter how rough the surf gets.',
		'species_id': 345,
	},
	346: {
		'description': 'This carnivorous Pokémon lived in primordial seas. It catches prey in its eight tentacles and dissolves them with digestive fluid as it eats.',
		'species_id': 346,
	},
	347: {
		'description': 'This is one kind of primeval bug Pokémon. With eight wings, it could apparently swim a lot faster than you’d expect.',
		'species_id': 347,
	},
	348: {
		'description': 'Armaldo adapted from living in water to living on land. It has been determined that it is the ancestor of some bug Pokémon.',
		'species_id': 348,
	},
	349: {
		'description': 'It is a shabby and ugly Pokémon. However, it is very hardy and can survive on little water.',
		'species_id': 349,
	},
	350: {
		'description': 'It’s said that a glimpse of a Milotic and its beauty will calm any hostile emotions you’re feeling.',
		'species_id': 350,
	},
	351: {
		'description': 'Its form changes depending on the weather. The rougher conditions get, the rougher Castform’s disposition!',
		'species_id': 351,
	},
	352: {
		'description': 'Its color changes for concealment and also when its mood or health changes. The darker the color, the healthier it is.',
		'species_id': 352,
	},
	353: {
		'description': 'There’s a proverb that says, “Shun the house where Shuppet gather in the growing dusk.”',
		'species_id': 353,
	},
	354: {
		'description': 'Resentment at being cast off made it spring into being. Some say that treating it well will satisfy it, and it will once more become a stuffed toy.',
		'species_id': 354,
	},
	355: {
		'description': 'Making itself invisible, it silently sneaks up to prey. It has the ability to slip through thick walls.',
		'species_id': 355,
	},
	356: {
		'description': 'It seeks drifting will-o’-the-wisps and sucks them into its empty body. What happens inside is a mystery.',
		'species_id': 356,
	},
	357: {
		'description': 'Bunches of delicious fruit grow around its neck. In warm areas, many ranches raise Tropius.',
		'species_id': 357,
	},
	358: {
		'description': 'In high winds, Chimecho cries as it hangs from a tree branch or the eaves of a building using a suction cup on its head. This Pokémon plucks berries with its long tail and eats them.',
		'species_id': 358,
	},
	359: {
		'description': 'The elderly call it the disaster Pokémon and detest it, but interest in its power to predict disasters is on the rise.',
		'species_id': 359,
	},
	360: {
		'description': 'It tends to move in a pack with others. They cluster in a tight group to sleep in a cave.',
		'species_id': 360,
	},
	361: {
		'description': 'It can only survive in cold areas. It bounces happily around, even in environments as cold as −150 degrees Fahrenheit.',
		'species_id': 361,
	},
	362: {
		'description': 'It can instantly freeze moisture in the atmosphere. It uses this power to freeze its foes.',
		'species_id': 362,
	},
	363: {
		'description': 'Spheal always travels by rolling around on its ball-like body. When the season for ice floes arrives, this Pokémon can be seen rolling about on ice and crossing the sea.',
		'species_id': 363,
	},
	364: {
		'description': 'Sealeo often balances and rolls things on the tip of its nose. While the Pokémon is rolling something, it checks the object’s aroma and texture to determine whether it likes the object or not.',
		'species_id': 364,
	},
	365: {
		'description': 'Walrein swims all over in frigid seawater while crushing icebergs with its grand, imposing tusks. Its thick layer of blubber makes enemy attacks bounce off harmlessly.',
		'species_id': 365,
	},
	366: {
		'description': 'Clamperl’s pearls are exceedingly precious. They can be more than 10 times as costly as Shellder’s pearls.',
		'species_id': 366,
	},
	367: {
		'description': 'Deep seas are their habitat. According to tradition, when Huntail wash up onshore, something unfortunate will happen.',
		'species_id': 367,
	},
	368: {
		'description': 'It sucks bodily fluids out of its prey. The leftover meat sinks to the seafloor, where it becomes food for other Pokémon.',
		'species_id': 368,
	},
	369: {
		'description': 'The reason it hasn’t changed at all in a hundred million years is that it’s apparently already a perfect life-form.',
		'species_id': 369,
	},
	370: {
		'description': 'Luvdisc makes its home in coral reefs in warm seas. It especially likes sleeping in the space between Corsola’s branches.',
		'species_id': 370,
	},
	371: {
		'description': 'Some theories suggest that its behavior of forcefully bashing its head into things stimulates cells that affect its evolution.',
		'species_id': 371,
	},
	372: {
		'description': 'Shelgon lives deep within caves. It stays shut up in its hard shell, dreaming of the day it will be able to fly.',
		'species_id': 372,
	},
	373: {
		'description': 'Thanks to its fervent wishes, the cells in its body finally mutated, and at last it has its heart’s desire—wings.',
		'species_id': 373,
	},
	374: {
		'description': 'If you anger it, it will do more than rampage. It will also burst out strong magnetism, causing nearby machines to break.',
		'species_id': 374,
	},
	375: {
		'description': 'It flies at high speeds around the skies. When it finds its prey, Metang takes a firm grip with its sharp claws and never lets go.',
		'species_id': 375,
	},
	376: {
		'description': 'It analyzes its opponents with more accuracy than a supercomputer, which enables it to calmly back them into a corner.',
		'species_id': 376,
	},
	377: {
		'description': 'Regirock’s body is composed entirely of rocks. Recently, a study made the startling discovery that the rocks were all unearthed from different locations.',
		'species_id': 377,
	},
	378: {
		'description': 'Regice cloaks itself with frigid air of -328 degrees Fahrenheit. Things will freeze solid just by going near this Pokémon. Its icy body is so cold, it will not melt even if it is immersed in magma.',
		'species_id': 378,
	},
	379: {
		'description': 'Registeel was imprisoned by people in ancient times. The metal composing its body is thought to be a curious substance that is not of this earth.',
		'species_id': 379,
	},
	380: {
		'description': 'Latias is highly intelligent and capable of understanding human speech. It is covered with a glass-like down. The Pokémon enfolds its body with its down and refracts light to alter its appearance.',
		'species_id': 380,
	},
	381: {
		'description': 'Latios will only open its heart to a Trainer with a compassionate spirit. This Pokémon can fly faster than a jet plane by folding its forelegs to minimize air resistance.',
		'species_id': 381,
	},
	382: {
		'description': 'Kyogre is said to be the personification of the sea itself. Legends tell of its many clashes against Groudon, as each sought to gain the power of nature.',
		'species_id': 382,
	},
	383: {
		'description': 'Through Primal Reversion and with nature’s full power, it will take back its true form. It can cause magma to erupt and expand the landmass of the world.',
		'species_id': 383,
	},
	384: {
		'description': 'It flies forever through the ozone layer, consuming meteoroids for sustenance. The many meteoroids in its body provide the energy it needs to Mega Evolve.',
		'species_id': 384,
	},
	385: {
		'description': 'It’s believed that when this Pokémon wakes from its 1,000-year slumber, it will grant any wishes written on the notes attached to its head.',
		'species_id': 385,
	},
	386: {
		'description': 'Deoxys emerged from a virus that came from space. It is highly intelligent and wields psychokinetic powers. This Pokémon shoots lasers from the crystalline organ on its chest.',
		'species_id': 386,
	},
	387: {
		'description': 'It undertakes photosynthesis with its body, making oxygen. The leaf on its head wilts if it is thirsty.',
		'species_id': 387,
	},
	388: {
		'description': 'It knows where pure water wells up. It carries fellow Pokémon there on its back.',
		'species_id': 388,
	},
	389: {
		'description': 'Small Pokémon occasionally gather on its unmoving back to begin building their nests.',
		'species_id': 389,
	},
	390: {
		'description': 'The gas made in its belly burns from its rear end. The fire burns weakly when it feels sick.',
		'species_id': 390,
	},
	391: {
		'description': 'It uses ceilings and walls to launch aerial attacks. Its fiery tail is but one weapon.',
		'species_id': 391,
	},
	392: {
		'description': 'It tosses its enemies around with agility. It uses all its limbs to fight in its own unique style.',
		'species_id': 392,
	},
	393: {
		'description': 'Because it is very proud, it hates accepting food from people. Its thick down guards it from cold.',
		'species_id': 393,
	},
	394: {
		'description': 'It lives a solitary life. Its wings deliver wicked blows that can snap even the thickest of trees.',
		'species_id': 394,
	},
	395: {
		'description': 'The three horns that extend from its beak attest to its power. The leader has the biggest horns.',
		'species_id': 395,
	},
	396: {
		'description': 'They flock around mountains and fields, chasing after bug Pokémon. Their singing is noisy and annoying.',
		'species_id': 396,
	},
	397: {
		'description': 'It lives in forests and fields. Squabbles over territory occur when flocks collide.',
		'species_id': 397,
	},
	398: {
		'description': 'When Staravia evolve into Staraptor, they leave the flock to live alone. They have sturdy wings.',
		'species_id': 398,
	},
	399: {
		'description': 'It constantly gnaws on logs and rocks to whittle down its front teeth. It nests alongside water.',
		'species_id': 399,
	},
	400: {
		'description': 'It makes its nest by damming streams with bark and mud. It is known as an industrious worker.',
		'species_id': 400,
	},
	401: {
		'description': 'When its antennae hit each other, it sounds like the music of a xylophone.',
		'species_id': 401,
	},
	402: {
		'description': 'It signals its emotions with its melodies. Scientists are studying these melodic patterns.',
		'species_id': 402,
	},
	403: {
		'description': 'Electricity makes this Pokémon’s fur glow. Shinx sends signals to others of its kind by shaking the tip of its tail while the tail tip is shining brightly.',
		'species_id': 403,
	},
	404: {
		'description': 'Upon encountering an opponent, this Pokémon prepares for battle by extending its claws, which can put out 1,000,000 volts of electricity.',
		'species_id': 404,
	},
	405: {
		'description': 'Seeing through solid objects uses up a lot of Luxray’s electricity, so the Pokémon sleeps for long periods of time to store up energy.',
		'species_id': 405,
	},
	406: {
		'description': 'This Pokémon is highly sensitive to temperature changes. When its bud starts to open, that means spring is right around the corner.',
		'species_id': 406,
	},
	407: {
		'description': 'The poison in its right hand is quick acting. The poison in its left hand is slow acting. Both are life threatening.',
		'species_id': 407,
	},
	408: {
		'description': 'Its hard skull is its distinguishing feature. It snapped trees by headbutting them, and then it fed on their ripe berries.',
		'species_id': 408,
	},
	409: {
		'description': 'This ancient Pokémon used headbutts skillfully. Its brain was really small, so some theories suggest that its stupidity led to its extinction.',
		'species_id': 409,
	},
	410: {
		'description': 'Although its fossils can be found in layers of primeval rock, nothing but its face has ever been discovered.',
		'species_id': 410,
	},
	411: {
		'description': 'This Pokémon is from roughly 100 million years ago. Its terrifyingly tough face is harder than steel.',
		'species_id': 411,
	},
	412: {
		'description': 'If its cloak is broken in battle, it quickly remakes the cloak with materials nearby.',
		'species_id': 412,
	},
	413: {
		'description': 'When Burmy evolved, its cloak became a part of this Pokémon’s body. The cloak is never shed.',
		'species_id': 413,
	},
	414: {
		'description': 'It flutters around at night and steals honey from the Combee hive.',
		'species_id': 414,
	},
	415: {
		'description': 'It ceaselessly gathers nectar from sunrise to sundown, all for the sake of Vespiquen and the swarm.',
		'species_id': 415,
	},
	416: {
		'description': 'Vespiquen that give off more pheromones have larger swarms of Combee attendants.',
		'species_id': 416,
	},
	417: {
		'description': 'A pair may be seen rubbing their cheek pouches together in an effort to share stored electricity.',
		'species_id': 417,
	},
	418: {
		'description': 'It inflates the flotation sac around its neck and pokes its head out of the water to see what is going on.',
		'species_id': 418,
	},
	419: {
		'description': 'Its flotation sac developed as a result of pursuing aquatic prey. It can double as a rubber raft.',
		'species_id': 419,
	},
	420: {
		'description': 'The deeper a Cherubi’s red, the more nutrients it has stockpiled in its body. And the sweeter and tastier its small ball!',
		'species_id': 420,
	},
	421: {
		'description': 'Its folded petals are pretty tough. Bird Pokémon can peck at them all they want, and Cherrim won’t be bothered at all.',
		'species_id': 421,
	},
	422: {
		'description': 'Its appearance changes depending on the environment. One theory suggests that living in cold seas causes Shellos to take on this form.',
		'species_id': 422,
	},
	423: {
		'description': 'Its body is covered in a sticky slime. It’s very susceptible to dehydration, so it can’t spend too much time on land.',
		'species_id': 423,
	},
	424: {
		'description': 'It uses its tails for everything. If it wraps both of its tails around you and gives you a squeeze, that’s proof it really likes you.',
		'species_id': 424,
	},
	425: {
		'description': 'The gathering of many souls gave rise to this Pokémon. During humid seasons, they seem to appear in abundance.',
		'species_id': 425,
	},
	426: {
		'description': 'It grabs people and Pokémon and carries them off somewhere. Where do they go? Nobody knows.',
		'species_id': 426,
	},
	427: {
		'description': 'Buneary can attack by rolling up their ears and then striking with the force created by unrolling them. This attack becomes stronger with training.',
		'species_id': 427,
	},
	428: {
		'description': 'Once hot seasons are over, Lopunny’s coat will be replaced with fur that holds a lot of insulating air in preparation for colder weather.',
		'species_id': 428,
	},
	429: {
		'description': 'Its muttered curses can cause awful headaches or terrifying visions that torment others.',
		'species_id': 429,
	},
	430: {
		'description': 'Its goons take care of most of the fighting for it. The only time it dirties its own hands is in delivering a final blow to finish off an opponent.',
		'species_id': 430,
	},
	431: {
		'description': 'When it’s happy, Glameow demonstrates beautiful movements of its tail, like a dancing ribbon.',
		'species_id': 431,
	},
	432: {
		'description': 'To make itself appear intimidatingly beefy, it tightly cinches its waist with its twin tails.',
		'species_id': 432,
	},
	433: {
		'description': 'There is an orb inside its mouth. When it hops, the orb bounces all over and makes a ringing sound.',
		'species_id': 433,
	},
	434: {
		'description': 'If it lifts its tail and points its rear at you, beware. It’s about to spray you with a fluid stinky enough to make you faint.',
		'species_id': 434,
	},
	435: {
		'description': 'It digs holes in the ground to make its nest. The stench of the fluid it lets fly from the tip of its tail is extremely potent.',
		'species_id': 435,
	},
	436: {
		'description': 'Polishing Bronzor to a shine makes its surface reflect the truth, according to common lore. Be that as it may, Bronzor hates being polished.',
		'species_id': 436,
	},
	437: {
		'description': 'Many scientists suspect that this Pokémon originated outside the Galar region, based on the patterns on its body.',
		'species_id': 437,
	},
	438: {
		'description': 'This Pokémon lives in dry, rocky areas. As its green spheres dry out, their dull luster increases.',
		'species_id': 438,
	},
	439: {
		'description': 'It looks for a Mr. Rime that’s a good dancer and carefully copies the Mr. Rime’s steps like an apprentice.',
		'species_id': 439,
	},
	440: {
		'description': 'Happiny’s willing to lend its precious round stone to those it’s friendly with, but if the stone isn’t returned, Happiny will cry and throw a tantrum.',
		'species_id': 440,
	},
	441: {
		'description': 'It can learn and speak human words. If they gather, they all learn the same saying.',
		'species_id': 441,
	},
	442: {
		'description': 'It was bound to a fissure in an odd keystone as punishment for misdeeds 500 years ago.',
		'species_id': 442,
	},
	443: {
		'description': 'Its original home is an area much hotter than Alola. If you’re planning to live with one, your heating bill will soar.',
		'species_id': 443,
	},
	444: {
		'description': 'It sheds its skin and gradually grows larger. Its scales can be ground into a powder and used as raw materials for traditional medicine.',
		'species_id': 444,
	},
	445: {
		'description': 'Its fine scales don’t just reduce wind resistance—their sharp edges also cause injury to any opponent who attacks it.',
		'species_id': 445,
	},
	446: {
		'description': 'It stores food beneath its fur. It might share just one bite, but only if it really trusts you.',
		'species_id': 446,
	},
	447: {
		'description': 'It can use waves called auras to gauge how others are feeling. These same waves can also tell this Pokémon about the state of the environment.',
		'species_id': 447,
	},
	448: {
		'description': 'It can tell what people are thinking. Only Trainers who have justice in their hearts can earn this Pokémon’s trust.',
		'species_id': 448,
	},
	449: {
		'description': 'This Pokémon is active during the day and passes the cold desert nights burrowed snugly into the sand.',
		'species_id': 449,
	},
	450: {
		'description': 'When roused to violence by its rage, it spews out the quantities of sand it has swallowed and whips up a sandstorm.',
		'species_id': 450,
	},
	451: {
		'description': 'It attacks using the claws on its tail. Once locked in its grip, its prey is unable to move as this Pokémon’s poison seeps in.',
		'species_id': 451,
	},
	452: {
		'description': 'It’s so vicious that it’s called the Sand Demon. Yet when confronted by Hippowdon, Drapion keeps a low profile and will never pick a fight.',
		'species_id': 452,
	},
	453: {
		'description': 'Once diluted, its poison becomes medicinal. This Pokémon came into popularity after a pharmaceutical company chose it as a mascot.',
		'species_id': 453,
	},
	454: {
		'description': 'It booms out a victory croak when its prey goes down in defeat. This Pokémon and Seismitoad are related species.',
		'species_id': 454,
	},
	455: {
		'description': 'It binds itself to trees in marshes. It attracts prey with its sweet-smelling drool and gulps them down.',
		'species_id': 455,
	},
	456: {
		'description': 'It lures in prey with its shining tail fins. It stays near the surface during the day and moves to the depths when night falls.',
		'species_id': 456,
	},
	457: {
		'description': 'They traverse the deep waters as if crawling over the seafloor. The fantastic lights of its fins shine like stars in the night sky.',
		'species_id': 457,
	},
	458: {
		'description': 'It swims along with a school of Remoraid, and they’ll all fight together to repel attackers.',
		'species_id': 458,
	},
	459: {
		'description': 'The berries that grow around its belly are like ice pops. Galarian Darumaka absolutely love these berries.',
		'species_id': 459,
	},
	460: {
		'description': 'This Pokémon is known to bring blizzards. A shake of its massive body is enough to cause whiteout conditions.',
		'species_id': 460,
	},
	461: {
		'description': 'With its claws, it leaves behind signs for its friends to find. The number of distinct signs is said to be over 500.',
		'species_id': 461,
	},
	462: {
		'description': 'It’s thought that a special magnetic field changed the molecular structure of this Pokémon’s body, and that’s what caused the Pokémon’s evolution.',
		'species_id': 462,
	},
	463: {
		'description': 'Lickilicky can do just about anything with its tongue, which is as dexterous as the human hand. In contrast, Lickilicky’s use of its fingers is clumsy.',
		'species_id': 463,
	},
	464: {
		'description': 'It relies on its carapace to deflect incoming attacks and throw its enemy off balance. As soon as that happens, it drives its drill into the foe.',
		'species_id': 464,
	},
	465: {
		'description': 'Vine growth is accelerated for Tangrowth living in warm climates. If the vines grow long, Tangrowth shortens them by tearing parts of them off.',
		'species_id': 465,
	},
	466: {
		'description': 'A single Electivire can provide enough electricity for all the buildings in a big city for a year.',
		'species_id': 466,
	},
	467: {
		'description': 'Magmortar takes down its enemies by shooting fireballs, which burn them to a blackened crisp. It avoids this method when hunting prey.',
		'species_id': 467,
	},
	468: {
		'description': 'Known as a bringer of blessings, it’s been depicted on good-luck charms since ancient times.',
		'species_id': 468,
	},
	469: {
		'description': 'This six-legged Pokémon is easily capable of transporting an adult in flight. The wings on its tail help it stay balanced.',
		'species_id': 469,
	},
	470: {
		'description': 'This Pokémon’s tail is blade sharp, with a fantastic cutting edge that can slice right though large trees.',
		'species_id': 470,
	},
	471: {
		'description': 'The coldness emanating from Glaceon causes powdery snow to form, making it quite a popular Pokémon at ski resorts.',
		'species_id': 471,
	},
	472: {
		'description': 'Its flight is soundless. It uses its lengthy tail to carry off its prey... Then its elongated fangs do the rest.',
		'species_id': 472,
	},
	473: {
		'description': 'It looks strong, and that’s exactly what it is. As the weather grows colder, its ice tusks grow longer, thicker, and more impressive.',
		'species_id': 473,
	},
	474: {
		'description': 'Some say an additional program made this Pokémon evolve, but even academics can’t agree on whether Porygon-Z is really an evolution.',
		'species_id': 474,
	},
	475: {
		'description': 'Sharply attuned to others’ wishes for help, this Pokémon seeks out those in need and aids them in battle.',
		'species_id': 475,
	},
	476: {
		'description': 'It uses three small units to catch prey and battle enemies. The main body mostly just gives orders.',
		'species_id': 476,
	},
	477: {
		'description': 'With the mouth on its belly, Dusknoir swallows its target whole. The soul is the only thing eaten— Dusknoir disgorges the body before departing.',
		'species_id': 477,
	},
	478: {
		'description': 'It spits out cold air of nearly −60 degrees Fahrenheit to freeze its quarry. It brings frozen prey back to its lair and neatly lines them up.',
		'species_id': 478,
	},
	479: {
		'description': 'In this form, Rotom focuses its antics on plants. Any flowers you were growing are going to get mowed down.',
		'species_id': 479,
	},
	480: {
		'description': 'It is said that its emergence gave humans the intelligence to improve their quality of life.',
		'species_id': 480,
	},
	481: {
		'description': 'It sleeps at the bottom of a lake. Its spirit is said to leave its body to fly on the lake’s surface.',
		'species_id': 481,
	},
	482: {
		'description': 'It is thought that Uxie, Mesprit, and Azelf all came from the same egg.',
		'species_id': 482,
	},
	483: {
		'description': 'It has the power to control time. It appears in Sinnoh-region myths as an ancient deity.',
		'species_id': 483,
	},
	484: {
		'description': 'It has the ability to distort space. It is described as a deity in Sinnoh-region mythology.',
		'species_id': 484,
	},
	485: {
		'description': 'Boiling blood, like magma, circulates through its body. It makes its dwelling place in volcanic caves.',
		'species_id': 485,
	},
	486: {
		'description': 'There is an enduring legend that states this Pokémon towed continents with ropes.',
		'species_id': 486,
	},
	487: {
		'description': 'It was banished for its violence. It silently gazed upon the old world from the Distortion World.',
		'species_id': 487,
	},
	488: {
		'description': 'Those who sleep holding Cresselia’s feather are assured of joyful dreams. It is said to represent the crescent moon.',
		'species_id': 488,
	},
	489: {
		'description': 'It drifts in warm seas. It always returns to where it was born, no matter how far it may have drifted.',
		'species_id': 489,
	},
	490: {
		'description': 'It starts its life with a wondrous power that permits it to bond with any kind of Pokémon.',
		'species_id': 490,
	},
	491: {
		'description': 'It can lull people to sleep and make them dream. It is active during nights of the new moon.',
		'species_id': 491,
	},
	492: {
		'description': 'The blooming of Gracidea flowers confers the power of flight upon it. Feelings of gratitude are the message it delivers.',
		'species_id': 492,
	},
	493: {
		'description': 'It is told in mythology that this Pokémon was born before the universe even existed.',
		'species_id': 493,
	},
	494: {
		'description': 'When it shares the infinite energy it creates, that being’s entire body will be overflowing with power.',
		'species_id': 494,
	},
	495: {
		'description': 'They photosynthesize by bathing their tails in sunlight. When they are not feeling well, their tails droop.',
		'species_id': 495,
	},
	496: {
		'description': 'When it gets dirty, its leaves can’t be used in photosynthesis, so it always keeps itself clean.',
		'species_id': 496,
	},
	497: {
		'description': 'It can stop its opponents’ movements with just a glare. It takes in solar energy and boosts it internally.',
		'species_id': 497,
	},
	498: {
		'description': 'It loves to eat roasted berries, but sometimes it gets too excited and burns them to a crisp.',
		'species_id': 498,
	},
	499: {
		'description': 'When its internal fire flares up, its movements grow sharper and faster. When in trouble, it emits smoke.',
		'species_id': 499,
	},
	500: {
		'description': 'It has mastered fast and powerful fighting moves. It grows a beard of fire.',
		'species_id': 500,
	},
	501: {
		'description': 'It fights using the scalchop on its stomach. In response to an attack, it retaliates immediately by slashing.',
		'species_id': 501,
	},
	502: {
		'description': 'As a result of strict training, each Dewott learns different forms for using the scalchops.',
		'species_id': 502,
	},
	503: {
		'description': 'One swing of the sword incorporated in its armor can fell an opponent. A simple glare from one of them quiets everybody.',
		'species_id': 503,
	},
	504: {
		'description': 'Extremely cautious, one of them will always be on the lookout, but it won’t notice a foe coming from behind.',
		'species_id': 504,
	},
	505: {
		'description': 'When they see an enemy, their tails stand high, and they spit the seeds of berries stored in their cheek pouches.',
		'species_id': 505,
	},
	506: {
		'description': 'This Pokémon is far brighter than the average child, and Lillipup won’t forget the love it receives or any abuse it suffers.',
		'species_id': 506,
	},
	507: {
		'description': 'The black fur that covers this Pokémon’s body is dense and springy. Even sharp fangs bounce right off.',
		'species_id': 507,
	},
	508: {
		'description': 'Stoutland is immensely proud of its impressive moustache. It’s said that moustache length is what determines social standing among this species.',
		'species_id': 508,
	},
	509: {
		'description': 'Opponents that get drawn in by its adorable behavior come away with stinging scratches from its claws and stinging pride from its laughter.',
		'species_id': 509,
	},
	510: {
		'description': 'This stealthy Pokémon sneaks up behind prey without making any sound at all. It competes with Thievul for territory.',
		'species_id': 510,
	},
	511: {
		'description': 'It’s good at finding berries and gathers them from all over. It’s kind enough to share them with friends.',
		'species_id': 511,
	},
	512: {
		'description': 'Ill tempered, it fights by swinging its barbed tail around wildly. The leaf growing on its head is very bitter.',
		'species_id': 512,
	},
	513: {
		'description': 'This Pokémon lives in caves in volcanoes. The fire within the tuft on its head can reach 600 degrees Fahrenheit.',
		'species_id': 513,
	},
	514: {
		'description': 'When it gets excited, embers rise from its head and tail and it gets hot. For some reason, it loves sweets.',
		'species_id': 514,
	},
	515: {
		'description': 'The water stored inside the tuft on its head is full of nutrients. Plants that receive its water grow large.',
		'species_id': 515,
	},
	516: {
		'description': 'It prefers places with clean water. When its tuft runs low, it replenishes it by siphoning up water with its tail.',
		'species_id': 516,
	},
	517: {
		'description': 'It eats dreams and releases mist. The mist is pink when it’s eating a good dream, and black when it’s eating a nightmare.',
		'species_id': 517,
	},
	518: {
		'description': 'It drowses and dreams all the time. It’s best to leave it be if it’s just woken up, as it’s a terrible grump when freshly roused from sleep.',
		'species_id': 518,
	},
	519: {
		'description': 'It’s forgetful and not very bright, but many Trainers love it anyway for its friendliness and sincerity.',
		'species_id': 519,
	},
	520: {
		'description': 'These bright Pokémon have acute memories. Apparently delivery workers often choose them as their partners.',
		'species_id': 520,
	},
	521: {
		'description': 'This Pokémon is intelligent and intensely proud. People will sit up and take notice if you become the Trainer of one.',
		'species_id': 521,
	},
	522: {
		'description': 'Its mane shines when it discharges electricity. They use the frequency and rhythm of these flashes to communicate.',
		'species_id': 522,
	},
	523: {
		'description': 'They have lightning-like movements. When Zebstrika run at full speed, the sound of thunder reverberates.',
		'species_id': 523,
	},
	524: {
		'description': 'When it detects a noise, it starts to move. The energy core inside it makes this Pokémon slightly warm to the touch.',
		'species_id': 524,
	},
	525: {
		'description': 'It relies on sound in order to monitor what’s in its vicinity. When angered, it will attack without ever changing the direction it’s facing.',
		'species_id': 525,
	},
	526: {
		'description': 'Although its energy blasts can blow away a dump truck, they have a limitation— they can only be fired when the sun is out.',
		'species_id': 526,
	},
	527: {
		'description': 'It emits ultrasonic waves as it flutters about, searching for its prey—bug Pokémon.',
		'species_id': 527,
	},
	528: {
		'description': 'The auspicious shape of this Pokémon’s nose apparently led some regions to consider Swoobat a symbol of good luck.',
		'species_id': 528,
	},
	529: {
		'description': 'It’s a digger, using its claws to burrow through the ground. It causes damage to vegetable crops, so many farmers have little love for it.',
		'species_id': 529,
	},
	530: {
		'description': 'Known as the Drill King, this Pokémon can tunnel through the terrain at speeds of over 90 mph.',
		'species_id': 530,
	},
	531: {
		'description': 'It touches others with the feelers on its ears, using the sound of their heartbeats to tell how they are feeling.',
		'species_id': 531,
	},
	532: {
		'description': 'Timburr that have started carrying logs that are about three times their size are nearly ready to evolve.',
		'species_id': 532,
	},
	533: {
		'description': 'Gurdurr excels at demolition—construction is not its forte. In any case, there’s skill in the way this Pokémon wields its metal beam.',
		'species_id': 533,
	},
	534: {
		'description': 'When going all out, this Pokémon throws aside its concrete pillars and leaps at opponents to pummel them with its fists.',
		'species_id': 534,
	},
	535: {
		'description': 'It uses sound waves to communicate with others of its kind. People and other Pokémon species can’t hear its cries of warning.',
		'species_id': 535,
	},
	536: {
		'description': 'On occasion, their cries are sublimely pleasing to the ear. Palpitoad with larger lumps on their bodies can sing with a wider range of sounds.',
		'species_id': 536,
	},
	537: {
		'description': 'This Pokémon is popular among the elderly, who say the vibrations of its lumps are great for massages.',
		'species_id': 537,
	},
	538: {
		'description': 'They train in groups of five. Any member that can’t keep up will discard its belt and leave the group.',
		'species_id': 538,
	},
	539: {
		'description': 'The karate chops of a Sawk that’s trained itself to the limit can cleave the ocean itself.',
		'species_id': 539,
	},
	540: {
		'description': 'Since this Pokémon makes its own clothes out of leaves, it is a popular mascot for fashion designers.',
		'species_id': 540,
	},
	541: {
		'description': 'It protects itself from the cold by wrapping up in leaves. It stays on the move, eating leaves in forests.',
		'species_id': 541,
	},
	542: {
		'description': 'It keeps its eggs warm with heat from fermenting leaves. It also uses leaves to make warm wrappings for Sewaddle.',
		'species_id': 542,
	},
	543: {
		'description': 'Its fangs are highly venomous. If this Pokémon finds prey it thinks it can eat, it leaps for them without any thought of how things might turn out.',
		'species_id': 543,
	},
	544: {
		'description': 'Whirlipede protects itself with a sturdy shell and poisonous spikes while it stores up the energy it’ll need for evolution.',
		'species_id': 544,
	},
	545: {
		'description': 'Scolipede engage in fierce territorial battles with Centiskorch. At the end of one of these battles, the victor makes a meal of the loser.',
		'species_id': 545,
	},
	546: {
		'description': 'Weaving together the cotton of both Cottonee and Eldegoss produces exquisite cloth that’s highly prized by many luxury brands.',
		'species_id': 546,
	},
	547: {
		'description': 'As long as this Pokémon bathes in sunlight, its cotton keeps growing. If too much cotton fluff builds up, Whimsicott tears it off and scatters it.',
		'species_id': 547,
	},
	548: {
		'description': 'The deeper the color of a Petilil’s leaves, the healthier the Pokémon is. Petilil sometimes makes its home in a well-tended field or flowerbed.',
		'species_id': 548,
	},
	549: {
		'description': 'Essential oils made from Lilligant flowers have a sublime scent, but they’re also staggeringly expensive.',
		'species_id': 549,
	},
	550: {
		'description': 'In the past, it often appeared on the dinner table. The meat of red-striped Basculin is on the fatty side, and it’s more popular with the youth.',
		'species_id': 550,
	},
	551: {
		'description': 'Sandile is small, but its legs and lower body are powerful. Pushing sand aside as it goes, Sandile moves through the desert as if it’s swimming.',
		'species_id': 551,
	},
	552: {
		'description': 'Although this Pokémon has specialized eyes that allow it to see in the dark, Krokorok won’t move much at night—the desert gets cold after sunset.',
		'species_id': 552,
	},
	553: {
		'description': 'While terribly aggressive, Krookodile also has the patience to stay hidden under sand for days, lying in wait for prey.',
		'species_id': 553,
	},
	554: {
		'description': 'The colder they get, the more energetic they are. They freeze their breath to make snowballs, using them as ammo for playful snowball fights.',
		'species_id': 554,
	},
	555: {
		'description': 'Darmanitan takes this form when enraged. It won’t stop spewing flames until its rage has settled, even if its body starts to melt.',
		'species_id': 555,
	},
	556: {
		'description': 'Once each year, this Pokémon scatters its seeds. They’re jam-packed with nutrients, making them a precious food source out in the desert.',
		'species_id': 556,
	},
	557: {
		'description': 'It first tries to find a rock to live in, but if there are no suitable rocks to be found, Dwebble may move in to the ports of a Hippowdon.',
		'species_id': 557,
	},
	558: {
		'description': 'Its thick claws are its greatest weapons. They’re mighty enough to crack Rhyperior’s carapace.',
		'species_id': 558,
	},
	559: {
		'description': 'It protects itself with its durable skin. It’s thought that this Pokémon will evolve once its skin has completely stretched out.',
		'species_id': 559,
	},
	560: {
		'description': 'While mostly known for having the temperament of an aggressive ruffian, this Pokémon takes very good care of its family, friends, and territory.',
		'species_id': 560,
	},
	561: {
		'description': 'A discovery was made in the desert where Sigilyph fly. The ruins of what may have been an ancient city were found beneath the sands.',
		'species_id': 561,
	},
	562: {
		'description': 'It’s said that this Pokémon was formed when an ancient clay tablet was drawn to a vengeful spirit.',
		'species_id': 562,
	},
	563: {
		'description': 'There are many depictions of Cofagrigus decorating ancient tombs. They’re symbols of the wealth that kings of bygone eras had.',
		'species_id': 563,
	},
	564: {
		'description': 'Its hunting grounds encompassed a broad area, from the land to more than half a mile deep in the ocean.',
		'species_id': 564,
	},
	565: {
		'description': 'Carracosta eats every last bit of the prey it catches, even the shells and bones, to further strengthen its sturdy shell.',
		'species_id': 565,
	},
	566: {
		'description': 'Restored from a fossil, this ancient bird Pokémon has wings but can’t yet fly.',
		'species_id': 566,
	},
	567: {
		'description': 'Said to be an ancestor of bird Pokémon, the muscles it uses to flap its wings are still weak, so it needs a long runway in order to take off.',
		'species_id': 567,
	},
	568: {
		'description': 'This Pokémon was born from a bag stuffed with trash. Galarian Weezing relish the fumes belched by Trubbish.',
		'species_id': 568,
	},
	569: {
		'description': 'It sprays toxic gas from its mouth and fingers. If the gas engulfs you, the toxins will seep in all the way down to your bones.',
		'species_id': 569,
	},
	570: {
		'description': 'Zorua sometimes transforms into a person and goes into cities to search for food. When Zorua does this, it usually takes on the form of a child.',
		'species_id': 570,
	},
	571: {
		'description': 'Seeking to ease the burden of their solitude, lonely Trainers tell Zoroark to show illusions to them.',
		'species_id': 571,
	},
	572: {
		'description': 'They pet each other with their tails as a form of greeting. Of the two, the one whose tail is fluffier is a bit more boastful.',
		'species_id': 572,
	},
	573: {
		'description': 'A special oil that seeps through their fur helps them avoid attacks. The oil fetches a high price at market.',
		'species_id': 573,
	},
	574: {
		'description': 'Even when nobody seems to be around, Gothita can still be heard making a muted cry. Many believe it’s speaking to something only it can see.',
		'species_id': 574,
	},
	575: {
		'description': 'On nights when the stars shine, this Pokémon’s psychic power is at its strongest. It’s unknown just what link Gothorita has to the greater universe.',
		'species_id': 575,
	},
	576: {
		'description': 'A criminal who was shown his fate by a Gothitelle went missing that same day and was never seen again.',
		'species_id': 576,
	},
	577: {
		'description': 'Many say that the special liquid covering this Pokémon’s body would allow it to survive in the vacuum of space.',
		'species_id': 577,
	},
	578: {
		'description': 'Its brain has split into two, and the two halves rarely think alike. Its actions are utterly unpredictable.',
		'species_id': 578,
	},
	579: {
		'description': 'It’s said that drinking the liquid surrounding Reuniclus grants wisdom. Problem is, the liquid is highly toxic to anything besides Reuniclus itself.',
		'species_id': 579,
	},
	580: {
		'description': 'They are better at swimming than flying, and they happily eat their favorite food, peat moss, as they dive underwater.',
		'species_id': 580,
	},
	581: {
		'description': 'Swanna start to dance at dusk. The one dancing in the middle is the leader of the flock.',
		'species_id': 581,
	},
	582: {
		'description': 'Supposedly, this Pokémon was born from an icicle. It spews out freezing air at −58 degrees Fahrenheit to make itself more comfortable.',
		'species_id': 582,
	},
	583: {
		'description': 'It blasts enemies with cold air reaching −148 degrees Fahrenheit, freezing them solid. But it spares their lives afterward—it’s a kind Pokémon.',
		'species_id': 583,
	},
	584: {
		'description': 'People believe this Pokémon formed when two Vanillish stuck together. Its body temperature is roughly 21 degrees Fahrenheit.',
		'species_id': 584,
	},
	585: {
		'description': 'The turning of the seasons changes the color and scent of this Pokémon’s fur. People use it to mark the seasons.',
		'species_id': 585,
	},
	586: {
		'description': 'They migrate according to the seasons, so some people call Sawsbuck the harbingers of spring.',
		'species_id': 586,
	},
	587: {
		'description': 'This Pokémon absolutely loves sweet berries. Sometimes it stuffs its cheeks full of so much food that it can’t fly properly.',
		'species_id': 587,
	},
	588: {
		'description': 'It spits a liquid from its mouth to melt through Shelmet’s shell. Karrablast doesn’t eat the shell— it eats only the contents.',
		'species_id': 588,
	},
	589: {
		'description': 'It charges its enemies, lances at the ready. An image of one of its duels is captured in a famous painting of Escavalier clashing with Sirfetch’d.',
		'species_id': 589,
	},
	590: {
		'description': 'The spores released from this Pokémon’s hands are highly poisonous, but when thoroughly dried, the spores can be used as stomach medicine.',
		'species_id': 590,
	},
	591: {
		'description': 'Amoonguss generally doesn’t move much. It tends to stand still near Poké Balls that have been dropped on the ground.',
		'species_id': 591,
	},
	592: {
		'description': 'Its thin, veillike arms have tens of thousands of poisonous stingers. Females have slightly longer stingers.',
		'species_id': 592,
	},
	593: {
		'description': 'The crown on its head gets bigger and bigger as it absorbs more and more of the life-force of other creatures.',
		'species_id': 593,
	},
	594: {
		'description': 'The reason it helps Pokémon in a weakened condition is that any Pokémon coming after them may also attack Alomomola.',
		'species_id': 594,
	},
	595: {
		'description': 'Joltik latch on to other Pokémon and suck out static electricity. They’re often found sticking to Yamper’s hindquarters.',
		'species_id': 595,
	},
	596: {
		'description': 'It lays traps of electrified threads near the nests of bird Pokémon, aiming to snare chicks that are not yet good at flying.',
		'species_id': 596,
	},
	597: {
		'description': 'Mossy caves are their preferred dwellings. Enzymes contained in mosses help Ferroseed’s spikes grow big and strong.',
		'species_id': 597,
	},
	598: {
		'description': 'Its spikes are harder than steel. This Pokémon crawls across rock walls by stabbing the spikes on its feelers into the stone.',
		'species_id': 598,
	},
	599: {
		'description': 'It’s suspected that Klink were the inspiration behind ancient people’s invention of the first gears.',
		'species_id': 599,
	},
	600: {
		'description': 'Many companies in the Galar region choose Klang as their logo. This Pokémon is considered the symbol of industrial technology.',
		'species_id': 600,
	},
	601: {
		'description': 'The three gears that compose this Pokémon spin at high speed. Its new spiked gear isn’t a living creature.',
		'species_id': 601,
	},
	602: {
		'description': 'One alone can emit only a trickle of electricity, so a group of them gathers to unleash a powerful electric shock.',
		'species_id': 602,
	},
	603: {
		'description': 'These Pokémon have a big appetite. When they spot their prey, they attack it and paralyze it with electricity.',
		'species_id': 603,
	},
	604: {
		'description': 'They crawl out of the ocean using their arms. They will attack prey on shore and immediately drag it into the ocean.',
		'species_id': 604,
	},
	605: {
		'description': 'This Pokémon was discovered about 50 years ago. Its highly developed brain enables it to exert its psychic powers.',
		'species_id': 605,
	},
	606: {
		'description': 'Sometimes found drifting above wheat fields, this Pokémon can control the memories of its opponents.',
		'species_id': 606,
	},
	607: {
		'description': 'The younger the life this Pokémon absorbs, the brighter and eerier the flame on its head burns.',
		'species_id': 607,
	},
	608: {
		'description': 'It lurks in cities, pretending to be a lamp. Once it finds someone whose death is near, it will trail quietly after them.',
		'species_id': 608,
	},
	609: {
		'description': 'In homes illuminated by Chandelure instead of lights, funerals were a constant occurrence— or so it’s said.',
		'species_id': 609,
	},
	610: {
		'description': 'They play with each other by knocking their large tusks together. Their tusks break sometimes, but they grow back so quickly that it isn’t a concern.',
		'species_id': 610,
	},
	611: {
		'description': 'Its skin is as hard as a suit of armor. Fraxure’s favorite strategy is to tackle its opponents, stabbing them with its tusks at the same time.',
		'species_id': 611,
	},
	612: {
		'description': 'While usually kindhearted, it can be terrifying if angered. Tusks that can slice through steel beams are how Haxorus deals with its adversaries.',
		'species_id': 612,
	},
	613: {
		'description': 'It sniffles before performing a move, using its frosty snot to provide an icy element to any move that needs it.',
		'species_id': 613,
	},
	614: {
		'description': 'It swims energetically through frigid seas. When it gets tired, it freezes the seawater with its breath so it can rest on the ice.',
		'species_id': 614,
	},
	615: {
		'description': 'They are composed of ice crystals. They capture prey with chains of ice, freezing the prey at -148 degrees Fahrenheit.',
		'species_id': 615,
	},
	616: {
		'description': 'It has a strange physiology that responds to electricity. When together with Karrablast, Shelmet evolves for some reason.',
		'species_id': 616,
	},
	617: {
		'description': 'Discarding its shell made it nimble. To keep itself from dehydrating, it wraps its body in bands of membrane.',
		'species_id': 617,
	},
	618: {
		'description': 'For some reason, this Pokémon smiles slightly when it emits a strong electric current from the yellow markings on its body.',
		'species_id': 618,
	},
	619: {
		'description': 'Though small, Mienfoo’s temperament is fierce. Any creature that approaches Mienfoo carelessly will be greeted with a flurry of graceful attacks.',
		'species_id': 619,
	},
	620: {
		'description': 'Delivered at blinding speeds, kicks from this Pokémon can shatter massive boulders into tiny pieces.',
		'species_id': 620,
	},
	621: {
		'description': 'Druddigon are vicious and cunning. They take up residence in nests dug out by other Pokémon, treating the stolen nests as their own lairs.',
		'species_id': 621,
	},
	622: {
		'description': 'This Pokémon was created from clay. It received orders from its master many thousands of years ago, and it still follows those orders to this day.',
		'species_id': 622,
	},
	623: {
		'description': 'There’s a theory that inside Golurk is a perpetual motion machine that produces limitless energy, but this belief hasn’t been proven.',
		'species_id': 623,
	},
	624: {
		'description': 'A pack of these Pokémon forms to serve a Bisharp boss. Each Pawniard trains diligently, dreaming of one day taking the lead.',
		'species_id': 624,
	},
	625: {
		'description': 'Violent conflicts erupt between Bisharp and Fraxure over places where sharpening stones can be found.',
		'species_id': 625,
	},
	626: {
		'description': 'These Pokémon live in herds of about 20 individuals. Bouffalant that betray the herd will lose the hair on their heads for some reason.',
		'species_id': 626,
	},
	627: {
		'description': 'A combative Pokémon, it’s ready to pick a fight with anyone. It has talons that can crush hard berries.',
		'species_id': 627,
	},
	628: {
		'description': 'Because this Pokémon is hotheaded and belligerent, it’s Corviknight that’s taken the role of transportation in Galar.',
		'species_id': 628,
	},
	629: {
		'description': 'Vullaby grow quickly. Bones that have gotten too small for older Vullaby to wear often get passed down to younger ones in the nest.',
		'species_id': 629,
	},
	630: {
		'description': 'They adorn themselves with bones. There seem to be fashion trends among them, as different bones come into and fall out of popularity.',
		'species_id': 630,
	},
	631: {
		'description': 'A flame serves as its tongue, melting through the hard shell of Durant so that Heatmor can devour their insides.',
		'species_id': 631,
	},
	632: {
		'description': 'With their large mandibles, these Pokémon can crunch their way through rock. They work together to protect their eggs from Sandaconda.',
		'species_id': 632,
	},
	633: {
		'description': 'Because it can’t see, this Pokémon is constantly biting at everything it touches, trying to keep track of its surroundings.',
		'species_id': 633,
	},
	634: {
		'description': 'Their two heads will fight each other over a single piece of food. Zweilous are covered in scars even without battling others.',
		'species_id': 634,
	},
	635: {
		'description': 'The three heads take turns sinking their teeth into the opponent. Their attacks won’t slow until their target goes down.',
		'species_id': 635,
	},
	636: {
		'description': 'Larvesta’s body is warm all over. It spouts fire from the tips of its horns to intimidate predators and scare prey.',
		'species_id': 636,
	},
	637: {
		'description': 'This Pokémon emerges from a cocoon formed of raging flames. Ancient murals depict Volcarona as a deity of fire.',
		'species_id': 637,
	},
	638: {
		'description': 'It has a body and heart of steel. It worked with its allies to punish people when they hurt Pokémon.',
		'species_id': 638,
	},
	639: {
		'description': 'Spoken of in legend, this Pokémon used its phenomenal power to destroy a castle in its effort to protect Pokémon.',
		'species_id': 639,
	},
	640: {
		'description': 'Legends say this Pokémon confounded opponents with its swift movements.',
		'species_id': 640,
	},
	641: {
		'description': 'Tornadus expels massive energy from its tail, causing severe storms. Its power is great enough to blow houses away.',
		'species_id': 641,
	},
	642: {
		'description': 'As it flies around, it shoots lightning all over the place and causes forest fires. It is therefore disliked.',
		'species_id': 642,
	},
	643: {
		'description': 'According to myth, if people ignore truth and let themselves become consumed by greed, Reshiram will arrive to burn their kingdoms down.',
		'species_id': 643,
	},
	644: {
		'description': 'Mythology tells us that if people lose the righteousness in their hearts, their kingdoms will be razed by Zekrom’s lightning.',
		'species_id': 644,
	},
	645: {
		'description': 'From the forces of lightning and wind, it creates energy to give nutrients to the soil and make the land abundant.',
		'species_id': 645,
	},
	646: {
		'description': 'It appears that this Pokémon uses its powers over ice to freeze its own body in order to stabilize its cellular structure.',
		'species_id': 646,
	},
	647: {
		'description': 'When it is resolute, its body fills with power and it becomes swifter. Its jumps are then too fast to follow.',
		'species_id': 647,
	},
	648: {
		'description': 'Its melodies are sung with a special vocalization method that can control the feelings of those who hear it.',
		'species_id': 648,
	},
	649: {
		'description': 'This Pokémon existed 300 million years ago. Team Plasma altered it and attached a cannon to its back.',
		'species_id': 649,
	},
	650: {
		'description': 'Such a thick shell of wood covers its head and back that even a direct hit from a truck wouldn’t faze it.',
		'species_id': 650,
	},
	651: {
		'description': 'They strengthen their lower bodies by running into one another. They are very kind and won’t start fights.',
		'species_id': 651,
	},
	652: {
		'description': 'When it takes a defensive posture with its fists guarding its face, it could withstand a bomb blast.',
		'species_id': 652,
	},
	653: {
		'description': 'As it walks, it munches on a twig in place of a snack. It intimidates opponents by puffing hot air out of its ears.',
		'species_id': 653,
	},
	654: {
		'description': 'When the twig is plucked from its tail, friction sets the twig alight. The flame is used to send signals to its allies.',
		'species_id': 654,
	},
	655: {
		'description': 'Using psychic power, it generates a fiery vortex of 5,400 degrees Fahrenheit, incinerating foes swept into this whirl of flame.',
		'species_id': 655,
	},
	656: {
		'description': 'It protects its skin by covering its body in delicate bubbles. Beneath its happy-go-lucky air, it keeps a watchful eye on its surroundings.',
		'species_id': 656,
	},
	657: {
		'description': 'Its swiftness is unparalleled. It can scale a tower of more than 2,000 feet in a minute’s time.',
		'species_id': 657,
	},
	658: {
		'description': 'It appears and vanishes with a ninja’s grace. It toys with its enemies using swift movements, while slicing them with throwing stars of sharpest water.',
		'species_id': 658,
	},
	659: {
		'description': 'It’s very sensitive to danger. The sound of Corviknight’s flapping will have Bunnelby digging a hole to hide underground in moments.',
		'species_id': 659,
	},
	660: {
		'description': 'The fur on its belly retains heat exceptionally well. People used to make heavy winter clothing from fur shed by this Pokémon.',
		'species_id': 660,
	},
	661: {
		'description': 'When this Pokémon gets excited, its body temperature increases sharply. If you touch a Fletchling with bare hands, you might get burned.',
		'species_id': 661,
	},
	662: {
		'description': 'Fletchinder are exceedingly territorial and aggressive. These Pokémon fight among themselves over feeding grounds.',
		'species_id': 662,
	},
	663: {
		'description': 'Talonflame dives toward prey at speeds of up to 310 mph and assaults them with powerful kicks, giving the prey no chance to escape.',
		'species_id': 663,
	},
	664: {
		'description': 'The powder that covers its body regulates its temperature, so it can live in any region or climate.',
		'species_id': 664,
	},
	665: {
		'description': 'The beaks of bird Pokémon can’t begin to scratch its stalwart body. To defend itself, it spews powder.',
		'species_id': 665,
	},
	666: {
		'description': 'The patterns on this Pokémon’s wings depend on the climate and topography of its habitat. It scatters colorful scales.',
		'species_id': 666,
	},
	667: {
		'description': 'This hot-blooded Pokémon is filled with curiosity. When it gets angry or starts fighting, its short mane gets hot.',
		'species_id': 667,
	},
	668: {
		'description': 'The temperature of its breath is over 10,000 degrees Fahrenheit, but Pyroar doesn’t use it on its prey. This Pokémon prefers to eat raw meat.',
		'species_id': 668,
	},
	669: {
		'description': 'Flabébé wears a crown made from pollen it’s collected from its flower. The crown has hidden healing properties.',
		'species_id': 669,
	},
	670: {
		'description': 'It gives its own power to flowers, pouring its heart into caring for them. Floette never forgives anyone who messes up a flower bed.',
		'species_id': 670,
	},
	671: {
		'description': 'Its life can span several hundred years. It’s said to devote its entire life to protecting gardens.',
		'species_id': 671,
	},
	672: {
		'description': 'If it has sunshine and water, it doesn’t need to eat, because it can generate energy from the leaves on its back.',
		'species_id': 672,
	},
	673: {
		'description': 'They inhabit mountainous regions. The leader of the herd is decided by a battle of clashing horns.',
		'species_id': 673,
	},
	674: {
		'description': 'Wanting to make sure it’s taken seriously, Pancham’s always giving others a glare. But if it’s not focusing, it ends up smiling.',
		'species_id': 674,
	},
	675: {
		'description': 'Using its leaf, Pangoro can predict the moves of its opponents. It strikes with punches that can turn a dump truck into scrap with just one hit.',
		'species_id': 675,
	},
	676: {
		'description': 'Left alone, its fur will grow longer and longer, but it will only allow someone it trusts to cut it.',
		'species_id': 676,
	},
	677: {
		'description': 'There’s enough psychic power in Espurr to send a wrestler flying, but because this power can’t be controlled, Espurr finds it troublesome.',
		'species_id': 677,
	},
	678: {
		'description': 'If it doesn’t hold back when it unleashes its psychic power, it can tear apart a tanker. Its unfriendliness is part of its charm.',
		'species_id': 678,
	},
	679: {
		'description': 'The blue eye on the sword’s handguard is the true body of Honedge. With its old cloth, it drains people’s lives away.',
		'species_id': 679,
	},
	680: {
		'description': 'The two swords employ a strategy of rapidly alternating between offense and defense to bring down their prey.',
		'species_id': 680,
	},
	681: {
		'description': 'Its potent spectral powers allow it to manipulate others. It once used its powers to force people and Pokémon to build a kingdom to its liking.',
		'species_id': 681,
	},
	682: {
		'description': 'The scent its body gives off enraptures those who smell it. Noble ladies had no shortage of love for Spritzee.',
		'species_id': 682,
	},
	683: {
		'description': 'The scents Aromatisse can produce range from sweet smells that bolster allies to foul smells that sap an opponent’s will to fight.',
		'species_id': 683,
	},
	684: {
		'description': 'The sweet smell of cotton candy perfumes Swirlix’s fluffy fur. This Pokémon spits out sticky string to tangle up its enemies.',
		'species_id': 684,
	},
	685: {
		'description': 'Slurpuff’s fur contains a lot of air, making it soft to the touch and lighter than it looks.',
		'species_id': 685,
	},
	686: {
		'description': 'By exposing foes to the blinking of its luminescent spots, Inkay demoralizes them, and then it seizes the chance to flee.',
		'species_id': 686,
	},
	687: {
		'description': 'It’s said that Malamar’s hypnotic powers played a role in certain history-changing events.',
		'species_id': 687,
	},
	688: {
		'description': 'If the two don’t work well together, both their offense and defense fall apart. Without good teamwork, they won’t survive.',
		'species_id': 688,
	},
	689: {
		'description': 'Having an eye on each palm allows it to keep watch in all directions. In a pinch, its limbs start to act on their own to ensure the enemy’s defeat.',
		'species_id': 689,
	},
	690: {
		'description': 'Skrelp looks like a piece of rotten seaweed, so it can blend in with seaweed drifting on the ocean and avoid being detected by enemies.',
		'species_id': 690,
	},
	691: {
		'description': 'Dragalge generates dragon energy by sticking the plume on its head out above the ocean’s surface and bathing it in sunlight.',
		'species_id': 691,
	},
	692: {
		'description': 'By detonating gas that accumulates in its right claw, this Pokémon launches water like a bullet. This is how Clauncher defeats its enemies.',
		'species_id': 692,
	},
	693: {
		'description': 'Clawitzer’s right arm is a cannon that launches projectiles made of seawater. Shots from a Clawitzer’s cannon arm can sink a tanker.',
		'species_id': 693,
	},
	694: {
		'description': 'The sun powers this Pokémon’s electricity generation. Interruption of that process stresses Helioptile to the point of weakness.',
		'species_id': 694,
	},
	695: {
		'description': 'One Heliolisk basking in the sun with its frill outspread is all it would take to produce enough electricity to power a city.',
		'species_id': 695,
	},
	696: {
		'description': 'Tyrunt is spoiled and selfish. It may just be trying to frolic, but sometimes the ones it’s trying to frolic with are gravely injured.',
		'species_id': 696,
	},
	697: {
		'description': 'The king of the ancient world, it can easily crunch a car with the devastating strength of its enormous jaws.',
		'species_id': 697,
	},
	698: {
		'description': 'Amaura was restored successfully, but it’s not expected to live long because of the heat of the current environment.',
		'species_id': 698,
	},
	699: {
		'description': 'This usually quiet and kindly Pokémon has a surface temperature of around –240 degrees Fahrenheit.',
		'species_id': 699,
	},
	700: {
		'description': 'There’s a Galarian fairy tale that describes a beautiful Sylveon vanquishing a dreadful dragon Pokémon.',
		'species_id': 700,
	},
	701: {
		'description': 'It always strikes a pose before going for its finishing move. Sometimes opponents take advantage of that time to counterattack.',
		'species_id': 701,
	},
	702: {
		'description': 'Since Dedenne can’t generate much electricity on its own, it steals electricity from outlets or other electric Pokémon.',
		'species_id': 702,
	},
	703: {
		'description': 'To keep the jewels on their bodies free of tarnish, packs of Carbink use their soft manes to polish one another’s jewels.',
		'species_id': 703,
	},
	704: {
		'description': 'Their horns are powerful sensors. As soon as Goomy pick up any sign of enemies, they go into hiding. This is how they’ve survived.',
		'species_id': 704,
	},
	705: {
		'description': 'The lump on its back contains its tiny brain. It thinks only of food and escaping its enemies.',
		'species_id': 705,
	},
	706: {
		'description': 'Its form of offense is forcefully stretching out its horns. The strikes land 100 times harder than any blow from a heavyweight boxer.',
		'species_id': 706,
	},
	707: {
		'description': 'Klefki sucks in metal ions with the horn topping its head. It seems this Pokémon loves keys so much that its head needed to look like one, too.',
		'species_id': 707,
	},
	708: {
		'description': 'With a voice like a human child’s, it cries out to lure adults deep into the forest, getting them lost among the trees.',
		'species_id': 708,
	},
	709: {
		'description': 'Small roots that extend from the tips of this Pokémon’s feet can tie into the trees of the forest and give Trevenant control over them.',
		'species_id': 709,
	},
	710: {
		'description': 'Large Pumpkaboo are said to be the product of areas where many lost souls lingered.',
		'species_id': 710,
	},
	711: {
		'description': 'A large-sized Pumpkaboo evolves into a large-sized Gourgeist. Its bodily proportions also get passed on to its descendants.',
		'species_id': 711,
	},
	712: {
		'description': 'This Pokémon lives in areas of frigid cold. It secures itself to the back of an Avalugg by freezing its feet in place.',
		'species_id': 712,
	},
	713: {
		'description': 'As Avalugg moves about during the day, the cracks in its body deepen. The Pokémon’s body returns to a pristine state overnight.',
		'species_id': 713,
	},
	714: {
		'description': 'No wavelength of sound is beyond Noibat’s ability to produce. The ultrasonic waves it generates can overcome much larger Pokémon.',
		'species_id': 714,
	},
	715: {
		'description': 'Flying through the darkness, it weakens enemies with ultrasonic waves that could crush stone. Its fangs finish the fight.',
		'species_id': 715,
	},
	716: {
		'description': 'When the horns on its head shine in seven colors, it is said to be sharing everlasting life.',
		'species_id': 716,
	},
	717: {
		'description': 'When its life comes to an end, it absorbs the life energy of every living thing and turns into a cocoon once more.',
		'species_id': 717,
	},
	718: {
		'description': 'Some say it can change to an even more powerful form when battling those who threaten the ecosystem.',
		'species_id': 718,
	},
	719: {
		'description': 'It can instantly create many diamonds by compressing the carbon in the air between its hands.',
		'species_id': 719,
	},
	720: {
		'description': 'It is said to be able to seize anything it desires with its six rings and six huge arms. With its power sealed, it is transformed into a much smaller form.',
		'species_id': 720,
	},
	721: {
		'description': 'It expels its internal steam from the arms on its back. It has enough power to blow away a mountain.',
		'species_id': 721,
	},
	722: {
		'description': 'During the day, it builds up energy via photosynthesis. At night, it flies silently through the sky, on the prowl for prey.',
		'species_id': 722,
	},
	723: {
		'description': 'It never slacks when it comes to the task of cleaning its feathers. Thorough preening keeps it looking spiffy and its blade quills nice and sharp.',
		'species_id': 723,
	},
	724: {
		'description': 'In a tenth of a second, it can nock and fire an arrow quill, piercing an opponent’s weak point before they notice what’s happening.',
		'species_id': 724,
	},
	725: {
		'description': 'Trying to pet Litten before it trusts you will result in a nasty scratch from its sharp claws. Be careful.',
		'species_id': 725,
	},
	726: {
		'description': 'When facing a powerful enemy, Torracat’s fighting spirit gets pumped up, and its fire bell blazes hotter.',
		'species_id': 726,
	},
	727: {
		'description': 'Incineroar’s rough and aggressive behavior is its most notable trait, but the way it helps out small Pokémon shows that it has a kind side as well.',
		'species_id': 727,
	},
	728: {
		'description': 'If Popplio want to create big, powerful balloons, they must be persistent. It takes daily practice for these Pokémon to develop their skills.',
		'species_id': 728,
	},
	729: {
		'description': 'On nights when the sea is calm, Brionne dance with one another to the singing of the Primarina that’s leading them.',
		'species_id': 729,
	},
	730: {
		'description': 'For Primarina, every battle’s a stage. Its singing and the dancing of its balloons will mesmerize the audience.',
		'species_id': 730,
	},
	731: {
		'description': 'It may look spindly, but its neck muscles are heavy-duty. It can peck at a tree 16 times per second!',
		'species_id': 731,
	},
	732: {
		'description': 'From its mouth, it fires the seeds of berries it has eaten. The scattered seeds give rise to new plants.',
		'species_id': 732,
	},
	733: {
		'description': 'Known for forming harmonious couples, this Pokémon is brought to wedding ceremonies as a good luck charm.',
		'species_id': 733,
	},
	734: {
		'description': 'Although it will eat anything, it prefers fresh living things, so it marches down streets in search of prey.',
		'species_id': 734,
	},
	735: {
		'description': 'Patient by nature, this Pokémon loses control of itself and pounces when it spots its favorite meal—Rattata!',
		'species_id': 735,
	},
	736: {
		'description': 'It uses its big jaws to dig nests into the forest floor, and it loves to feed on sweet tree sap.',
		'species_id': 736,
	},
	737: {
		'description': 'Its digestive processes convert the leaves it eats into electricity. An electric sac in its belly stores the electricity for later use.',
		'species_id': 737,
	},
	738: {
		'description': 'If it carries a Charjabug to use as a spare battery, a flying Vikavolt can rapidly fire high-powered beams of electricity.',
		'species_id': 738,
	},
	739: {
		'description': 'Crabrawler has been known to mistake Exeggutor for a coconut tree and climb it. The enraged Exeggutor shakes it off and stomps it.',
		'species_id': 739,
	},
	740: {
		'description': 'Before it stops to think, it just starts pummeling. There are records of its turning back avalanches with a flurry of punches.',
		'species_id': 740,
	},
	741: {
		'description': 'This Oricorio has drunk red nectar. If its Trainer gives the wrong order, this passionate Pokémon becomes fiercely angry.',
		'species_id': 741,
	},
	742: {
		'description': 'An opponent’s aura can tell Cutiefly what that opponent’s next move will be. Then Cutiefly can glide around the attack and strike back.',
		'species_id': 742,
	},
	743: {
		'description': 'Ribombee absolutely hate getting wet or rained on. In the cloudy Galar region, they are very seldom seen.',
		'species_id': 743,
	},
	744: {
		'description': 'This Pokémon intimidates opponents by striking the ground with the rocks on its neck. The moment an opponent flinches, Rockruff attacks.',
		'species_id': 744,
	},
	745: {
		'description': 'It’s invigorated by powerful opponents—the stronger the better. A full-force headbutt from one of these Lycanroc can shatter giant boulders.',
		'species_id': 745,
	},
	746: {
		'description': 'When facing tough opponents, they get into formation. But if they get wounded in battle, they’ll scatter and become solitary again.',
		'species_id': 746,
	},
	747: {
		'description': 'Unlike their Alolan counterparts, the Mareanie of the Galar region have not yet figured out that the branches of Corsola are delicious.',
		'species_id': 747,
	},
	748: {
		'description': 'Within the poison sac in its body is a poison so toxic that Pokémon as large as Wailord will still be suffering three days after it first takes effect.',
		'species_id': 748,
	},
	749: {
		'description': 'It eats dirt to create mud and smears this mud all over its feet, giving them the grip needed to walk on rough terrain without slipping.',
		'species_id': 749,
	},
	750: {
		'description': 'Mudsdale has so much stamina that it could carry over 10 tons across the Galar region without rest or sleep.',
		'species_id': 750,
	},
	751: {
		'description': 'Dewpider normally lives underwater. When it comes onto land in search of food, it takes water with it in the form of a bubble on its head.',
		'species_id': 751,
	},
	752: {
		'description': 'It acts as a caretaker for Dewpider, putting them inside its bubble and letting them eat any leftover food.',
		'species_id': 752,
	},
	753: {
		'description': 'During the day, Fomantis basks in sunlight and sleeps peacefully. It wakes and moves around at night.',
		'species_id': 753,
	},
	754: {
		'description': 'The petals on this Pokémon’s arms are thin and super sharp, and they can fire laser beams if Lurantis gathers light first.',
		'species_id': 754,
	},
	755: {
		'description': 'Morelull live in forests that stay dark even during the day. They scatter flickering spores that put enemies to sleep.',
		'species_id': 755,
	},
	756: {
		'description': 'If you see a light deep in a forest at night, don’t go near. Shiinotic will make you fall fast asleep.',
		'species_id': 756,
	},
	757: {
		'description': 'This sneaky Pokémon will slink behind its prey and immobilize it with poisonous gas before the prey even realizes Salandit is there.',
		'species_id': 757,
	},
	758: {
		'description': 'The winner of competitions between Salazzle is decided by which one has the most male Salandit with it.',
		'species_id': 758,
	},
	759: {
		'description': 'The way it protects itself by flailing its arms may be an adorable sight, but stay well away. This is flailing that can snap thick tree trunks.',
		'species_id': 759,
	},
	760: {
		'description': 'The moves it uses to take down its prey would make a martial artist jealous. It tucks subdued prey under its arms to carry them to its nest.',
		'species_id': 760,
	},
	761: {
		'description': 'When under attack, it secretes a sweet and delicious sweat. The scent only calls more enemies to it.',
		'species_id': 761,
	},
	762: {
		'description': 'Any Corvisquire that pecks at this Pokémon will be greeted with a smack from its sepals followed by a sharp kick.',
		'species_id': 762,
	},
	763: {
		'description': 'A kick from the hardened tips of this Pokémon’s legs leaves a wound in the opponent’s body and soul that will never heal.',
		'species_id': 763,
	},
	764: {
		'description': 'These Pokémon smell very nice. All Comfey wear different flowers, so each of these Pokémon has its own individual scent.',
		'species_id': 764,
	},
	765: {
		'description': 'It knows the forest inside and out. If it comes across a wounded Pokémon, Oranguru will gather medicinal herbs to treat it.',
		'species_id': 765,
	},
	766: {
		'description': 'Passimian live in groups of about 20, with each member performing an assigned role. Through cooperation, the group survives.',
		'species_id': 766,
	},
	767: {
		'description': 'Wimpod gather in swarms, constantly on the lookout for danger. They scatter the moment they detect an enemy’s presence.',
		'species_id': 767,
	},
	768: {
		'description': 'They live in sunken ships or in holes in the seabed. When Golisopod and Grapploct battle, the loser becomes the winner’s meal.',
		'species_id': 768,
	},
	769: {
		'description': 'Sandygast mainly inhabits beaches. It takes control of anyone who puts their hand into its mouth, forcing them to make its body bigger.',
		'species_id': 769,
	},
	770: {
		'description': 'This Pokémon lives on beaches, but it hates water. Palossand can’t maintain its castle-like shape if it gets drenched by a heavy rain.',
		'species_id': 770,
	},
	771: {
		'description': 'It’s covered in a slime that keeps its skin moist, allowing it to stay on land for days without drying up.',
		'species_id': 771,
	},
	772: {
		'description': 'It was modeled after a mighty Pokémon of myth. The mask placed upon it limits its power in order to keep it under control.',
		'species_id': 772,
	},
	773: {
		'description': 'It has the ability to change its type based on the memory it’s holding. This is its Bug-type form.',
		'species_id': 773,
	},
	774: {
		'description': 'Although its outer shell is uncommonly durable, the shock of falling to the ground smashes the shell to smithereens.',
		'species_id': 774,
	},
	775: {
		'description': 'It remains asleep from birth to death as a result of the sedative properties of the leaves that form its diet.',
		'species_id': 775,
	},
	776: {
		'description': 'Eating sulfur in its volcanic habitat is what causes explosive compounds to develop in its shell. Its droppings are also dangerously explosive.',
		'species_id': 776,
	},
	777: {
		'description': 'When it’s in trouble, it curls up into a ball, makes its fur spikes stand on end, and then discharges electricity indiscriminately.',
		'species_id': 777,
	},
	778: {
		'description': 'Its disguise made from an old rag allowed it to avoid an attack, but the impact broke the neck of the disguise. Now everyone knows it’s a Mimikyu.',
		'species_id': 778,
	},
	779: {
		'description': 'Its skin is thick enough to fend off Mareanie’s spikes. With its robust teeth, Bruxish crunches up the spikes and eats them.',
		'species_id': 779,
	},
	780: {
		'description': 'Drampa is a kind and friendly Pokémon—up until it’s angered. When that happens, it stirs up a gale and flattens everything around.',
		'species_id': 780,
	},
	781: {
		'description': 'After lowering its anchor, it waits for its prey. It catches large Wailord and drains their life-force.',
		'species_id': 781,
	},
	782: {
		'description': 'Jangmo-o strikes its scales to communicate with others of its kind. Its scales are actually fur that’s become as hard as metal.',
		'species_id': 782,
	},
	783: {
		'description': 'Before attacking its enemies, it clashes its scales together and roars. Its sharp claws shred the opposition.',
		'species_id': 783,
	},
	784: {
		'description': 'Certain ruins have paintings of ancient warriors wearing armor made of Kommo-o scales.',
		'species_id': 784,
	},
	785: {
		'description': 'The lightning-wielding guardian deity of Melemele, Tapu Koko is brimming with curiosity and appears before people from time to time.',
		'species_id': 785,
	},
	786: {
		'description': 'Although called a guardian deity, Tapu Lele is devoid of guilt about its cruel disposition and can be described as nature incarnate.',
		'species_id': 786,
	},
	787: {
		'description': 'It makes ringing sounds with its tail to let others know where it is, avoiding unneeded conflicts. This guardian deity of Ula’ula controls plants.',
		'species_id': 787,
	},
	788: {
		'description': 'Although it’s called a guardian deity, terrible calamities sometimes befall those who recklessly approach Tapu Fini.',
		'species_id': 788,
	},
	789: {
		'description': 'Cosmog is very curious but not very cautious, often placing itself in danger. If things start to look dicey, it teleports away.',
		'species_id': 789,
	},
	790: {
		'description': 'It sucks in dust from the air at an astounding rate, frantically building up energy within its core as preparation for evolution.',
		'species_id': 790,
	},
	791: {
		'description': 'Solgaleo was once known as the Beast That Devours the Sun. Energy in the form of light radiates boundlessly from it.',
		'species_id': 791,
	},
	792: {
		'description': 'It steals the light from its surroundings and then becomes the full moon, showering its own light across the night sky.',
		'species_id': 792,
	},
	793: {
		'description': 'It appeared in this world from an Ultra Wormhole. Nihilego appears to be a parasite that lives by feeding on people and Pokémon.',
		'species_id': 793,
	},
	794: {
		'description': 'Buzzwole goes around showing off its abnormally swollen muscles. It is one kind of Ultra Beast.',
		'species_id': 794,
	},
	795: {
		'description': 'Although it’s alien to this world and a danger here, it’s apparently a common organism in the world where it normally lives.',
		'species_id': 795,
	},
	796: {
		'description': 'They’ve been dubbed Ultra Beasts. Some of them stand unmoving, like trees, with their arms and legs stuck into the ground.',
		'species_id': 796,
	},
	797: {
		'description': 'Although it’s alien to this world and a danger here, it’s apparently a common organism in the world where it normally lives.',
		'species_id': 797,
	},
	798: {
		'description': 'Although it’s alien to this world and a danger here, it’s apparently a common organism in the world where it normally lives.',
		'species_id': 798,
	},
	799: {
		'description': 'An unknown life-form called a UB. It may be constantly hungry—it is certainly always devouring something.',
		'species_id': 799,
	},
	800: {
		'description': 'It needs light to survive, and it goes on a rampage seeking it out. Its laser beams will cut anything to pieces.',
		'species_id': 800,
	},
	801: {
		'description': 'Built roughly 500 years ago by a scientist, the part called the Soul-Heart is the actual life-form.',
		'species_id': 801,
	},
	802: {
		'description': 'This Pokémon can conceal itself in any shadow, so it went undiscovered for a long time.',
		'species_id': 802,
	},
	803: {
		'description': 'An Ultra Beast that lives in a different world, it cackles wildly as it sprays its opponents with poison from the needles on its head.',
		'species_id': 803,
	},
	804: {
		'description': 'One kind of Ultra Beast, it fires a glowing, venomous liquid from its needles. This liquid is also immensely adhesive.',
		'species_id': 804,
	},
	805: {
		'description': 'When stone walls started moving and attacking, the brute’s true identity was this mysterious life-form, which brings to mind an Ultra Beast.',
		'species_id': 805,
	},
	806: {
		'description': 'A UB that appeared from an Ultra Wormhole, it causes explosions, then takes advantage of opponents’ surprise to rob them of their vitality.',
		'species_id': 806,
	},
	807: {
		'description': 'Electricity sparks from the pads on its limbs. Wherever Zeraora runs, lightning flashes and thunder echoes.',
		'species_id': 807,
	},
	808: {
		'description': 'They live as a group, but when the time comes, one strong Meltan will absorb all the others and evolve.',
		'species_id': 808,
	},
	809: {
		'description': 'Centrifugal force is behind the punches of Melmetal’s heavy hex-nut arms. Melmetal is said to deliver the strongest punches of all Pokémon.',
		'species_id': 809,
	},
	810: {
		'description': 'It attacks with rapid beats of its stick. As it strikes with amazing speed, it gets more and more pumped.',
		'species_id': 810,
	},
	811: {
		'description': 'When it’s drumming out rapid beats in battle, it gets so caught up in the rhythm that it won’t even notice that it’s already knocked out its opponent.',
		'species_id': 811,
	},
	812: {
		'description': 'Rillaboom has become one with its forest of drums and continues to lay down beats that shake all of Galar.',
		'species_id': 812,
	},
	813: {
		'description': 'It has special pads on the backs of its feet, and one on its nose. Once it’s raring to fight, these pads radiate tremendous heat.',
		'species_id': 813,
	},
	814: {
		'description': 'It kicks berries right off the branches of trees and then juggles them with its feet, practicing its footwork.',
		'species_id': 814,
	},
	815: {
		'description': 'Gigantamax energy can sometimes cause the diameter of this Pokémon’s fireball to exceed 300 feet.',
		'species_id': 815,
	},
	816: {
		'description': 'When it gets wet, its skin changes color, and this Pokémon becomes invisible as if it were camouflaged.',
		'species_id': 816,
	},
	817: {
		'description': 'Highly intelligent but also very lazy, it keeps enemies out of its territory by laying traps everywhere.',
		'species_id': 817,
	},
	818: {
		'description': 'It has excellent sniping skills. Shooting a berry rolling along over nine miles away is a piece of cake for this Pokémon.',
		'species_id': 818,
	},
	819: {
		'description': 'It eats berries nonstop—a habit that has made it more resilient than it looks. It’ll show up on farms, searching for yet more berries.',
		'species_id': 819,
	},
	820: {
		'description': 'Common throughout the Galar region, this Pokémon has strong teeth and can chew through the toughest of berry shells.',
		'species_id': 820,
	},
	821: {
		'description': 'Jumping nimbly about, this small-bodied Pokémon takes advantage of even the slightest opportunity to disorient larger opponents.',
		'species_id': 821,
	},
	822: {
		'description': 'The lessons of many harsh battles have taught it how to accurately judge an opponent’s strength.',
		'species_id': 822,
	},
	823: {
		'description': 'The eight feathers on its back are called blade birds, and they can launch off its body to attack foes independently.',
		'species_id': 823,
	},
	824: {
		'description': 'Often found in gardens, this Pokémon has hairs on its body that it uses to assess its surroundings.',
		'species_id': 824,
	},
	825: {
		'description': 'As it grows inside its shell, it uses its psychic abilities to monitor the outside world and prepare for evolution.',
		'species_id': 825,
	},
	826: {
		'description': 'If it were to utilize every last bit of its power, it could control the minds of every living being in its vicinity.',
		'species_id': 826,
	},
	827: {
		'description': 'Cunning and cautious, this Pokémon survives by stealing food from others. It erases its tracks with swipes of its tail as it makes off with its plunder.',
		'species_id': 827,
	},
	828: {
		'description': 'With a lithe body and sharp claws, it goes around stealing food and eggs. Boltund is its natural enemy.',
		'species_id': 828,
	},
	829: {
		'description': 'It whirls around in the wind while singing a joyous song. This delightful display has charmed many into raising this Pokémon.',
		'species_id': 829,
	},
	830: {
		'description': 'The cotton on the head of this Pokémon can be spun into a glossy, gorgeous yarn—a Galar regional specialty.',
		'species_id': 830,
	},
	831: {
		'description': 'If its fleece grows too long, Wooloo won’t be able to move. Cloth made with the wool of this Pokémon is surprisingly strong.',
		'species_id': 831,
	},
	832: {
		'description': 'Its majestic horns are meant only to impress the opposite gender. They never see use in battle.',
		'species_id': 832,
	},
	833: {
		'description': 'It starts off battles by attacking with its rock-hard horn, but as soon as the opponent flinches, this Pokémon bites down and never lets go.',
		'species_id': 833,
	},
	834: {
		'description': 'In the Galar region, there’s a tale about this Pokémon chewing up a mountain and using the rubble to stop a flood.',
		'species_id': 834,
	},
	835: {
		'description': 'This gluttonous Pokémon only assists people with their work because it wants treats. As it runs, it crackles with electricity.',
		'species_id': 835,
	},
	836: {
		'description': 'It sends electricity through its legs to boost their strength. Running at top speed, it easily breaks 50 mph.',
		'species_id': 836,
	},
	837: {
		'description': 'It can race around like a unicycle, even on rough, rocky terrain. Burning coal sustains it.',
		'species_id': 837,
	},
	838: {
		'description': 'By rapidly rolling its legs, it can travel at over 18 mph. The temperature of the flames it breathes exceeds 1,800 degrees Fahrenheit.',
		'species_id': 838,
	},
	839: {
		'description': 'When Galar was hit by a harsh cold wave, this Pokémon served as a giant heating stove and saved many lives.',
		'species_id': 839,
	},
	840: {
		'description': 'As soon as it’s born, it burrows into an apple. Not only does the apple serve as its food source, but the flavor of the fruit determines its evolution.',
		'species_id': 840,
	},
	841: {
		'description': 'If it stretches its neck, the strong aroma of its nectar pours out. The scent is so sickeningly sweet that one whiff makes other Pokémon faint.',
		'species_id': 841,
	},
	842: {
		'description': 'Due to Gigantamax energy, this Pokémon’s nectar has thickened. The increased viscosity lets the nectar absorb more damage than before.',
		'species_id': 842,
	},
	843: {
		'description': 'It spews sand from its nostrils. While the enemy is blinded, it burrows into the ground to hide.',
		'species_id': 843,
	},
	844: {
		'description': 'Its unique style of coiling allows it to blast sand out of its sand sac more efficiently.',
		'species_id': 844,
	},
	845: {
		'description': 'The half-swallowed Pikachu is so startled that it isn’t struggling yet, but it’s still looking for a chance to strike back.',
		'species_id': 845,
	},
	846: {
		'description': 'After it’s eaten its fill, its movements become extremely sluggish. That’s when Cramorant swallows it up.',
		'species_id': 846,
	},
	847: {
		'description': 'It spins its tail fins to propel itself, surging forward at speeds of over 100 knots before ramming prey and spearing into them.',
		'species_id': 847,
	},
	848: {
		'description': 'It manipulates the chemical makeup of its poison to produce electricity. The voltage is weak, but it can cause a tingling paralysis.',
		'species_id': 848,
	},
	849: {
		'description': 'It has an electrical organ on its chest. While generating electricity, it fills its surroundings with what sounds like the strumming of a bass guitar.',
		'species_id': 849,
	},
	850: {
		'description': 'It wraps prey up with its heated body, cooking them in its coils. Once they’re well-done, it will voraciously nibble them down to the last morsel.',
		'species_id': 850,
	},
	851: {
		'description': 'The heat that comes off a Gigantamax Centiskorch may destabilize air currents. Sometimes it can even cause storms.',
		'species_id': 851,
	},
	852: {
		'description': 'Its tentacles tear off easily, but it isn’t alarmed when that happens—it knows they’ll grow back. It’s about as smart as a three-year-old.',
		'species_id': 852,
	},
	853: {
		'description': 'Searching for an opponent to test its skills against, it emerges onto land. Once the battle is over, it returns to the sea.',
		'species_id': 853,
	},
	854: {
		'description': 'It absorbs the life-force of those who drink it. It waits patiently, but opportunities are fleeting— it tastes so bad that it gets spat out immediately.',
		'species_id': 854,
	},
	855: {
		'description': 'Leaving leftover black tea unattended is asking for this Pokémon to come along and pour itself into it, turning the tea into a new Polteageist.',
		'species_id': 855,
	},
	856: {
		'description': 'If this Pokémon senses a strong emotion, it will run away as fast as it can. It prefers areas without people.',
		'species_id': 856,
	},
	857: {
		'description': 'Using the braids on its head, it pummels foes to get them to quiet down. One blow from those braids would knock out a professional boxer.',
		'species_id': 857,
	},
	858: {
		'description': 'Beams like lightning shoot down from its tentacles. It’s known to some as the Raging Goddess.',
		'species_id': 858,
	},
	859: {
		'description': 'It sneaks into people’s homes, stealing things and feasting on the negative energy of the frustrated occupants.',
		'species_id': 859,
	},
	860: {
		'description': 'With sly cunning, it tries to lure people into the woods. Some believe it to have the power to make crops grow.',
		'species_id': 860,
	},
	861: {
		'description': 'Gigantamax energy has caused more hair to sprout all over its body. With the added strength, it can jump over the world’s tallest building.',
		'species_id': 861,
	},
	862: {
		'description': 'It evolved after experiencing numerous fights. While crossing its arms, it lets out a shout that would make any opponent flinch.',
		'species_id': 862,
	},
	863: {
		'description': 'After many battles, it evolved dangerous claws that come together to form daggers when extended.',
		'species_id': 863,
	},
	864: {
		'description': 'Be cautious of the ectoplasmic body surrounding its soul. You’ll become stiff as stone if you touch it.',
		'species_id': 864,
	},
	865: {
		'description': 'After deflecting attacks with its hard leaf shield, it strikes back with its sharp leek stalk. The leek stalk is both weapon and food.',
		'species_id': 865,
	},
	866: {
		'description': 'Its amusing movements make it very popular. It releases its psychic power from the pattern on its belly.',
		'species_id': 866,
	},
	867: {
		'description': 'Never touch its shadowlike body, or you’ll be shown the horrific memories behind the picture carved into it.',
		'species_id': 867,
	},
	868: {
		'description': 'They say that any patisserie visited by Milcery is guaranteed success and good fortune.',
		'species_id': 868,
	},
	869: {
		'description': 'It launches swarms of missiles, each made of cream and loaded with 100,000 kilocalories. Get hit by one of these, and your head will swim.',
		'species_id': 869,
	},
	870: {
		'description': 'The six of them work together as one Pokémon. Teamwork is also their battle strategy, and they constantly change their formation as they fight.',
		'species_id': 870,
	},
	871: {
		'description': 'It stores electricity in each spine. Even if one gets broken off, it still continues to emit electricity for at least three hours.',
		'species_id': 871,
	},
	872: {
		'description': 'It eats snow that piles up on the ground. The more snow it eats, the bigger and more impressive the spikes on its back grow.',
		'species_id': 872,
	},
	873: {
		'description': 'It shows no mercy to any who desecrate fields and mountains. It will fly around on its icy wings, causing a blizzard to chase offenders away.',
		'species_id': 873,
	},
	874: {
		'description': 'Once a year, on a specific date and at a specific time, they gather out of nowhere and form up in a circle.',
		'species_id': 874,
	},
	875: {
		'description': 'The hair on its head connects to the surface of its brain. When this Pokémon has something on its mind, its hair chills the air around it.',
		'species_id': 875,
	},
	876: {
		'description': 'They diligently serve people and Pokémon so they can gather feelings of gratitude. The females are particularly good at babysitting.',
		'species_id': 876,
	},
	877: {
		'description': 'Hunger hormones affect its temperament. Until its hunger is appeased, it gets up to all manner of evil deeds.',
		'species_id': 877,
	},
	878: {
		'description': 'If a job requires serious strength, this Pokémon will excel at it. Its copper body tarnishes in the rain, turning a vibrant green color.',
		'species_id': 878,
	},
	879: {
		'description': 'After this Pokémon has Gigantamaxed, its massive nose can utterly demolish large structures with a single smashing blow.',
		'species_id': 879,
	},
	880: {
		'description': 'The powerful muscles in its tail generate its electricity. Compared to its lower body, its upper half is entirely too small.',
		'species_id': 880,
	},
	881: {
		'description': 'This Pokémon lived on prehistoric seashores and was able to preserve food with the ice on its body. It went extinct because it moved so slowly.',
		'species_id': 881,
	},
	882: {
		'description': 'Its mighty legs are capable of running at speeds exceeding 40 mph, but this Pokémon can’t breathe unless it’s underwater.',
		'species_id': 882,
	},
	883: {
		'description': 'The skin on its face is impervious to attack, but breathing difficulties made this Pokémon go extinct anyway.',
		'species_id': 883,
	},
	884: {
		'description': 'The hardness of its cells is exceptional, even among Steel types. It also has a body structure that’s resistant to earthquakes.',
		'species_id': 884,
	},
	885: {
		'description': 'If this weak Pokémon is by itself, a mere child could defeat it. But if Dreepy has friends to help it train, it can evolve and become much stronger.',
		'species_id': 885,
	},
	886: {
		'description': 'Without a Dreepy to place on its head and care for, it gets so uneasy it’ll try to substitute any Pokémon it finds for the missing Dreepy.',
		'species_id': 886,
	},
	887: {
		'description': 'Apparently the Dreepy inside Dragapult’s horns eagerly look forward to being launched out at Mach speeds.',
		'species_id': 887,
	},
	888: {
		'description': 'Able to cut down anything with a single strike, it became known as the Fairy King’s Sword, and it inspired awe in friend and foe alike.',
		'species_id': 888,
	},
	889: {
		'description': 'Now that it’s equipped with its shield, it can shrug off impressive blows, including the attacks of Dynamax Pokémon.',
		'species_id': 889,
	},
	890: {
		'description': 'Infinite amounts of energy pour from this Pokémon’s enlarged core, warping the surrounding space-time.',
		'species_id': 890,
	},
	891: {
		'description': 'If Kubfu pulls the long white hair on its head, its fighting spirit heightens and power wells up from the depths of its belly.',
		'species_id': 891,
	},
	892: {
		'description': 'All it takes is a glare from this Pokémon to take the lives of those with evil in their hearts—or so they say.',
		'species_id': 892,
	},
	893: {
		'description': 'Once the vines on Zarude’s body tear off, they become nutrients in the soil. This helps the plants of the forest grow.',
		'species_id': 893,
	},
	894: {
		'description': 'Its entire body is made up of a single organ that generates electrical energy. Regieleki is capable of creating all Galar\'s electricity.',
		'species_id': 894,
	},
	895: {
		'description': 'Its body is composed of crystallized dragon energy. Regidrago is said to have the powers of every dragon Pokémon.',
		'species_id': 895,
	},
	896: {
		'description': 'Glastrier has tremendous physical strength, and the mask of ice covering its face is 100 times harder than diamond.',
		'species_id': 896,
	},
	897: {
		'description': 'As it dashes through the night, Spectrier absorbs the life-force of sleeping creatures. It craves silence and solitude.',
		'species_id': 897,
	},
	898: {
		'description': 'Calyrex is known in legend as a king that ruled over Galar in ancient times. It has the power to cause hearts to mend and plants to spring forth.',
		'species_id': 898,
	},
};