export const TypeIds = <const>[ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ];
const TypeNames = <const>[ 'normal', 'fighting', 'flying', 'poison', 'ground', 'rock', 'bug', 'ghost', 'steel', 'fire', 'water', 'grass', 'electric', 'psychic', 'ice', 'dragon', 'dark', 'fairy' ];

export type TypeId = typeof TypeIds[number];
type ElementType = typeof TypeNames[number];

export const ElementTypes: Record<TypeId, ElementType> = <const>{
	0: 'normal',
	1: 'fighting',
	2: 'flying',
	3: 'poison',
	4: 'ground',
	5: 'rock',
	6: 'bug',
	7: 'ghost',
	8: 'steel',
	9: 'fire',
	10: 'water',
	11: 'grass',
	12: 'electric',
	13: 'psychic',
	14: 'ice',
	15: 'dragon',
	16: 'dark',
	17: 'fairy',
};

export const ElementTypeColors = <const>{
	'normal': '#9099a1',
	'fighting': '#ce4069',
	'flying': '#8fa8dd',
	'poison': '#ab6ac8',
	'ground': '#d97746',
	'rock': '#c7b78b',
	'bug': '#90c12c',
	'ghost': '#5269ac',
	'steel': '#5a8ea1',
	'fire': '#ff9c54',
	'water': '#4d90d5',
	'grass': '#63bb5b',
	'electric': '#f3d23b',
	'psychic': '#f97176',
	'ice': '#74cec0',
	'dragon': '#0a6dc4',
	'dark': '#5a5366',
	'fairy': '#ec8fe6',
};
