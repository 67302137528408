import { without } from 'lodash';
import { useEffect, useReducer } from 'react';
import { DefaultSections, Section, SectionAction } from 'src/data/settings';

export function useStorageReducer(): [Section[], React.Dispatch<SectionAction>] {
	const storageKey = 'sections';
	const [ sections, setSection ] = useReducer((state: Section[], action: SectionAction) => {
		if (action.enabled) {
			return [ ...state, action.section ];
		} else {
			return without(state, action.section);
		}
	},
	[ ...DefaultSections ],
	(initializerArg) => {
		const storedValue = localStorage.getItem(storageKey);
		if (storedValue) {
			return JSON.parse(storedValue);
		}
		return initializerArg;
	});

	useEffect(() => {
		localStorage.setItem(storageKey, JSON.stringify(sections));
	}, [ sections ]);

	return [ sections, setSection ];
}
