import Fuse from 'fuse.js';
import { findIndex, map } from 'lodash';
import { useCallback } from 'react';
import { PokemonForm } from 'poke-data';
import { useEventListener } from 'poke-hook';
import { PokemonImage } from '../Pokedex/PokemonImage';

const MAX_RESULTS = 5;

export function Results({
	pokemon,
	searchResults,
	setPokemon,
}: {
	pokemon: PokemonForm | undefined,
	searchResults: Fuse.FuseResult<PokemonForm>[],
	setPokemon: (x: PokemonForm | undefined) => void,
}): JSX.Element {

	const currIndex = findIndex(searchResults, [ 'item.id', pokemon?.id ]);
	const prevIndex = (currIndex + searchResults.length - 1) % searchResults.length;
	const nextIndex = (currIndex + 1) % searchResults.length;

	const handleResultNavigation = useCallback((keyDown: KeyboardEvent) => {
		if (
			![ 'P', 'N' ].includes(keyDown.key) ||
			!pokemon
		) {
			return;
		}
		if (keyDown.key === 'P') {
			setPokemon(searchResults[prevIndex].item);
		}
		if (keyDown.key === 'N') {
			setPokemon(searchResults[nextIndex].item);
		}
		keyDown.preventDefault();
	}, [ pokemon, searchResults, prevIndex, nextIndex ]);

	useEventListener('keydown', handleResultNavigation);

	if (!pokemon) {
		return <></>;
	}

	const canNavigate = searchResults.length > 1;

	const start = Math.max(0, Math.min(currIndex - Math.floor(MAX_RESULTS / 2), searchResults.length - MAX_RESULTS));
	const visibleResults = searchResults.slice(start, start + MAX_RESULTS);

	return <div
		className="grid grid-flow-col gap-7 h-10 pt-1 place-items-center text-bw text-center"
	>
		{ canNavigate && <span
			className='select-none w-7 h-9 leading-9'
			onClick={ () => setPokemon(searchResults[prevIndex].item) }
			role='button'
		>&lt;</span> }
		{ map(visibleResults, (value) => {
			const p = value.item;
			let className = 'w-7 h-7 ';
			className += pokemon.id === p.id ? 'opacity-100' : 'opacity-50';
			return <span
				className={ className }
				key={ value.item.id }
			>
				<PokemonImage
					className='w-7 h-7 object-contain'
					pokemon={ p }
					onClick={ setPokemon }
				/>
			</span>;
		}) }
		{ canNavigate && <span
			className='select-none w-7 h-9 leading-9'
			onClick={ () => setPokemon(searchResults[nextIndex].item) }
			role='button'
		>&gt;</span> }
	</div>;
}

